var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"ma-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"10"}},[_vm._v(" Add New Certificate(s) ")]),_c('v-col',{staticClass:"justify-end d-flex",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.newFilesExpanded = !_vm.newFilesExpanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                      rotate: _vm.newFilesExpanded,
                    }},[_vm._v("mdi-chevron-down")])],1)],1)],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.newFilesExpanded),expression:"newFilesExpanded"}]},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])]),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf,.png,.jpg","dense":"","multiple":"","show-size":"","prepend-icon":"","prepend-inner-icon":"$file","label":"Certificate(s)","truncate-length":"16","outlined":"","hide-details":"","disabled":_vm.shouldDisplayForRole('editDocument')},on:{"change":_vm.insertedFiles},model:{value:(_vm.newFiles),callback:function ($$v) {_vm.newFiles=$$v},expression:"newFiles"}})],1)],1)],1)],1)],1)],1),_vm._l((_vm.certificates),function(cert){return _c('v-col',{key:cert.id,attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"9"}},[_vm._v(" Certificate ")]),_c('v-col',{attrs:{"cols":"2"}},[(cert.file)?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile(cert.filePath, '')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1),_c('v-col',{staticClass:"justify-end d-flex",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){cert.expanded = !cert.expanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                      rotate: cert.expanded,
                    }},[_vm._v("mdi-chevron-down")])],1)],1)],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(cert.expanded),expression:"cert.expanded"}]},[(cert && cert.file != null)?_c('v-hover',{attrs:{"close-delay":"35"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"flat":""}},[(cert.file.type !== 'application/pdf')?_c('v-img',{attrs:{"contain":"","height":200,"min-width":100,"src":_vm.URL.createObjectURL(cert.file)},on:{"error":_vm.errorHandler}},[_c('v-expand-transition',[(hover == true)?_c('div',{staticClass:"d-flex justify-center transition-fast-in-fast-out imageOverlay text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(cert.file)}}},[_vm._v("View Image")])],1):_vm._e()])],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"100px"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(cert.file)}}},[_vm._v("View PDF")])],1)],1)]}}],null,true)}):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf,.png,.jpg","dense":"","show-size":"","prepend-icon":"","prepend-inner-icon":"$file","label":"Certificate","truncate-length":"16","outlined":"","hide-details":"","disabled":_vm.shouldDisplayForRole('editDocument')},on:{"change":function($event){$event != null
                      ? (cert.preview = _vm.URL.createObjectURL($event))
                      : (_vm.certificates = _vm.certificates.filter((e) => e != cert))}},model:{value:(cert.file),callback:function ($$v) {_vm.$set(cert, "file", $$v)},expression:"cert.file"}})],1)],1)],1)],1)],1)],1)})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"10"}},[_vm._v(" Add Statement(s) of Results ")]),_c('v-col',{staticClass:"justify-end d-flex",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.newStatementsExpanded = !_vm.newStatementsExpanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                      rotate: _vm.newStatementsExpanded,
                    }},[_vm._v("mdi-chevron-down")])],1)],1)],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.newStatementsExpanded),expression:"newStatementsExpanded"}]},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])]),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"disabled":_vm.loading == true ||
                    _vm.shouldDisplayForRole('editDocument') == true,"accept":".pdf,.png,.jpg","dense":"","multiple":"","show-size":"","prepend-icon":"","prepend-inner-icon":"$file","label":"Statement of Results","truncate-length":"16","outlined":"","hide-details":""},on:{"change":_vm.insertedStatements},model:{value:(_vm.newStatements),callback:function ($$v) {_vm.newStatements=$$v},expression:"newStatements"}})],1)],1)],1)],1)],1)],1),_vm._l((_vm.statements),function(statement){return _c('v-col',{key:statement.id,attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"9"}},[_vm._v(" Statement of Results ")]),_c('v-col',{attrs:{"cols":"2"}},[(statement.file)?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile('', statement.filePath)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1),_c('v-col',{staticClass:"justify-end d-flex",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){statement.expanded = !statement.expanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                      rotate: statement.expanded,
                    }},[_vm._v("mdi-chevron-down")])],1)],1)],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(statement.expanded),expression:"statement.expanded"}]},[(statement && statement.file != null)?_c('v-hover',{attrs:{"close-delay":"35"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"flat":""}},[(statement.file.type !== 'application/pdf')?_c('v-img',{attrs:{"contain":"","height":200,"src":statement.preview}},[_c('v-expand-transition',[(hover == true)?_c('div',{staticClass:"d-flex justify-center transition-fast-in-fast-out imageOverlay text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(statement.file)}}},[_vm._v("View Image")])],1):_vm._e()])],1):_vm._e()],1)]}}],null,true)}):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"disabled":_vm.loading == true ||
                    _vm.shouldDisplayForRole('editDocument') == true,"accept":".pdf,.png,.jpg","dense":"","show-size":"","prepend-icon":"","prepend-inner-icon":"$file","label":"Statement of Results","truncate-length":"16","outlined":"","hide-details":""},on:{"change":function($event){$event != null
                      ? (statement.preview = _vm.URL.createObjectURL($event))
                      : (_vm.statements = _vm.statements.filter(
                          (e) => e != statement
                        ))}},model:{value:(statement.file),callback:function ($$v) {_vm.$set(statement, "file", $$v)},expression:"statement.file"}})],1)],1)],1)],1)],1)],1)})],2),_c('v-row',{staticClass:"mt-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5 text-left mb-0"},[_vm._v("Available options")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"auto","disabled":!_vm.getBusHandgun ||
          _vm.getHandgun ||
          _vm.shouldDisplayForRole('editDocument') == true,"label":`Demonstrate Tactical Proficiency with a Handgun (U/S 123513)${
          _vm.getBusHandgun == false ? ' (requires business proficiency)' : ''
        }${_vm.getHandgun == true ? ' (already exists)' : ''}`},model:{value:(_vm.currentDoc.Handgun),callback:function ($$v) {_vm.$set(_vm.currentDoc, "Handgun", $$v)},expression:"currentDoc.Handgun"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"auto","disabled":!_vm.getBusShotgun ||
          _vm.getShotgun ||
          _vm.shouldDisplayForRole('editDocument') == true,"label":`Demonstrate Tactical Proficiency with a Shotgun (U/S 123512)${
          _vm.getBusShotgun == false ? ' (requires business proficiency)' : ''
        }${_vm.getShotgun == true ? ' (already exists)' : ''}`},model:{value:(_vm.currentDoc.Shotgun),callback:function ($$v) {_vm.$set(_vm.currentDoc, "Shotgun", $$v)},expression:"currentDoc.Shotgun"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"disabled":!_vm.getBusSLR || _vm.getSLR || _vm.shouldDisplayForRole('editDocument') == true,"hide-details":"auto","label":`Demonstrate Tactical Proficiency with a Self-Loading Rifle or Carbine (U/S 123518)${
          _vm.getBusSLR == false ? ' (requires business proficiency)' : ''
        }${_vm.getSLR == true ? ' (already exists)' : ''}`},model:{value:(_vm.currentDoc.Selfloadingrifle),callback:function ($$v) {_vm.$set(_vm.currentDoc, "Selfloadingrifle", $$v)},expression:"currentDoc.Selfloadingrifle"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"disabled":!_vm.validForm || _vm.shouldDisplayForRole('editDocument') == true,"loading":_vm.loading,"color":"primary","block":""},on:{"click":_vm.addTUS}},[_vm._v("Submit")])],1)],1),_c('multimedia-viewer',{attrs:{"open":_vm.multiMediaViewer.open,"data":{
      type: 'file',
      file: _vm.multiMediaViewer.file,
      offlinePermit: null,
    }},on:{"update:open":function($event){return _vm.$set(_vm.multiMediaViewer, "open", $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
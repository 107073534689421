
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Dash from "@/store/modules/Dash";
import { CRUDFunctionality, Navigation, Storage } from "@/hooks";
import NotificationSystem from "@/store/modules/NotificationSystem";
import Subscription from "@/store/modules/Subscription";
import { SubscriptionTrialStatus } from "@/enums/subscription";
import IssuePermit from "@/views/manage/permits/IssuePermit.vue";
import logger from "@/plugins/logger";

@Component({ components: { IssuePermit } })
export default class AppBar extends Vue {
  Substore = Subscription;
  SubType = SubscriptionTrialStatus;
  title = "";
  newPermitModal = false;
  issuePermit(permissions: string[]): boolean {
    logger.log(
      "Firearm Permission",
      permissions,
      permissions
        .map((permission) => CRUDFunctionality.hasAccess(permission))
        .every(Boolean)
    );
    return permissions
      .map((permission) => CRUDFunctionality.hasAccess(permission))
      .every(Boolean);
  }
  flipDrawer(): void {
    // logger.log("Flip drawer  to: ", !this.appDrawer);
    Dash.setAppDrawer(!this.appDrawer);
  }
  goToNotifications(): void {
    this.$router.push({
      name: "dash.notificationList",
    });
  }
  openIssuePermit() {
    this.newPermitModal = true;
    /* if (this.navigable("dash.manage.permits") == true) {
      this.$router.push({ name: "dash.manage.permits" });
    } else {
      this.$router.push({ name: "dash.myPermits" });
    } */
  }
  navigable(name: string): boolean {
    return AuthModule.getUserRoles
      .flatMap((role) => {
        return Navigation.allowedRoutes[role.roleId];
      })
      .includes(name);
  }
  get getUsername(): string | null {
    return AuthModule.getUsername;
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }
  get hasUnreadNotifications(): boolean {
    return NotificationSystem.unreadNotitications.length != 0;
  }
  async logout() {
    // logger.log("Logged out");
    AuthModule.removeLoggedIn();
    await Storage.removeLogin();
    // IndexedDB.unmountDB();
    this.$router.push({
      name: "Login",
    });
  }
  @Watch("$route.name", { immediate: true })
  routeChanged(newTitle: string): void {
    switch (newTitle) {
      case "dash.myPermits":
        this.title = "My Permits";
        break;
      default:
        this.title = newTitle;
        break;
    }
  }
  get getUserRoles(): string {
    let temp = "";
    AuthModule.getUserRoles.forEach((e) => {
      temp += e.roleName + ",";
    });
    if (temp.length > 2) {
      const lastChar = temp.charAt(temp.length - 1);
      // logger.log("Last char: ", lastChar);
      if (lastChar === ",") {
        // logger.log("Slice char: ", lastChar);
        temp = temp.slice(0, temp.length - 1);
      }
    }
    return temp;
  }
}

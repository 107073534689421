
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Dash from "@/store/modules/Dash";
import { CRUDFunctionality, Navigation, Storage } from "@/hooks";
import logger from "@/plugins/logger";
import IssuePermit from "@/views/manage/permits/IssuePermit.vue";

@Component({
  components: {
    IssuePermit,
  },
})
export default class NavDrawer extends Vue {
  newPermitModal = false;
  issuePermit(permissions: string[]): boolean {
    logger.log(
      "Firearm Permission",
      permissions,
      permissions
        .map((permission) => CRUDFunctionality.hasAccess(permission))
        .every(Boolean)
    );
    return permissions
      .map((permission) => CRUDFunctionality.hasAccess(permission))
      .every(Boolean);
  }
  flipDrawer(trust: boolean): void {
    // logger.log("Nav flip me please ", trust, this.appDrawer);
    Dash.setAppDrawer(trust);
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }

  get getIncidentProxy(): boolean {
    return AuthModule.getIncidentProxy;
  }

  get getReportProxy(): boolean {
    return AuthModule.getReportProxy;
  }

  selected = null;
  async logout() {
    // logger.log("Logged out");
    AuthModule.removeLoggedIn();
    await Storage.removeLogin();
    // IndexedDB.unmountDB();
    this.$router.push({
      name: "Login",
    });
  }
  navigable(name: string): boolean {
    return AuthModule.getUserRoles
      .flatMap((role) => {
        return Navigation.allowedRoutes[role.roleId];
      })
      .includes(name);
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('v-data-iterator',{attrs:{"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","items":_vm.dash},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('h3',{staticClass:"mt-3"},[_vm._v("No Data")]),_c('p',[_vm._v("If this persists please contact us.")])]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',{staticClass:"mx-1",attrs:{"align":"start"}},_vm._l((props.items),function(item){return _c('v-col',{key:item.name,staticClass:"py-1",attrs:{"cols":"6","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"white--text",attrs:{"elevation":"2","height":"100","color":item.name != ''
                    ? _vm.colorEval(`${item.value} ${item.colorCheck}`)
                      ? item.name == 'currentShifts' ||
                        item.name == 'firearmBookedOutCount' ||
                        item.name == 'ammunitionBookedOut' ||
                        item.name == 'incidentCount'
                        ? '#0B9CAD'
                        : '#F25B68'
                      : item.name == 'currentShifts' ||
                        item.name == 'firearmBookedOutCount' ||
                        item.name == 'ammunitionBookedOut' ||
                        item.name == 'incidentCount'
                      ? '#FFB347'
                      : '#72BF60'
                    : '#72BF60'},on:{"click":function($event){return _vm.openModal(item)}}},[_c('v-card-text',[_c('v-icon',{attrs:{"color":"white","x-large":""},domProps:{"textContent":_vm._s(item.icon)}}),_c('h5',{staticClass:"text-h5 white--text"},[_vm._v(" "+_vm._s(item.value == -1 ? "" : item.name == "complianceScore" || item.name == "firearmComplianceScore" ? `${Math.round(item.value)} %` : item.value)+" ")])],1)],1),_c('p',{staticClass:"mt-2 text-subtitle-2 mb-1"},[_vm._v(_vm._s(item.title))])],1)}),1)]}}])}),_c('o-modal',{attrs:{"data":_vm.modalData,"open":_vm.overviewModal},on:{"update:data":function($event){_vm.modalData=$event},"update:open":function($event){_vm.overviewModal=$event}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
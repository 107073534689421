
import { Component, Vue, Watch } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import {
  CRUDFunctionality,
  AROs,
  Downloader,
  PocketBook,
  Firearms,
} from "@/hooks";
import {
  AROViewModel,
  Firearm,
  PocketbookViewModel,
  PocketbookFormattedData,
  PocketBookExtentions,
  PocketBookFilter,
  ArmResponseOfficer,
} from "models";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";
import ManageCommentsModal from "@/views/pocketbook/AddEditComments.vue";
import logger from "@/plugins/logger";

@Component({
  components: {
    JsonExcel,
    ManageCommentsModal,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class OnlinePocketBook extends Vue {
  records: any[] = [];
  items: PocketBookExtentions[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "createDate";
  expanded = [];
  deleteModal = false;
  valid = false;
  //aroOrSO: "ARO" | "SO" | null = null;
  aros: AROViewModel[] = [];
  aeCommentModal = false;
  aeCommentModalData = {
    type: " ",
    field: {} as PocketBookExtentions,
  };
  communicationModal = false;
  communicationModalLoading = false;
  communicationMessage: string | null = null;
  selectedAro: AROViewModel | null = null;
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Entry Date & Time", value: "shiftStart" },
    /*  { text: "Entry End Date/Time", value: "shiftEnd" }, */
    { text: "Title", value: "incidentTitle" },
    { text: "Description", value: "comments" },
    { text: "Supervisor Feedback", value: "feedback" },
  ];
  expReports: {
    "Entry Date Time": string | null;
    /* "End of Shift": string | null; */
    Title: string | null;
    Description: string | null;
    "Supervisor Feedback": string | null;
  }[] = [];
  expFields = {
    "Entry Date Time": "Entry Date Time",
    /*  "End of Shift": "End of Shift", */
    Title: "Title",
    Description: "Description",
    "Supervisor Feedback": "Supervisor Feedback",
  };
  filter: PocketBookFilter = {
    shift: null,
    firearmSerial: null,
    firearmMake: null,
    firearmModel: null,
    permitNo: null,
    startTime: dayjs().subtract(1, "day").format("YYYY-MM-DDTHH:mm"),
    endingTime: dayjs().add(1, "day").format("YYYY-MM-DDTHH:mm"),
    vehicleMake: null,
    vehicleModel: null,
    vehicleRegNo: null,
    onDutyOBNumber: null,
    offDutyOBNumber: null,
    aroId: null,
  };

  userFilter(
    item: ArmResponseOfficer,
    queryText: string,
    itemText: string
  ): boolean {
    // logger.log("Searched ", queryText, itemText, item);
    if (
      (item.teamName != null &&
        item.teamName
          .toLocaleLowerCase()
          .includes(queryText.toLocaleLowerCase())) ||
      item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
      item.surname.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    logger.log("Name: ", name, has);
    return has;
  }

  get getUserId(): string | null {
    const userId = AuthModule.getUserOverview?.securityFirm.userID;
    if (userId) {
      return userId;
    } else {
      return null;
    }
  }

  openAEComments(i: PocketBookExtentions | null): void {
    logger.log("to be opened", i);
    if (!i) {
      this.aeCommentModalData.type = "add";
      this.aeCommentModalData.field = {
        comments: "",
        shiftStart: dayjs().format("YYYY-MM-DD HH:mm"),
        shiftEnd: dayjs().format("YYYY-MM-DD HH:mm"),
        incidentTitle: "",
      } as PocketBookExtentions;
    } else {
      this.aeCommentModalData.type = "edit";
      this.aeCommentModalData.field = {
        comments: i.comments,
        shiftStart: i.shiftStart,
        shiftEnd: i.shiftEnd,
        incidentTitle: i.incidentTitle,
        id: i.id,
        createDate: i.createDate,
        userId: i.userId,
        createBy: i.createBy,
        pocketBook: i.pocketBook,
        pocketBookId: i.pocketBookId,
        updateBy: i.updateBy,
        updateDate: i.updateDate,
        aroId: i.aroId,
        securityFirmId: i.securityFirmId,
        checked: i.checked,
        obNumber: i.obNumber,
        feedback: i.feedback,
        checkedByUser: i.checkedByUser,
        checkedByUserId: i.checkedByUserId,
        checkedDate: i.checkedDate,
      };
    }
    this.aeCommentModal = true;
  }

  /* openDeleteItem(i: PocketbookFormattedData): void {
    this.manageItemName = i.shiftFormatted;
    this.manageItemId = i.id;
    this.deleteModal = true;
  } */

  /* async deleteItem(): Promise<void> {
    try {
      if (this.manageItemId)
        var res = await PocketBook.DeletePocketBookEntry(this.manageItemId);
      this.$notifCreator.createSuccessNotification(
        "Pocketbook Entry Deleted Succesfully."
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.manageItemName = null;
      this.manageItemId = null;
      this.deleteModal = false;
      this.loadItems();
    }
  } */
  async sendMessage(): Promise<void> {
    try {
      this.communicationModalLoading = true;
      if (this.communicationMessage) {
        const resp = await PocketBook.NotifyAllPocketBooks({
          notificationMessage: this.communicationMessage,
        });
        this.$notifCreator.createSuccessNotification(
          "Message sent to all Pocketbooks."
        );
        this.communicationModal = false;
        this.communicationMessage = null;
      } else {
        this.$notifCreator.createErrorNotification(
          "A Message must be provided."
        );
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.communicationModalLoading = false;
    }
  }

  async generatePDF(): Promise<void> {
    try {
      const columns = [
        { header: "Entry Date Time", dataKey: "Entry Date Time" },
        /*  { header: "End of Shift", dataKey: "End of Shift" }, */
        { header: "Title", dataKey: "Title" },
        { header: "Description", dataKey: "Description" },
        { header: "Supervisor Feedback", dataKey: "Supervisor Feedback" },
      ];

      await Downloader.generatePdfAndSave({
        fileName: `Pocketbook`,
        columns: columns,
        body: this.expReports,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loadItems(): Promise<void> {
    // logger.log(this.shouldDisplayForRole("addPBEntry"));
    // if (this.shouldDisplayForRole("addPBEntry") == true) {
    try {
      this.loading = true;
      if (this.selectedAro) {
        this.filter.aroId = this.selectedAro.id;
        const res = await PocketBook.GetFirmPocketBookExtentions(this.filter);
        if (res) this.items = this.formatData(res);
      } else {
        this.filter.aroId = undefined;
        this.items = [];
      }

      // this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
    this.expReports = this.populateExportData(this.items);
    // }
  }

  formatData(data: PocketBookExtentions[]): PocketBookExtentions[] {
    const filteredData: PocketBookExtentions[] = [];
    data.forEach((elem) => {
      filteredData.push({
        id: elem.id,
        createDate: elem.createDate,
        userId: elem.userId,
        comments: elem.comments,
        createBy: elem.createBy,
        pocketBook: elem.pocketBook,
        pocketBookId: elem.pocketBookId,
        updateBy: elem.updateBy,
        updateDate: elem.updateDate,
        incidentTitle: elem.incidentTitle,
        shiftEnd: dayjs(elem.shiftEnd).format("YYYY-MM-DD HH:mm"),
        shiftStart: dayjs(elem.shiftStart).format("YYYY-MM-DD HH:mm"),
        aroId: elem.aroId,
        securityFirmId: elem.securityFirmId,
        checked: elem.checked,
        obNumber: elem.obNumber,
        feedback: elem.feedback,
        checkedByUser: elem.checkedByUser,
        checkedByUserId: elem.checkedByUserId,
        checkedDate: elem.checkedDate,
      });
    });
    return filteredData;
  }

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      //await this.loadItems();
      await this.loadAROs();
    } catch (err) {
      logger.log("Err on Mounted:", err);
    } finally {
      this.loading = false;
    }
  }

  async loadAROs(): Promise<void> {
    try {
      this.loading = true;
      const res = await AROs.getAros();
      this.aros = res.arOs;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  populateExportData(items: PocketBookExtentions[]) {
    return items.map((elem: PocketBookExtentions) => {
      return {
        "Entry Date Time": elem.shiftStart ? elem.shiftStart : null,
        /*  "End of Shift": elem.shiftEnd, */
        Title: elem.incidentTitle ? elem.incidentTitle : null,
        Description: elem.comments ? elem.comments : null,
        "Supervisor Feedback": elem.feedback ? elem.feedback : null,
      };
    });
  }
}

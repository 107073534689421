var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"title primary py-2 white--text",staticStyle:{"height":"8dvh"}},[_c('span',[_vm._v(_vm._s(_vm.trimString(_vm.getModalTitle)))]),_c('v-spacer'),(
          _vm.data.type == 'file' &&
          _vm.platform == 'web' &&
          _vm.base64 &&
          _vm.data.file &&
          (_vm.data.file.name.includes('.png') ||
            _vm.data.file.name.includes('.jpg') ||
            _vm.data.file.name.includes('.jpeg'))
        )?_c('v-btn',{staticClass:"pr-0 mr-3",attrs:{"fab":"","text":"","small":""},on:{"click":_vm.downloadImage}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-download ")])],1):_vm._e(),_c('v-btn',{staticClass:"pr-0",attrs:{"fab":"","text":"","small":""},on:{"click":_vm.closeModal}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close ")])],1)],1),(
        _vm.data.type == 'offlinePermitPDF' &&
        _vm.data.offlinePermit != null &&
        _vm.getOfflinePermit != null &&
        _vm.platform == 'web'
      )?_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"92dvh"}},[[_c('vue-pdf-app',{attrs:{"pdf":_vm.getOfflinePermit}})]],2)],1)],1)],1):_vm._e(),(_vm.data.type == 'offlinePermitPDF' && _vm.base64 && _vm.platform != 'web')?_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"82dvh"}},[[_c('vue-pdf-app',{attrs:{"pdf":_vm.getOfflinePermit,"title":false,"config":{
                  secondaryToolbar: {
                    secondaryPresentationMode: false,
                    secondaryOpenFile: false,
                    secondaryPrint: false,
                    secondaryDownload: false,
                    secondaryViewBookmark: true,
                    firstPage: true,
                    lastPage: true,
                    pageRotateCw: true,
                    pageRotateCcw: true,
                    cursorSelectTool: true,
                    cursorHandTool: true,
                    scrollVertical: true,
                    scrollHorizontal: true,
                    scrollWrapped: true,
                    spreadNone: false,
                    spreadOdd: false,
                    spreadEven: false,
                    documentProperties: false,
                  },
                  toolbar: {
                    toolbarViewerLeft: {
                      findbar: true,
                      previous: true,
                      next: true,
                      pageNumber: true,
                    },
                    toolbarViewerRight: {
                      presentationMode: false,
                      openFile: false,
                      print: false,
                      download: false,
                      viewBookmark: false,
                    },
                    toolbarViewerMiddle: {
                      zoomOut: true,
                      zoomIn: true,
                      scaleSelectContainer: true,
                    },
                  },
                }}})]],2)],1)],1)],1):_vm._e(),(_vm.data.type == 'file' && _vm.data.file != null && _vm.platform == 'web')?_c('v-card-text',{staticClass:"pa-0"},[(_vm.data.file.name.includes('.pdf'))?_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"92dvh"}},[[_c('vue-pdf-app',{attrs:{"pdf":_vm.base64}})]],2)],1)],1):(
          _vm.data.file.name.includes('.png') ||
          _vm.data.file.name.includes('.jpg') ||
          _vm.data.file.name.includes('.jpeg')
        )?_c('v-img',{attrs:{"src":_vm.base64,"height":"100%","width":"100%","max-height":"92dvh","contain":""}}):_c('div',[_c('p',[_vm._v("-- Unsupported file type --")])])],1):_vm._e(),(_vm.data.type == 'file' && _vm.data.file != null && _vm.platform != 'web')?_c('v-card-text',{staticClass:"pa-0"},[(_vm.data.file.name.includes('.pdf'))?_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"82dvh"}},[[_c('vue-pdf-app',{attrs:{"pdf":_vm.base64,"config":{
                  secondaryToolbar: {
                    secondaryPresentationMode: false,
                    secondaryOpenFile: false,
                    secondaryPrint: false,
                    secondaryDownload: false,
                    secondaryViewBookmark: true,
                    firstPage: true,
                    lastPage: true,
                    pageRotateCw: true,
                    pageRotateCcw: true,
                    cursorSelectTool: true,
                    cursorHandTool: true,
                    scrollVertical: true,
                    scrollHorizontal: true,
                    scrollWrapped: true,
                    spreadNone: false,
                    spreadOdd: false,
                    spreadEven: false,
                    documentProperties: false,
                  },
                  toolbar: {
                    toolbarViewerLeft: {
                      findbar: true,
                      previous: true,
                      next: true,
                      pageNumber: true,
                    },
                    toolbarViewerRight: {
                      presentationMode: false,
                      openFile: false,
                      print: false,
                      download: false,
                      viewBookmark: false,
                    },
                    toolbarViewerMiddle: {
                      zoomOut: true,
                      zoomIn: true,
                      scaleSelectContainer: true,
                    },
                  },
                }}})]],2)],1)],1):(
          _vm.data.file.name.includes('.png') ||
          _vm.data.file.name.includes('.jpg') ||
          _vm.data.file.name.includes('.jpeg')
        )?_c('v-img',{attrs:{"src":_vm.base64,"height":"100%","width":"100%","max-height":"82dvh","contain":""}}):_c('div',[_c('p',[_vm._v("-- Unsupported file type --")])])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
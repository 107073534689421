
import { Component, Vue, Prop } from "vue-property-decorator";
import download from "downloadjs";
import { Documents, Downloader } from "@/hooks";
import dayjs from "dayjs";
import { Device } from "@capacitor/device";
import logger from "@/plugins/logger";

interface dataType {
  "Date & Time": string;
  User: string;
  Action: string;
  Description: string;
}

@Component
export default class JsonExcel extends Vue {
  // mime type [xls, csv]
  @Prop({
    default: "xls",
    type: String,
  })
  type!: string;
  // Json to download
  @Prop({
    type: Array,
    required: false,
    default: null,
  })
  headerData!: Record<string, string>[];
  // fields inside the Json Object that you want to export
  // if no given, all the properties in the Json are exported
  @Prop({
    type: Object,
    default: () => null,
  })
  exportFieldsHeader!: Record<string, string>;
  @Prop({
    type: Array,
    required: false,
    default: null,
  })
  data!: Record<string, string>[];
  // fields inside the Json Object that you want to export
  // if no given, all the properties in the Json are exported
  @Prop({
    type: Object,
    default: () => null,
  })
  exportFields!: Record<string, string>;
  // filename to export
  @Prop({
    default: "data.xls",
  })
  name!: string;

  @Prop({
    type: String,
    default: "Sheet1",
  })
  worksheet!: string;
  // Determine if CSV Data should be escaped
  @Prop({
    type: Boolean,
    default: true,
  })
  escapeCsv!: boolean;
  // long number stringify
  @Prop({
    type: Boolean,
    default: false,
  })
  stringifyLongNum!: boolean;
  // Use as fallback when the row has no field values
  @Prop({
    default: "",
    type: String,
    required: false,
  })
  defaultValue!: string;

  get idName() {
    var now = new Date().getTime();
    return "export_" + now;
  }
  get downloadFields() {
    if (this.exportFields) return this.exportFields;
    return null;
  }
  get downloadFieldsHeaders() {
    if (this.exportFieldsHeader) return this.exportFieldsHeader;
    return null;
  }

  async generate() {
    logger.log("Generate");
    let data = this.data;
    logger.log("Fetched ", data);
    if (!data || !data.length) {
      return;
    }
    logger.log("Ready", this.type);

    debugger;

    let json = this.getProcessedJson(data, this.downloadFields);
    logger.log("Headers ", this.headerData, this.downloadFieldsHeaders);
    let jsonHeader = this.headerData
      ? this.getProcessedJson(this.headerData, this.downloadFieldsHeaders)
      : null;

    logger.log("Raw processed ", json, jsonHeader);
    if (this.type === "html") {
      // this is mainly for testing
      return this.export(
        this.jsonToXLS(json),
        this.name.replace(".xls", ".html"),
        "text/html"
      );
    } else if (this.type === "csv") {
      // if (jsonHeader) {
      //   json = jsonHeader.concat(json);
      // }
      const body = this.jsonToCSV(json);
      const head = jsonHeader ? this.jsonToCSV(jsonHeader) : null;

      const temp = head + "\r\n" + body;
      logger.log("Csv ", temp);
      return this.export(
        temp,
        this.name.replace(".xls", ".csv"),
        "application/csv"
      );
    }
    return this.export(
      this.jsonToXLS(json),
      this.name,
      "application/vnd.ms-excel"
    );
  }
  /*
		Use downloadjs to generate the download link
		*/
  async export(data: string, filename: string, mime: string) {
    let blob = this.base64ToBlob(data, mime);
    const temp = await Documents.getBase64(blob);
    // download(blob, filename, mime);
    // logger.log("Test base64 : ", temp);
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.platform == "web") {
      download(blob, filename, mime);
    } else {
      if (typeof temp == "string") {
        Downloader.saveFile(temp, filename, mime);
      }
    }

    // logger.warn("Not a string");
  }
  /*
		jsonToXLS
		---------------
		Transform json data into an xml document with MS Excel format, sadly
		it shows a prompt when it opens, that is a default behavior for
		Microsoft office and cannot be avoided. It's recommended to use CSV format instead.
		*/
  jsonToXLS(data: any[]) {
    let xlsTemp =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table>${table}</table></body></html>';
    let xlsData = "";

    if (this.headerData) {
      xlsData += "<thead>";
      for (var k in this.exportFieldsHeader) {
        xlsData += "<th colspan='2'>" + k + "</th>";
      }
      xlsData += "</thead>";
      xlsData += "<tbody>";
      this.headerData.map((item, index) => {
        xlsData += "<tr>";
        for (let key in item) {
          xlsData +=
            "<td colspan='2' align='left'>" +
            this.preprocessLongNum(
              this.valueReformattedForMultilines(item[key])
            ) +
            "</td>";
        }
        xlsData += "</tr>";
      });
      xlsData += "</tr>";
      xlsData += "</tbody>";
    }
    xlsData += "<thead>";
    const colspan = Object.keys(data[0]).length;
    // eslint-disable-next-line @typescript-eslint/no-this-alias

    //Fields
    xlsData += "<tr>";
    for (var key in data[0]) {
      xlsData += "<th colspan='2'>" + key + "</th>";
    }
    xlsData += "</tr>";
    xlsData += "</thead>";

    //Data
    xlsData += "<tbody>";
    console.log("Data to process ", data);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data.map((item, index) => {
      xlsData += "<tr>";
      for (let key in item) {
        xlsData +=
          "<td colspan='2' align='left'>" +
          this.preprocessLongNum(
            this.valueReformattedForMultilines(item[key])
          ) +
          "</td>";
      }
      xlsData += "</tr>";
    });
    xlsData += "</tbody>";

    return xlsTemp
      .replace("${table}", xlsData)
      .replace("${worksheet}", this.worksheet);
  }
  /*
		jsonToCSV
		---------------
		Transform json data into an CSV file.
		*/
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  jsonToCSV(data: Record<number, Record<string, unknown>>[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _self = this;
    const csvData: any[] = [];
    logger.log("Test json ", data);
    //Fields
    for (let key in data[0]) {
      csvData.push(key);
      csvData.push(",");
    }
    csvData.pop();
    csvData.push("\r\n");
    //Data
    data.map(function (item: any) {
      for (let key in item) {
        let escapedCSV = item[key] + "";

        if (_self.escapeCsv) {
          escapedCSV = '="' + escapedCSV + '"'; // cast Numbers to string
          if (escapedCSV.match(/[,"\n]/)) {
            escapedCSV = '"' + escapedCSV.replace(/"/g, '""') + '"';
          }
        }
        csvData.push(escapedCSV);
        csvData.push(",");
      }
      csvData.pop();
      csvData.push("\r\n");
    });
    logger.log("Testing print: ", csvData);
    return csvData.join("");
  }
  /*
		getProcessedJson
		---------------
		Get only the data to export, if no fields are set return all the data
		*/
  getProcessedJson(data: any, header: any) {
    let keys = this.getKeys(data, header);

    let newData: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _self = this;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data.map(function (item: any, index: any) {
      let newItem: Record<string, unknown> = {};
      for (let label in keys) {
        console.log("Label ", label, keys);
        let property = keys[label];
        newItem[label] = _self.getValue(property, item);
      }
      newData.push(newItem);
    });
    return newData;
  }
  getKeys(data: any, header: any) {
    if (header) {
      return header;
    }

    let keys: Record<string, unknown> = {};
    for (let key in data[0]) {
      keys[key] = key;
    }
    return keys;
  }
  /*
		parseExtraData
		---------------
		Parse title and footer attribute to the csv format
		*/
  parseExtraData(extraData: any, format: any) {
    let parseData = "";
    if (Array.isArray(extraData)) {
      for (var i = 0; i < extraData.length; i++) {
        if (extraData[i]) parseData += format.replace("${data}", extraData[i]);
      }
    } else {
      parseData += format.replace("${data}", extraData);
    }
    return parseData;
  }

  getValue(key: any, item: any) {
    const field = typeof key !== "object" ? key : key.field;
    let indexes = typeof field !== "string" ? [] : field.split(".");
    let value = this.defaultValue;

    console.log("Test value ", key, item);

    if (item["isSecurityGuard"] != null) {
      if (item["isSecurityGuard"] == true) {
        item["isSecurityGuard"] = "SO";
      } else if (item["isSecurityGuard"] == false) {
        item["isSecurityGuard"] = "ARO";
      }
    }

    if (item["isSecurityGuardTeam"] != null) {
      if (item["isSecurityGuardTeam"] == true) {
        item["isSecurityGuardTeam"] = "SO Team";
      } else if (item["isSecurityGuardTeam"] == false) {
        item["isSecurityGuardTeam"] = "ARO Team";
      }
    }

    if (item["isSG"] != null) {
      if (item["isSG"] == true) {
        item["isSG"] = "SO";
      } else if (item["isSG"] == false) {
        item["isSG"] = "ARO";
      }
    }

    if (item["archiveReason"] != null) {
      if (item["archiveReason"] == 1) {
        item["archiveReason"] = "Resigned";
      } else if (item["archiveReason"] == 2) {
        item["archiveReason"] = "Dismissed";
      } else if (item["archiveReason"] == 3) {
        item["archiveReason"] = "Absconded";
      } else if (item["archiveReason"] == 4) {
        item["archiveReason"] = "Retrenched";
      } else if (item["archiveReason"] == 5) {
        item["archiveReason"] = "Medically Boarded";
      } else if (item["archiveReason"] == 6) {
        item["archiveReason"] = "Deceased";
      } else if (item["archiveReason"] == 7) {
        item["archiveReason"] = "Suspended";
      }
    }

    if (item["date"] != null) {
      item["date"] =
        new Date(item["date"]).getFullYear() +
        "-" +
        (new Date(item["date"]).getMonth() + 1) +
        "-" +
        new Date(item["date"]).getDate();
    }

    /* if (item["firearm"] != null) {
        item["firearm"] =
          item["firearm"].serialNumber +
          " (" +
          item["firearm"].make +
          ", " +
          item["firearm"].model +
          ", " +
          item["firearm"].typeStr +
          ", " +
          item["firearm"].expiryDateStr +
          ")";
      } */

    if (item["purchaseDate"] != null) {
      item["purchaseDate"] =
        new Date(item["purchaseDate"]).getFullYear() +
        "-" +
        (new Date(item["purchaseDate"]).getMonth() + 1) +
        "-" +
        new Date(item["purchaseDate"]).getDate();
    }

    if (item["createdDate"] != null) {
      item["createdDate"] =
        new Date(item["createdDate"]).getFullYear() +
        "-" +
        (new Date(item["createdDate"]).getMonth() + 1) +
        "-" +
        new Date(item["createdDate"]).getDate();
    }

    if (item["updatedDate"] != null) {
      item["updatedDate"] =
        new Date(item["updatedDate"]).getFullYear() +
        "-" +
        (new Date(item["updatedDate"]).getMonth() + 1) +
        "-" +
        new Date(item["updatedDate"]).getDate();
    }

    if (item["startDate"] != null) {
      item["startDate"] =
        new Date(item["startDate"]).getFullYear() +
        "-" +
        (new Date(item["startDate"]).getMonth() + 1) +
        "-" +
        new Date(item["startDate"]).getDate();
    }

    if (item["endDate"] != null) {
      item["endDate"] =
        new Date(item["endDate"]).getFullYear() +
        "-" +
        (new Date(item["endDate"]).getMonth() + 1) +
        "-" +
        new Date(item["endDate"]).getDate();
    }

    if (item["expiryDate"] != null) {
      item["expiryDate"] =
        new Date(item["expiryDate"]).getFullYear() +
        "-" +
        (new Date(item["expiryDate"]).getMonth() + 1) +
        "-" +
        new Date(item["expiryDate"]).getDate();
    }

    if (item["fromDate"] != null) {
      item["fromDate"] =
        new Date(item["fromDate"]).getFullYear() +
        "-" +
        (new Date(item["fromDate"]).getMonth() + 1) +
        "-" +
        new Date(item["fromDate"]).getDate();
    }

    if (item["lastMaintenanceDate"] != null) {
      item["lastMaintenanceDate"] =
        new Date(item["lastMaintenanceDate"]).getFullYear() +
        "-" +
        (new Date(item["lastMaintenanceDate"]).getMonth() + 1) +
        "-" +
        new Date(item["lastMaintenanceDate"]).getDate();
    }
    if ("daysToExpiryCalc" == key) {
      const today = dayjs();
      const exp = dayjs(item["expiryDate"]);
      const rem = exp.diff(today, "d");
      console.log("Testing ", rem);
      item["daysToExpiryCalc"] = rem;

      // item["daysToExpiryCalc"] = Math.round(
      //   (dayjs(item["expiryDate"]).get("millisecond") -
      //     dayjs().get("millisecond")) /
      //     (1000 * 3600 * 24)
      // );
    }

    if (item["team"] != null) {
      if (item["team"].isSecurityGuardTeam == true) {
        item["team"].isSecurityGuardTeam = "SO Team";
      } else if (item["team"].isSecurityGuardTeam == false) {
        item["team"].isSecurityGuardTeam = "ARO Team";
      }
    }

    /* logger.log("test"); */

    if (!field) value = item;
    else if (indexes.length > 1)
      value = this.getValueFromNestedItem(item, indexes);
    else value = this.parseValue(item[field]);

    // eslint-disable-next-line no-prototype-builtins
    if (key.hasOwnProperty("callback"))
      value = this.getValueFromCallback(value, key.callback);

    // logger.log(value);

    return value;
  }

  /*
    convert values with newline \n characters into <br/>
    */
  valueReformattedForMultilines(value: any) {
    if (typeof value == "string") return value.replace(/\n/gi, "<br/>");
    else return value;
  }
  preprocessLongNum(value: any) {
    if (this.stringifyLongNum) {
      if (String(value).startsWith("0x")) {
        return value;
      }
      if (!isNaN(value) && value != "") {
        if (value > 99999999999 || value < 0.0000000000001) {
          return '="' + value + '"';
        }
      }
    }

    return value;
  }
  getValueFromNestedItem(item: any, indexes: any) {
    let nestedItem = item;
    for (let index of indexes) {
      if (nestedItem) nestedItem = nestedItem[index];
    }
    return this.parseValue(nestedItem);
  }

  getValueFromCallback(item: any, callback: any) {
    if (typeof callback !== "function") return this.defaultValue;
    const value = callback(item);
    return this.parseValue(value);
  }
  parseValue(value: any) {
    return value || value === 0 || typeof value === "boolean"
      ? value
      : this.defaultValue;
  }
  base64ToBlob(data: any, mime: any) {
    let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
    let bstr = atob(base64);
    let n = bstr.length;
    let u8arr = new Uint8ClampedArray(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
}

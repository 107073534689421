
import dayjs from "dayjs";
import jsPDF from "jspdf";
import JsonExcel from "@/components/JsonExcel.vue";
import autoTable from "jspdf-autotable";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { Downloader } from "@/hooks";
import logger from "@/plugins/logger";

Vue.component("expansionPanel", {
  render: function (createElement) {
    return createElement("div", [
      this.expansionInfo.obj != null
        ? createElement("p", this.expansionInfo.obj)
        : "",
      this.expansionInfo.obj1 != null
        ? createElement("p", this.expansionInfo.obj1)
        : "",
      this.expansionInfo.obj2 != null
        ? createElement("p", this.expansionInfo.obj2)
        : "",
      this.expansionInfo.obj3 != null
        ? createElement("p", this.expansionInfo.obj3)
        : "",
      this.expansionInfo.obj4 != null
        ? createElement("p", this.expansionInfo.obj4)
        : "",
      this.expansionInfo.obj5 != null
        ? createElement("p", this.expansionInfo.obj5)
        : "",
      this.expansionInfo.obj6 != null
        ? createElement("p", this.expansionInfo.obj6)
        : "",
      this.expansionInfo.obj7 != null
        ? createElement("p", this.expansionInfo.obj7)
        : "",
      this.expansionInfo.obj8 != null
        ? createElement("p", this.expansionInfo.obj8)
        : "",
      this.expansionInfo.obj9 != null
        ? createElement("p", this.expansionInfo.obj9)
        : "",
      this.expansionInfo.obj10 != null
        ? createElement("p", this.expansionInfo.obj10)
        : "",
      this.expansionInfo.obj11 != null
        ? createElement("p", this.expansionInfo.obj11)
        : "",
      this.expansionInfo.obj12 != null
        ? createElement("p", this.expansionInfo.obj12)
        : "",
    ]);
  },
  props: { expansionInfo: { type: Object, required: true } },
});

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    daysRemaining: function (value: string) {
      const today = dayjs();
      const exp = dayjs(value);
      const rem = exp.diff(today, "d");

      return rem;
    },
  },
})
export default class OModal extends Vue {
  search = "";
  sortDesc = true;
  sortBy = "";
  expanded: any[] = [];
  @PropSync("open") overviewModal!: boolean;
  @Prop() data!: {
    tableData: [];
    tableHeaders: { text: string; value: string };
    tableName: string;
    title: string;
    sortBy: string;
  };
  expansionInfo = {};
  expReports: any[] = []; // TODO: DEPRECATE this as soon as possible, note that it is used by the XLS/CSV exporter
  get getExportReports() {
    switch (this.data.tableName) {
      case "complianceScore":
        return this.data.tableData.map((elem: any) => {
          return {
            "ARO/SO": elem.isSecurityGuard == true ? "SO" : "ARO",
            "Full Name": elem.name + ", " + elem.surname,
            "Reg No": elem.psiraRegistrationNumber,
            "Grade/Firearm (PSIRA)":
              elem.psiraRegistrationDocuments != null
                ? this.getValue(elem.psiraRegistrationDocuments, "grade", "na")
                : "",
            "Expiry Date (PSIRA)":
              elem.psiraRegistrationDocuments != null
                ? this.getValue(
                    elem.psiraRegistrationDocuments,
                    "expiryDateStr",
                    "na"
                  )
                : " ",
            "Days to Expiry (PSIRA)":
              elem.psiraRegistrationDocuments != null
                ? this.getValue(
                    elem.psiraRegistrationDocuments,
                    "expiryDate",
                    "na"
                  )
                : " ",
            "Grade/Firearm (SAPS Competency)":
              elem.sapsCompetencyCertificates != null
                ? this.getValue(
                    elem.sapsCompetencyCertificates,
                    "firearmType",
                    "false"
                  )
                : "",
            "Expiry Date (SAPS Competency)":
              elem.sapsCompetencyCertificates != null
                ? this.getValue(
                    elem.sapsCompetencyCertificates,
                    "expiryDateStr",
                    "false"
                  )
                : "",
            "Days to Expiry (SAPS Competency)":
              elem.sapsCompetencyCertificates != null
                ? this.getValue(
                    elem.sapsCompetencyCertificates,
                    "expiryDate",
                    "false"
                  )
                : "",
            "Grade/Firearm (Regulation 21)":
              elem.sapsCompetencyCertificates != null
                ? this.getValue(
                    elem.sapsCompetencyCertificates,
                    "firearmType",
                    "true"
                  )
                : "",
            "Expiry Date (Regulation 21)":
              elem.sapsCompetencyCertificates != null
                ? this.getValue(
                    elem.sapsCompetencyCertificates,
                    "expiryDateStr",
                    "true"
                  )
                : "",
            "Days to Expiry (Regulation 21)":
              elem.sapsCompetencyCertificates != null
                ? this.getValue(
                    elem.sapsCompetencyCertificates,
                    "expiryDate",
                    "true"
                  )
                : "",
            "Expiry Date (Driving License)":
              elem.drivingLicenses != null
                ? this.getValue(elem.drivingLicenses, "expiryDateStr", "na")
                : "",
            "Days to Expiry (Driving License)":
              elem.drivingLicenses != null
                ? this.getValue(elem.drivingLicenses, "expiryDate", "na")
                : "",
            Team:
              elem.teamArmResponseOfficers != null
                ? this.getValue(elem.teamArmResponseOfficers, "team", "na")
                : "",
          };
        });
      case "firearmComplianceScore":
        return this.data.tableData.map((elem: any) => {
          return {
            Make: elem.make != null ? elem.make : " N/A",
            Type: elem.typeStr != null ? elem.typeStr : " N/A",
            "S/N": elem.serialNumber != null ? elem.serialNumber : " N/A",
            "Expiry Date":
              elem.expiryDateStr != null ? elem.expiryDateStr : " N/A",
            "Days To Expiry":
              elem.expiryDate != null
                ? this.getDaysToExpiry(elem.expiryDate)
                : " ",
            "Currently Booked By":
              elem.bookedBy != null ? elem.bookedBy : " N/A", // TODO: DEPRECATED
            "No of Magazines":
              elem.numberOfMagazines != null ? elem.numberOfMagazines : "N/A",
            "Last Maintenance Date":
              elem.lastMaintenanceDate != null
                ? dayjs(elem.lastMaintenanceDate).format("YYYY-MM-DD")
                : " N/A",
            Branch: elem.branch != null ? elem.branch : " N/A",
            Model: elem.model != null ? elem.model : " N/A",
            Picture: elem.imagePath != null ? elem.imagePath : "",
            License: elem.licensePath != null ? elem.licensePath : "",
            Calibre: elem.calibre != null ? elem.calibre : " N/A",
            "License Number":
              elem.licenseNumber != null ? elem.licenseNumber : " N/A",
            "License Type":
              elem.licenseType != null ? elem.licenseType.toString() : " N/A",
            "Date of Issue":
              elem.fromDateStr != null ? elem.fromDateStr : " N/A",
            "Is Renewed":
              elem.isRenewed != null ? elem.isRenewed.toString() : " N/A",
            "Renewed Date":
              elem.renewedDate != null
                ? dayjs(elem.renewedDate).format("YYYY-MM-DD")
                : " N/A",
          };
        });
      default:
        return [];
    }
  }
  expFields: any = {}; //TODO: DEPRECATE this as soon as possible, note that it is used by the XLS/CSV exporter
  get getExportFields() {
    switch (this.data.tableName) {
      case "complianceScore":
        return {
          "ARO/SO": "ARO/SO",
          "Full Name": "Full Name",
          "Reg No": "Reg No",
          "Grade/Firearm (PSIRA)": "Grade/Firearm (PSIRA)",
          "Expiry Date (PSIRA)": "Expiry Date (PSIRA)",
          "Days to Expiry (PSIRA)": "Days to Expiry (PSIRA)",

          "Grade/Firearm (SAPS Competency)": "Grade/Firearm (SAPS Competency)",
          "Expiry Date (SAPS Competency)": "Expiry Date (SAPS Competency)",
          "Days to Expiry (SAPS Competency)":
            "Days to Expiry (SAPS Competency)",

          "Grade/Firearm (Regulation 21)": "Grade/Firearm (Regulation 21)",
          "Expiry Date (Regulation 21)": "Expiry Date (Regulation 21)",
          "Days to Expiry (Regulation 21)": "Days to Expiry (Regulation 21)",

          "Expiry Date (Driving License)": "Expiry Date (Driving License)",
          "Days to Expiry (Driving License)":
            "Days to Expiry (Driving License)",
          Team: "Team",
        };
      case "firearmComplianceScore":
        return {
          Make: "Make",
          Type: "Type",
          "S/N": "S/N",
          "Expiry Date": "Expiry Date",
          "Days To Expiry": "Days To Expiry",
          "Currently Booked By": "Currently Booked By",
          "No of Magazines": "No of Magazines",
          "Last Maintenance Date": "Last Maintenance Date",
          Branch: "Branch",
          Model: "Model",
          Picture: "Picture",
          License: "License",
          Calibre: "Calibre",
          "License Number": "License Number",
          "License Type": "License Type",
          "Date of Issue": "Date of Issue",
          "Is Renewed": "Is Renewed",
          "Renewed Date": "Renewed Date",
        };
      default:
        return {};
    }
  }
  get getPDFhead() {
    switch (this.data.tableName) {
      case "complianceScore":
        return [
          { header: "ARO/SO", dataKey: "ARO/SO" },
          { header: "Full Name", dataKey: "Full Name" },
          { header: "Reg No", dataKey: "Reg No" },
          { header: "Grade/Firearm (PSIRA)", dataKey: "Grade/Firearm (PSIRA)" },
          { header: "Expiry Date (PSIRA)", dataKey: "Expiry Date (PSIRA)" },
          {
            header: "Days to Expiry (PSIRA)",
            dataKey: "Days to Expiry (PSIRA)",
          },
          {
            header: "Grade/Firearm (SAPS Competency)",
            dataKey: "Grade/Firearm (SAPS Competency)",
          },
          {
            header: "Expiry Date (SAPS Competency)",
            dataKey: "Expiry Date (SAPS Competency)",
          },
          {
            header: "Days to Expiry (SAPS Competency)",
            dataKey: "Days to Expiry (SAPS Competency)",
          },
          {
            header: "Grade/Firearm (Regulation 21)",
            dataKey: "Grade/Firearm (Regulation 21)",
          },
          {
            header: "Expiry Date (Regulation 21)",
            dataKey: "Expiry Date (Regulation 21)",
          },
          {
            header: "Days to Expiry (Regulation 21)",
            dataKey: "Days to Expiry (Regulation 21)",
          },
          {
            header: "Expiry Date (Driving License)",
            dataKey: "Expiry Date (Driving License)",
          },
          {
            header: "Days to Expiry (Driving License)",
            dataKey: "Days to Expiry (Driving License)",
          },
          { header: "Team", dataKey: "Team" },
        ];
      case "firearmComplianceScore":
        return [
          { header: "Make", dataKey: "Make" },
          { header: "Type", dataKey: "Type" },
          { header: "S/N", dataKey: "S/N" },
          { header: "Expiry Date", dataKey: "Expiry Date" },
          { header: "Days To Expiry", dataKey: "Days To Expiry" },
          { header: "Currently Booked By", dataKey: "Currently Booked By" },
          { header: "No of Magazines", dataKey: "No of Magazines" },
          { header: "Last Maintenance Date", dataKey: "Last Maintenance Date" },
          { header: "Branch", dataKey: "Branch" },
          { header: "Model", dataKey: "Model" },
          { header: "Picture", dataKey: "Picture" },
          { header: "License", dataKey: "License" },
          { header: "Calibre", dataKey: "Calibre" },
          { header: "License Number", dataKey: "License Number" },
          { header: "License Type", dataKey: "License Type" },
          { header: "Date of Issue", dataKey: "Date of Issue" },
          { header: "Is Renewed", dataKey: "Is Renewed" },
          { header: "Renewed Date", dataKey: "Renewed Date" },
        ];
      default:
        return [];
    }
  }
  // get getPDFbody(){}
  async generatePDF(): Promise<void> {
    try {
      let fileName = "Default";
      let horizontalPageBreakRepeat = "";
      if (this.data.tableName == "complianceScore") {
        fileName = `ARO/SO Compliance Score Expired (${dayjs().format(
          "YYYY-MM-DD"
        )})`;
        horizontalPageBreakRepeat = "Full Name";
      } else if (this.data.tableName == "firearmComplianceScore") {
        fileName = `Firearm Compliance Score Expired (${dayjs().format(
          "YYYY-MM-DD"
        )})`;
        horizontalPageBreakRepeat = "S/N";
      }
      await Downloader.generatePdfAndSave({
        fileName: fileName,
        columns: this.getPDFhead,
        body: this.expReports,
        horizontalPageBreakRepeat: horizontalPageBreakRepeat,
      });
    } catch (err) {
      logger.error(err);
      return Promise.reject(err);
    }
  }
  populateExportData(): void {
    if (this.data.tableName == "complianceScore") {
      this.expFields = this.getExportFields;
      this.expReports = this.getExportReports;
    } else if (this.data.tableName == "firearmComplianceScore") {
      this.expFields = this.getExportFields;
      this.expReports = this.getExportReports;
    }
    logger.log(`export report (${this.data.tableName}):`, this.expReports);
  }

  openContextRow(i: any): void {
    this.clearExpansion();
    this.expanded.push(i);

    switch (this.data.tableName) {
      case "psiraExpiring":
        this.expansionInfo = {
          obj: `File: `,
          obj1: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj2: `Renewed Date: ${dayjs(i.renewedDate).format("YYYY-MM-DD")}`,
          obj3: `Renewed File: `,
        };
        break;
      case "firearmExpiring":
        this.expansionInfo = {
          obj: `Number of Magazines: ${i.numberOfMagazines}`,
          obj1: `Last Maintenence Date: ${i.lastMaintenanceDate}`,
          obj2: `Branch: ${i.branch}`,
          obj3: `Model: ${i.model}`,
          obj4: `Picture: `,
          obj5: `License: `,
          obj6: `Calibre: ${i.calibre}`,
          obj7: `License Number: ${i.licenseNumber}`,
          obj8: `License Type: ${i.licenseType}`,
          obj9: `Date of Issue: ${i.fromDateStr}`,
          obj10: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj11: `Renewed Date: ${
            i.renewedDate != null
              ? dayjs(i.renewedDate).format("YYYY-MM-DD")
              : " "
          }`,
        };
        break;
      case "sapsExpiring":
        this.expansionInfo = {
          obj: `File: `,
          obj1: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj2: `Renewed Date: ${dayjs(i.renewedDate).format("YYYY-MM-DD")}`,
          obj3: `Renewed File: `,
        };
        break;
      case "regulationExpiring":
        this.expansionInfo = {
          obj: `File: `,
          obj1: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj2: `Renewed Date: ${dayjs(i.renewedDate).format("YYYY-MM-DD")}`,
          obj3: `Renewed File: `,
        };
        break;
      case "drivingExpiring":
        this.expansionInfo = {
          obj: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj1: `Renewed Date: ${dayjs(i.renewedDate).format("YYYY-MM-DD")}`,
          obj2: `Renewed File: ${i.renewedDocumentFilePath}`,
        };
        break;
      case "complianceScore":
        this.expansionInfo = {
          obj: `Grade/Firearm (PSIRA): ${
            i.psiraRegistrationDocuments != null
              ? this.getValue(i.psiraRegistrationDocuments, "grade", "na")
              : " "
          }`,
          obj1: `Expiry Date (PSIRA): ${
            i.psiraRegistrationDocuments != null
              ? this.getValue(
                  i.psiraRegistrationDocuments,
                  "expiryDateStr",
                  "na"
                )
              : " "
          }`,
          obj2: `Days to Expiry (PSIRA): ${
            i.psiraRegistrationDocuments != null
              ? this.getValue(i.psiraRegistrationDocuments, "expiryDate", "na")
              : " "
          }`,
          obj4: `Grade/Firearm (SAPS Competency): ${
            i.sapsCompetencyCertificates != null
              ? this.getValue(
                  i.sapsCompetencyCertificates,
                  "firearmType",
                  "false"
                )
              : ""
          }`,
          obj5: `Expiry Date (SAPS Competency): ${
            i.sapsCompetencyCertificates != null
              ? this.getValue(
                  i.sapsCompetencyCertificates,
                  "expiryDateStr",
                  "false"
                )
              : ""
          }`,
          obj6: `Days to Expiry (SAPS Competency): ${
            i.sapsCompetencyCertificates != null
              ? this.getValue(
                  i.sapsCompetencyCertificates,
                  "expiryDate",
                  "false"
                )
              : ""
          }`,
          obj7: `Grade/Firearm (Regulation 21): ${
            i.sapsCompetencyCertificates != null
              ? this.getValue(
                  i.sapsCompetencyCertificates,
                  "firearmType",
                  "true"
                )
              : ""
          }`,
          obj8: `Expiry Date (Regulation 21): ${
            i.sapsCompetencyCertificates != null
              ? this.getValue(
                  i.sapsCompetencyCertificates,
                  "expiryDateStr",
                  "true"
                )
              : ""
          }`,
          obj9: `Days to Expiry (Regulation 21): ${
            i.sapsCompetencyCertificates != null
              ? this.getValue(
                  i.sapsCompetencyCertificates,
                  "expiryDate",
                  "true"
                )
              : ""
          }`,
          obj10: `Expiry Date (Driving License): ${
            i.drivingLicenses != null
              ? this.getValue(i.drivingLicenses, "expiryDateStr", "na")
              : ""
          }`,
          obj11: `Days to Expiry (Driving License): ${
            i.drivingLicenses != null
              ? this.getValue(i.drivingLicenses, "expiryDate", "na")
              : ""
          }`,
          obj12: `Team: ${
            i.teamArmResponseOfficers != null
              ? this.getValue(i.teamArmResponseOfficers, "team", "na")
              : ""
          }`,
        };
        break;
      case "firearmComplianceScore":
        this.expansionInfo = {
          obj: `Number of Magazines: ${i.numberOfMagazines}`,
          obj1: `Last Maintenence Date: ${i.lastMaintenanceDate}`,
          obj2: `Branch: ${i.branch}`,
          obj3: `Model: ${i.model}`,
          obj4: `Picture: ${i.imagePath}`,
          obj5: `License: ${i.licensePath}`,
          obj6: `Calibre: ${i.calibre}`,
          obj7: `License Number: ${i.licenseNumber}`,
          obj8: `License Type: ${i.licenseType}`,
          obj9: `Date of Issue: ${i.fromDateStr}`,
          obj10: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj11: `Renewed Date: ${
            i.renewedDate != null
              ? dayjs(i.renewedDate).format("YYYY-MM-DD")
              : " "
          }`,
        };
        break;
      case "firearmMaintenanceDateCount":
        this.expansionInfo = {
          obj: `Currently Booked By: ${i.bookedBy}`, // TODO: DEPRECATED
          obj1: `Number of Magazines: ${i.numberOfMagazines}`,
          obj2: `Branch: ${i.branch}`,
          obj3: `Make: ${i.make}`,
          obj4: `Picture: ${i.imagePath}`,
          obj5: `License: ${i.licensePath}`,
          obj6: `Calibre: ${i.calibre}`,
          obj7: `License Number: ${i.licenseNumber}`,
          obj8: `License Type: ${i.licenseType}`,
          obj9: `Date of Issue: ${i.fromDateStr}`,
        };
        break;
      case "psiraRenewed":
        this.expansionInfo = {
          obj: `File: `,
          obj1: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj2: `Renewed Date: ${
            i.renewedDate != null
              ? dayjs(i.renewedDate).format("YYYY-MM-DD")
              : " "
          }`,
          obj3: `Renewed File: `,
        };
        break;
      case "firearmRenewed":
        this.expansionInfo = {
          obj: `Number of Magazines: ${i.numberOfMagazines}`,
          obj1: `Last Maintenence Date: ${dayjs(i.lastMaintenanceDate).format(
            "YYYY-MM-DD"
          )}`,
          obj2: `Branch: ${i.branch}`,
          obj3: `Model: ${i.model}`,
          obj4: `Picture: ${i.imagePath}`,
          obj5: `License: ${i.licensePath}`,
          obj6: `Calibre: ${i.calibre}`,
          obj7: `License Number: ${i.licenseNumber}`,
          obj8: `License Type: ${i.licenseType}`,
          obj9: `Date of Issue: ${i.fromDateStr}`,
          obj10: `Is Renewed:  ${i.isRenewed == true ? "Yes" : "No"}`,
          obj11: `Renewed Date: ${
            i.renewedDate != null
              ? dayjs(i.renewedDate).format("YYYY-MM-DD")
              : " "
          }`,
        };
        break;
      case "sapsRenewed":
        this.expansionInfo = {
          obj: `File: `,
          obj1: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj2: `Renewed Date: ${
            i.renewedDate != null
              ? dayjs(i.renewedDate).format("YYYY-MM-DD")
              : " "
          }`,
          obj3: `Renewed File: `,
        };
        break;
      case "drivingRenewed":
        this.expansionInfo = {
          obj: `Is Renewed: ${i.isRenewed == true ? "Yes" : "No"}`,
          obj1: `Renewed Date: ${dayjs(i.renewedDate).format("YYYY-MM-DD")}`,
          obj2: `Renewed File: ${i.renewedDocumentFilePath}`,
        };
        break;
      case "currentShifts":
        this.expansionInfo = {
          obj: `Team Name: ${i.team.name}`,
          obj1: `Team Type: ${
            i.team.isSecurityGuardTeam == false ? "ARO Team" : "SO Team"
          }`,
          obj2: `Team Members: ${
            i.shiftArmResponseOfficers.isTeamMember == true
              ? i.shiftArmResponseOfficers.armResponseOfficer
              : []
          }`,
          obj3: `Standby Shift Members: ${
            i.shiftArmResponseOfficers.isTeamMember == false
              ? i.shiftArmResponseOfficers.armResponseOfficer
              : []
          }`,
        };
        break;
      case "firearmBookedOutCount":
        this.expansionInfo = {
          obj: `Number of Magazines: ${i.firearm.numberOfMagazines}`,
          obj1: `Last Maintenence Date: ${dayjs(
            i.firearm.lastMaintenanceDate
          ).format("YYYY-MM-DD")}`,
          obj2: `Branch: ${i.firearm.branch}`,
          obj3: `Model: ${i.firearm.model}`,
          obj4: `Picture: ${i.firearm.imagePath}`,
          obj5: `License: ${i.firearm.licensePath}`,
          obj6: `Calibre: ${i.firearm.calibre}`,
          obj7: `License Number: ${i.firearm.licenseNumber}`,
          obj8: `License Type: ${i.firearm.licenseType}`,
          obj9: `Date of Issue: ${i.fromDateStr}`,
          obj10: `Is Renewed:  ${i.firearm.isRenewed == true ? "Yes" : "No"}`,
          obj11: `Renewed Date: ${
            i.firearm.renewedDate != null
              ? dayjs(i.firearm.renewedDate).format("YYYY-MM-DD")
              : " "
          }`,
        };
        break;
      case "firearmBookedOutCountOutstanding":
        this.expansionInfo = {
          obj: `Number of Magazines: ${i.firearm.numberOfMagazines}`,
          obj1: `Last Maintenence Date: ${dayjs(
            i.firearm.lastMaintenanceDate
          ).format("YYYY-MM-DD")}`,
          obj2: `Branch: ${i.firearm.branch}`,
          obj3: `Model: ${i.firearm.model}`,
          obj4: `Picture: ${i.firearm.imagePath}`,
          obj5: `License: ${i.firearm.licensePath}`,
          obj6: `Calibre: ${i.firearm.calibre}`,
        };
        break;
      case "ammunitionBookedOut":
        this.expansionInfo = {};
        this.expanded = [];
        break;
      case "incidentCount":
        this.expansionInfo = {
          obj: `Firearm: ${i.firearm.serialNumber} (${i.firearm.make}, ${i.firearm.model}, ${i.firearm.typeStr})`,
          obj1: `Ammunition Calibre: ${i.ammunition.calibre}`,
          obj2: `Number of Rounds: ${
            i.roundDischarged != null ? i.roundDischarged : ""
          }`,
          obj3: `Description: ${i.description != null ? i.description : ""}`,
          obj4: `Objects Involved: ${
            i.objectInvolved != null ? i.objectInvolved : ""
          }`,
          obj5: `Police Station: ${
            i.policeStation != null ? i.policeStation : ""
          }`,
          obj6: `SAP Case Number: ${
            i.sapCaseNumber != null ? i.sapCaseNumber : ""
          }`,
          obj7: `SAP 13 Number: ${i.saP13Number != null ? i.saP13Number : ""}`,
          obj8: `SAP 14 Number: ${i.saP14Number != null ? i.saP14Number : ""}`,
          obj9: `Investigation Officer: ${
            i.investigationOfficer != null ? i.investigationOfficer : ""
          }`,
          obj10: `Officer Contact: ${
            i.investigationOfficerContact != null
              ? i.investigationOfficerContact
              : ""
          }`,
          obj11: `Next Action Steps: ${
            i.nextActionSteps != null ? i.nextActionSteps : ""
          }`,
          obj12: `File: ${i.filePath}`,
        };
        break;
      default:
        this.expansionInfo = { obj: "There is no extra Info To Display" };
        break;
    }

    logger.log("Expansion Info: ", this.expansionInfo);
  }

  clearExpansion(): void {
    this.expanded = [];
    this.expansionInfo;
  }

  getDaysToExpiry(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");
    return String(rem);
  }

  getValue(array: any[], item: string, r21: string): string {
    let value = "";
    if (r21 == "na") {
      if (item == "expiryDate") {
        array.forEach((elem) => {
          value = (this.getDaysToExpiry(elem[item]) + ",").replace(/,*$/, "");
        });
      } else if (item == "team") {
        array.forEach((elem) => {
          value = (elem[item].name + ",").replace(/,*$/, "");
        });
      } else {
        array.forEach((elem) => {
          value = (elem[item] + ",").replace(/,*$/, "");
        });
      }
    } else if (r21 == "true") {
      if (item == "expiryDate") {
        array.forEach((elem) => {
          if (elem["isRegulation21Certificate"] == true) {
            value = (this.getDaysToExpiry(elem[item]) + ",").replace(/,*$/, "");
          }
        });
      } else {
        array.forEach((elem) => {
          if (elem["isRegulation21Certificate"] == true) {
            value = (elem[item] + ",").replace(/,*$/, "");
          }
        });
      }
    } else if (r21 == "false") {
      if (item == "expiryDate") {
        array.forEach((elem) => {
          if (elem["isRegulation21Certificate"] == false) {
            value = (this.getDaysToExpiry(elem[item]) + ",").replace(/,*$/, "");
          }
        });
      } else {
        array.forEach((elem) => {
          if (elem["isRegulation21Certificate"] == false) {
            value = (elem[item] + ",").replace(/,*$/, "");
          }
        });
      }
    }
    return value;
  }

  /* if (i >= 211) return "green";
    else if (i >= 181 && i <= 210) return "yellow";
    else if (i >= 151 && i <= 180) return "orange";
    else if (i >= 0 && i <= 150) return "red";
    else return "red darken-4"; */

  getColor(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");

    if (
      this.data.tableName == "firearmExpiring" ||
      this.data.tableName == "sapsExpiring" ||
      this.data.tableName == "firearmComplianceScore" ||
      this.data.tableName == "firearmRenewed" ||
      this.data.tableName == "sapsRenewed" ||
      this.data.tableName == "firearmBookedOutCount" ||
      this.data.tableName == "incidentCount"
    ) {
      if (rem >= 211) return "green";
      else if (rem >= 181 && rem <= 210) return "yellow";
      else if (rem >= 151 && rem <= 180) return "orange";
      else if (rem >= 0 && rem <= 150) return "red";
      else return "red darken-4";
    } else if (
      this.data.tableName == "psiraExpiring" ||
      this.data.tableName == "regulationExpiring" ||
      this.data.tableName == "drivingExpiring" ||
      this.data.tableName == "psiraRenewed" ||
      this.data.tableName == "drivingRenewed"
    ) {
      if (rem >= 151) return "green";
      else if (rem >= 121 && rem <= 150) return "yellow";
      else if (rem >= 91 && rem <= 120) return "orange";
      else if (rem >= 0 && rem <= 90) return "red";
      else return "red darken-4";
    } else if (this.data.tableName == "firearmMaintenanceDateCount") {
      if (rem >= 0 && rem <= 60) return "red";
      else if (rem >= 61) return "transparent";
      else return "red darken-4";
    } else {
      if (rem >= 45) return "green";
      else if (rem >= 30 && rem <= 44) return "yellow";
      else if (rem >= 16 && rem <= 29) return "orange";
      else if (rem >= 0 && rem <= 15) return "red";
      else return "red";
    }
  }

  @Watch("data.tableHeaders", { immediate: true })
  tableHedersChanged(): void {
    logger.log("Modal Table Header: ", this.data.tableHeaders);
  }
  @Watch("data.tableData", { immediate: true })
  tableDataChanged(): void {
    logger.log("Modal Table Data: ", this.data.tableData);
    this.populateExportData();
  }
}

import { axiosInstance } from "@/plugins/axios";
import { Base64FilesViewModel } from "models";

const Profile = () => {
  const baseUrl = "/api/Profile";

  const UpdateUserSignature = async (
    data: Base64FilesViewModel
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateUserSignature",
      data
    );
    return Promise.resolve(res.data);
  };

  return { UpdateUserSignature };
};
const temp = Profile();
export default temp;

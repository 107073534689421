
import { Component, Vue } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import { ActivityLog, User, Downloader } from "@/hooks";
import { ActivityLogModel, ManageUserViewModel } from "models";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class ActivityLogs extends Vue {
  items: ActivityLogModel[] = [];
  reportsFiltered: ActivityLogModel[] = [];
  users: ManageUserViewModel[] = [];
  user: string | null = null;
  get getLoading() {
    return this.loading;
  }
  get fileName() {
    return `ActivityLog-${this.fromDate}-${this.toDate}`;
  }
  loading = {
    pdf: false,
    excel: false,
    csv: false,
    api: false,
  };
  search = "";
  sortDesc = false;
  sortBy = "date";
  action: number | null = null;
  actions = [
    { id: 0, text: "Add" },
    { id: 1, text: "Delete" },
    { id: 2, text: "Update" },
    { id: 3, text: "Archive" },
    { id: 4, text: "View" },
  ];
  fromDateMenu = false;
  fromDate = dayjs().format("YYYY-MM-DD");
  toDateMenu = false;
  toDate = dayjs().add(1, "day").format("YYYY-MM-DD");
  toTimeMenu = false;
  toTime = dayjs().format("HH:mm");
  endTimeMenu = false;
  endTime = dayjs().format("HH:mm");
  detailsModal = false;
  oldValues = "";
  newValues = "";
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Date & Time", value: "date" },
    { text: "User", value: "userEmail" },
    { text: "Action", value: "auditType" },
    { text: "Description", value: "description" },
  ];
  expReports: {
    "Date & Time": string;
    User: string;
    Action: string | number;
    Description: string;
  }[] = [];
  expFields = {
    "Date & Time": "Date & Time",
    User: "User",
    Action: "Action",
    Description: "Description",
  };

  async viewDetails(id: number): Promise<void> {
    await this.details(id);
    this.detailsModal = true;
  }

  async actionChanged(): Promise<void> {
    await this.loadItems();
    if (this.action != null) {
      this.items = this.items.filter((elem) => elem.auditType == this.action);
    }
  }

  async getUsers(): Promise<void> {
    try {
      const res = await User.getUsers();
      this.users = res.manageUsers;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async refresh(): Promise<void> {
    this.items = [];
    this.loading.api = true;
    try {
      if (this.action != null && this.user != null) {
        const res = await ActivityLog.Refresh(
          this.fromDate,
          this.toDate,
          this.toTime,
          this.endTime,
          this.action,
          this.user
        );
        this.items = res;
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.api = false;
    }
  }

  async details(id: number): Promise<void> {
    const res = await ActivityLog.Details(id);
    this.oldValues = res.details != null ? res.details.oldValues : " ";
    this.newValues = res.details != null ? res.details.newValues : " ";

    return Promise.resolve();
  }

  updateReportsFiltered(event: any) {
    this.reportsFiltered = event ? (event as any[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.reportsFiltered);
  }

  async generatePDF(): Promise<void> {
    try {
      Vue.set(this.loading, "pdf", true);
      // this.loading.pdf = true;
      const columns = [
        { header: "Date & Time", dataKey: "Date & Time" },
        { header: "User", dataKey: "User" },
        { header: "Action", dataKey: "Action" },
        { header: "Description", dataKey: "Description" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `ActivityLog-${this.fromDate}-${this.toDate}`,
        columns: columns,
        body: this.expReports,
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Vue.set(this.loading, "pdf", false);
    }
  }

  async loadItems(): Promise<void> {
    this.items = [];
    try {
      this.loading.api = true;
      const res = await ActivityLog.getActivityLog(this.fromDate, this.toDate);
      this.items = res;
      this.expReports = this.populateExportData(this.items);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.api = false;
    }
  }

  async mounted(): Promise<void> {
    await this.loadItems();
    await this.getUsers();
  }

  populateExportData(reports: ActivityLogModel[]) {
    return reports.map((elem: ActivityLogModel) => {
      return {
        "Date & Time": dayjs(elem.date).format("YYYY-MM-DD HH:mm"),
        User: elem.userEmail != null ? elem.userEmail : "",
        Action:
          elem.auditType == 0
            ? "Add"
            : elem.auditType == 1
            ? "Delete"
            : elem.auditType == 2
            ? "Update"
            : elem.auditType == 3
            ? "Archive"
            : elem.auditType == 4
            ? "View"
            : elem.auditType,
        Description: elem.description != null ? elem.description : "",
      };
    });
  }
}


import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { Incidents } from "@/hooks";
import { Ammunition, AROViewModel, Firearm, Incident } from "models";
import dayjs from "dayjs";

@Component({
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class IncidentModal extends Vue {
  @PropSync("open") modalOpen!: boolean;
  @Prop() readonly data!: {
    type: "Ammo" | "Firearm" | "ARO";
    selectedItem: any;
  };
  loading = false;
  items: Incident[] = [];
  search: string | null = null;
  sortDesc = true;
  sortBy = "date";
  expanded = [];
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Incident No", value: "incidentNumber" },
    { text: "Incident Type", value: "incidentType.name" },
    { text: "Date", value: "date" },
    { text: "Time", value: "time" },
    { text: "ARO/SO", value: "armResponseOfficer.name" },
    { text: "", value: "data-table-expand" },
  ];

  @Watch("modalOpen", { immediate: true })
  async modalOpenChanged(): Promise<void> {
    if (this.modalOpen == true) {
      await this.Init();
    } else {
      this.items = [];
      this.search = null;
    }
  }

  async Init(): Promise<void> {
    try {
      this.loading = true;

      switch (this.data!.type) {
        case "ARO":
          await this.loadIncidentsForARO(this.data.selectedItem);
          break;
        case "Ammo":
          await this.loadIncidentsForAmmo(this.data.selectedItem);
          break;
        case "Firearm":
          await this.loadIncidentsForFirearm(this.data.selectedItem);
          break;
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async loadIncidentsForFirearm(item: Firearm): Promise<void> {
    try {
      const resp = await Incidents.getIncidentOnFirearmId(item.id);
      this.items = resp;

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loadIncidentsForARO(item: AROViewModel): Promise<void> {
    try {
      const resp = await Incidents.getIncidentOnAROId(item.id!);
      this.items = resp;

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loadIncidentsForAmmo(item: Ammunition): Promise<void> {
    try {
      const resp = await Incidents.getIncidentOnAmmunitionId(item.id);
      this.items = resp;

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}


import { Component, Vue, Watch } from "vue-property-decorator";
import PermitExpansionPanel from "@/partials/PermitExpansionPanel.vue";
import ReturnPermit from "@/components/ReturnPermit.vue";
import AuthModule from "@/store/modules/Auth";
import JsonExcel from "@/components/JsonExcel.vue";
import {
  Permits,
  IndexedDB,
  CRUDFunctionality,
  AROs,
  Downloader,
  ShiftSite,
  Reports,
} from "@/hooks";

import {
  AROViewModel,
  OfflinePermitType,
  OldPermit,
  ShiftSite as ShiftSiteModel,
} from "models";
import dayjs, { Dayjs } from "dayjs";
import logger from "@/plugins/logger";
import { PermitFilterType } from "@/enums/filterType";

@Component({
  components: {
    PermitExpansionPanel,
    ReturnPermit,
    JsonExcel,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class Permit_Report extends Vue {
  permitFilter: string | null = null;
  sortDesc = true;
  loading = false;
  newPermitModal = false;
  selectedItem = 0;
  reports: OldPermit[] = [];
  sortBy = "expiryDateStr";
  expanded: OldPermit[] = [];
  reportsFiltered: OldPermit[] = [];
  search = "";
  headers = [
    { text: "Permit Number", value: "savedPermitNumber" },
    { text: "ARO", value: "armResponseOfficer" },

    { text: "Firearm", value: "firearm" },
    { text: "From Date", value: "fromDateStr" },
    { text: "To Date", value: "toDateStr" },
    { text: "Firearm S/N", value: "firearm.serialNumber" },
    { text: "Ammunition Calibre", value: "ammunition.calibre" },
    { text: "Ammunition Count", value: "ammunitionCount" },
    { text: "Ammunition Returned", value: "ammunitionReturn.returnQuantity" },
    { text: "", value: "data-table-expand" },
    // { text: "From Time", value: "fromTime" },
    // { text: "To Time", value: "toTime" },
    // { text: "Area", value: "shiftSite.name" },
    // { text: "Nature Of Duty", value: "natureOfDuty" },
    // { text: "Remarks", value: "remarks" },
    // { text: "Number Of Magazines", value: "numberOfMagazines" },
    // { text: "Show ARO Signature", value: "showAROSignature" },
    // {
    //   text: "Show Responsible Person Signature",
    //   value: "showResponsiblePersonSignature",
    // },
    // { text: "Ammunition Return", value: "ammunitionReturn" },
    // { text: "Returned", value: "isReturned" },
  ];
  status = [
    {
      description: "Issued/ Pending Action",
      value: PermitFilterType.IssuedPendingAction,
      color: "default",
    },
    {
      description: "Approved",
      value: PermitFilterType.Approved,
      color: "success",
    },
    {
      description: "Returned",
      value: PermitFilterType.Returned,
      color: "warning",
    },
    {
      description: "Confirm Returned",
      value: PermitFilterType.Confirmed,
      color: "blue",
    },
    {
      description: "Declined/Cancelled",
      value: PermitFilterType.DeclinedCancelled,
      color: "error",
    },
  ];
  filterOnStatus: PermitFilterType = PermitFilterType.IssuedPendingAction;
  /**
   * ARO's
   */

  aros: AROViewModel[] = [];
  arosSelected: AROViewModel[] = [];
  async loadAros(): Promise<void> {
    const res = await AROs.getAros();
    this.aros = res.arOs;
  }
  /**
   * Shift Sites
   */

  shiftSites: ShiftSiteModel[] = [];
  shiftSitesSelected: ShiftSiteModel[] = [];
  async loadShiftSites(): Promise<void> {
    this.shiftSites = await ShiftSite.getShiftSites();
  }
  outstandingPermits = false;
  permits: OldPermit[] = [];
  permitDatePicker = false;
  dateFilters = {
    to: {
      active: false,
      date: dayjs().format("YYYY-MM-DD"),
    },
    from: {
      active: false,
      date: dayjs().subtract(4, "days").format("YYYY-MM-DD"),
    },
  };
  returnPermit: {
    open: boolean;
    permit: null | OldPermit;
  } = {
    open: false,
    permit: null,
  };
  currentPdfBuffer: ArrayBuffer | null = null;
  currentPdf: Blob | null = null;
  sortByDate(a: OldPermit, b: OldPermit): number {
    const aDate = new Date(a.toDateStr).getTime();
    const bDate = new Date(b.toDateStr).getTime();
    if (aDate - bDate > 0) {
      if (this.sortDesc) return -1;
      else return 1;
    } else if (aDate - bDate < 0) {
      if (this.sortDesc) return 1;
      else return -1;
    } else {
      if (a.toTime.split(":")[0] > b.toTime.split(":")[0]) {
        if (this.sortDesc) return -1;
        else return 1;
      } else if (a.toTime.split(":")[0] < b.toTime.split(":")[0]) {
        if (this.sortDesc) return -1;
        else return 1;
      } else {
        return 0;
      }
    }
  }

  expReports: {
    "Permit Number": string | number;
    ARO: string;
    Firearm: string;
    "From Date": string;
    "To Date": string;
    "Firearm S/N": string;
    "Ammunition Calibre": string;
    "Ammunition Count": string | number;
    "Ammunition Returned": number;
    "From Time": string;
    "To Time": string;
    Area: "Area";
    "Nature Of Duty": string;
    "Number Of Magazines": string | number;
    Remarks: string;
    Returned: string;
    "Returned By": string;
    "Returned Date": string;
    "Ammunition Return": string;
    "Show ARO Signature": string;
    "Show Responsible Person Signature": string;
    "Approved By": string;
    "Approved Date": string;
    "Declined By": string;
    "Declined Date": string;
  }[] = [];

  expFields = {
    "Permit Number": "Permit Number",
    ARO: "ARO",
    Firearm: "Firearm",
    "From Date": "From Date",
    "To Date": "To Date",
    "Firearm S/N": "Firearm S/N",
    "Ammunition Calibre": "Ammunition Calibre",
    "Ammunition Count": "Ammunition Count",
    "Ammunition Returned": "Ammunition Returned",
    "From Time": "From Time",
    "To Time": "To Time",
    Area: "Area",
    "Nature Of Duty": "Nature Of Duty",
    "Number Of Magazines": "Number Of Magazines",
    Remarks: "Remarks",
    Returned: "Returned",
    "Returned By": "Returned By",
    "Returned Date": "Returned Date",
    "Ammunition Return": "Ammunition Return",
    "Show ARO Signature": "Show ARO Signature",
    "Show Responsible Person Signature": "Show Responsible Person Signature",
    "Approved By": "Approved By",
    "Approved Date": "Approved Date",
    "Declined By": "Declined By",
    "Declined Date": "Declined Date",
  };

  // elem.firearm.make != null ||
  //           elem.firearm.model != null ||
  //           elem.firearm.type != null ||
  //           elem.firearm.expiryDateStr != null
  //             ? elem.make +
  //               ", " +
  //               elem.model +
  //               ", " +
  //               elem.type +
  //               ", " +
  //               elem.expiryDateStr
  //             : " ",

  // elem.armResponseOfficer.name != null || elem.armResponseOfficer.surname != null
  //           ?

  populateExportData(items: any[]) {
    return items.map((elem) => {
      return {
        "Permit Number":
          elem.savedPermitNumber != null ? elem.savedPermitNumber : "",
        ARO:
          (elem.armResponseOfficer.name != null
            ? elem.armResponseOfficer.name
            : "") +
          ", " +
          (elem.armResponseOfficer.surname != null
            ? elem.armResponseOfficer.surname
            : ""),

        Firearm:
          (elem.firearm.make != null ? elem.firearm.make : "") +
          ", " +
          (elem.firearm.model != null ? elem.firearm.model : "") +
          ", " +
          (elem.firearm.type != null ? elem.firearm.type : "") +
          ", " +
          (elem.firearm.expiryDateStr != null
            ? elem.firearm.expiryDateStr
            : ""),
        "From Date": elem.fromDateStr != null ? elem.fromDateStr : "",
        "To Date": elem.toDateStr != null ? elem.toDateStr : "",
        "Firearm S/N":
          elem.firearm.serialNumber != null ? elem.firearm.serialNumber : "",
        "Ammunition Calibre":
          elem.ammunition.calibre != null ? elem.ammunition.calibre : "",
        "Ammunition Count":
          elem.ammunitionCount != null ? elem.ammunitionCount : "",
        "Ammunition Returned":
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.returnQuantity
            : 0,
        "From Time": elem.fromTime != null ? elem.fromTime : "",
        "To Time": elem.toTime != null ? elem.toTime : "",
        Area: elem.shiftSite.name != null ? elem.shiftSite.name : "",
        "Nature Of Duty": elem.natureOfDuty != null ? elem.natureOfDuty : "",
        "Number Of Magazines":
          elem.numberOfMagazines != null ? elem.numberOfMagazines : "",
        Remarks:
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.remarks != null
              ? elem.ammunitionReturn.remarks
              : ""
            : "N/A",
        Returned:
          elem.isReturned != null
            ? elem.isReturned == true
              ? "Yes"
              : "No"
            : "",
        "Returned By":
          elem.ammunitionReturn != null
            ? elem.ammunitionReturn.createdBy != null
              ? elem.ammunitionReturn.createdBy
              : ""
            : "",

        "Returned Date":
          elem.ammunitionReturn != null
            ? dayjs(elem.ammunitionReturn.createdDate).format("YYYY-MM-DD")
            : "",
        "Ammunition Return":
          elem.ammunitionReturn != null
            ? "(total - used = returned) " +
              (elem.ammunitionReturn.totalAllocated != null
                ? elem.ammunitionReturn.totalAllocated
                : "") +
              " - " +
              (elem.ammunitionReturn.usedQuantity != null
                ? elem.ammunitionReturn.usedQuantity
                : "") +
              " = " +
              (elem.ammunitionReturn.returnQuantity != null
                ? elem.ammunitionReturn.returnQuantity
                : "")
            : " REQUIRED",

        "Show ARO Signature":
          elem.showAROSignature != null
            ? elem.showAROSignature == true
              ? "Yes"
              : "No"
            : "",
        "Show Responsible Person Signature":
          elem.showResponsiblePersonSignature != null
            ? elem.showResponsiblePersonSignature == true
              ? "Yes"
              : "No"
            : "",
        "Approved By":
          elem.acceptedBy != null
            ? elem.acceptedBy.userName != null
              ? elem.acceptedBy.userName
              : ""
            : "",
        "Approved Date":
          elem.acceptedDate != null
            ? dayjs(elem.acceptedDate).format("YYYY-MM-DD")
            : "",
        "Declined By": elem.declineBy != null ? elem.declineBy : "",
        "Declined Date":
          elem.declinedDate != null
            ? dayjs(elem.declinedDate).format("YYYY-MM-DD")
            : "",
      };
    });
  }

  updateReportsFiltered(event: any) {
    this.reportsFiltered = event ? (event as any[]) : this.permits;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.reportsFiltered);
  }

  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      logger.log("PDF Triggered");
      const columns = [
        { header: "Permit #", dataKey: "Permit Number" },
        { header: "ARO", dataKey: "ARO" },
        { header: "Firearm", dataKey: "Firearm" },
        { header: "From Date", dataKey: "From Date" },
        { header: "To Date", dataKey: "To Date" },
        { header: "Firearm S/N", dataKey: "Firearm S/N" },
        { header: "Ammunition Calibre", dataKey: "Ammunition Calibre" },
        { header: "Ammunition Count", dataKey: "Ammunition Count" },
        // { header: "Ammunition Returned", dataKey: "Ammunition Returned" },
        // { header: "From Time", dataKey: "From Time" },
        // { header: "To Time", dataKey: "To Time" },
        // { header: "Area", dataKey: "Area" },
        // { header: "Nature Of Duty", dataKey: "Nature Of Duty" },
        // { header: "Number Of Magazines", dataKey: "Number Of Magazines" },
        { header: "Remarks", dataKey: "Remarks" },
        { header: "Returned", dataKey: "Returned" },
        { header: "Returned By", dataKey: "Returned By" },
        { header: "Returned Date", dataKey: "Returned Date" },
        { header: "Ammunition Return", dataKey: "Ammunition Return" },
        { header: "Show ARO Signature", dataKey: "Show ARO Signature" },
        {
          header: "Show Responsible Person Signature",
          dataKey: "Show Responsible Person Signature",
        },
        { header: "Approved By", dataKey: "Approved By" },
        { header: "Approved Date", dataKey: "Approved Date" },
        { header: "Declined By", dataKey: "Declined By" },
        { header: "Declined Date", dataKey: "Declined Date" },
      ];

      logger.log("PDF Columns generated");
      await Downloader.generatePdfAndSave({
        fileName: `Permit (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreak: true,
        horizontalPageBreakRepeat: "Permit Number",
        horizontalPageBreakBehaviour: "immediately",
      });

      logger.log("PDF Exported");
    } catch (err) {
      //comment

      logger.log("PDF Err: ", err);
    }
  }

  sortByStatus(a: OldPermit, b: OldPermit): number {
    const aRet = a.ammunitionReturn == null && a.declineBy == null;
    const bRet = b.ammunitionReturn == null && b.declineBy == null;

    // item.ammunitionReturn == null &&
    // item.declineBy == null,
    if (aRet < bRet) {
      // if (this.sortDesc) return -1;
      // else return 1;
      return 1;
    } else if (aRet > bRet) {
      // if (this.sortDesc) return 1;
      // else return -1;
      return -1;
    } else {
      return 0;
    }
  }
  // get getFilteredPermits1(): OldPermit[] {
  //   if (this.permitFilter === null) {
  //     return this.permits.sort((a: OldPermit, b: OldPermit) =>
  //       this.sortByDate(b, a)
  //     );
  //   }
  //   const temp = this.permitFilter;
  //   return this.permits.filter(
  //     (e) =>
  //       e.armResponseOfficer.surname
  //         .toLowerCase()
  //         .includes(temp.toLowerCase()) ||
  //       e.armResponseOfficer.name.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.make.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.model.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.natureOfDuty.toLowerCase().includes(temp.toLowerCase()) ||
  //       e.firearm.calibre.toLowerCase().includes(temp.toLowerCase())
  //   );
  // }
  get getFilteredPermits(): OldPermit[] {
    let items = this.permits;

    items = items.sort(this.sortByDate).sort(this.sortByStatus);

    return items;

    // const temp = this.permitFilter;
    // return this.permits.filter(
    //   (e) =>
    //     e.armResponseOfficer.surname
    //       .toLowerCase()
    //       .includes(temp.toLowerCase()) ||
    //     e.armResponseOfficer.name.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.make.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.model.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.natureOfDuty.toLowerCase().includes(temp.toLowerCase()) ||
    //     e.firearm.calibre.toLowerCase().includes(temp.toLowerCase())
    // );
  }
  // issuePermit(permissions: string[]): boolean {
  //   logger.log(
  //     "Firearm Permission",
  //     permissions,
  //     permissions
  //       .map((permission) => CRUDFunctionality.hasAccess(permission))
  //       .every(Boolean)
  //   );
  //   return permissions
  //     .map((permission) => CRUDFunctionality.hasAccess(permission))
  //     .every(Boolean);
  // }
  // get getIssuePermit(): boolean {
  //   logger.log("Firearm Proxy", AuthModule.getIssuePermit);
  //   logger.log("Firearm Permission", CRUDFunctionality.hasAccess("addPermit"));
  //   return AuthModule.getIssuePermit;
  // }
  async downloadPermit(item: OldPermit): Promise<void> {
    try {
      this.loading = true;
      const res = await Permits.getPermitPDF(item);
      // logger.log("Get Permit: ", res);
      // const fileUrl = window.URL.createObjectURL(res);
      const buffer = await res.arrayBuffer();
      const saved: OfflinePermitType = {
        date: new Date(item.createdDate).toLocaleDateString(),
        id: item.id,
        buffer: buffer,
        title: `${item.armResponseOfficer.surname}, ${item.armResponseOfficer.name}`,
        subtitle: `${item.firearm.make}, ${item.firearm.model}, ${item.firearm.typeStr}`,
      };
      if (AuthModule.getUsername != null) {
        await IndexedDB.savePermit(saved, AuthModule.getUsername);
        this.$router.push({
          name: "dash.offlinePermits",
        });
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  openReturnPermitModal(item: OldPermit): void {
    this.returnPermit.permit = item;
    this.returnPermit.open = true;
  }

  async loadAllPermits(): Promise<void> {
    logger.log("Shift Sites", this.shiftSitesSelected);
    logger.log("AROs", this.arosSelected);
    const res = await Permits.getAllPermits(
      this.filterOnStatus,
      {
        startDate: this.outstandingPermits ? null : this.dateFilters.from.date,
        endDate: this.outstandingPermits ? null : this.dateFilters.to.date,
      },
      this.shiftSitesSelected.map((e) => e.id!),
      this.arosSelected.map((e) => e && e.id!.toString()),
      []
    ).catch((err) => {
      logger.log("Error all permits: ", err);
      return Promise.reject(err);
    });
    this.permits = res;

    this.expReports = this.populateExportData(this.permits);
  }
  async initialize(): Promise<void> {
    try {
      this.loading = true;
      await this.loadAllPermits();
      await this.loadAros();
      await this.loadShiftSites();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async mounted(): Promise<void> {
    try {
      await this.initialize();
      if (AuthModule.getUsername != null) {
        const storedPermits = await IndexedDB.getStoredPermits(
          AuthModule.getUsername
        );
      }

      return Promise.resolve();
      // logger.log("Result of Stored PErmits: ", storedPermits);
      // logger.log("All Done setup");
    } catch (err) {
      return Promise.reject(err);
    }
  }
}


import { Component, Vue, Watch } from "vue-property-decorator";
import NotificationSystem from "@/store/modules/NotificationSystem";
import { notificationType } from "models";
import logger from "@/plugins/logger";
// import { NOTIFICATION } from "@/enums/notifications";

@Component
export default class GlobalNotifications extends Vue {
  get displayNotifications(): boolean {
    return this.open;
  }
  currentNotif: null | notificationType = null;
  open = false;
  get getCurrentNotification(): notificationType | null {
    return NotificationSystem.getCurrentNotif;
  }
  closeNotif(notification: notificationType | null): void {
    this.open = false;
    if (notification != null) {
      setTimeout(
        NotificationSystem.clearCurrentNotifWithCheck,
        300,
        notification
      );
    }
    if (this.currentNotif?.cb) {
      this.currentNotif.cb();
    }
  }
  @Watch("getCurrentNotification", {
    immediate: true,
  })
  currentNotifChanged(
    newNotif: notificationType | null,
    oldNotif: notificationType | null | undefined
  ): void {
    logger.log("Current Notif Changed", newNotif, oldNotif);
    if (newNotif == null) {
      this.open = false;
      this.currentNotif = newNotif;
      setTimeout(() => {
        this.open = true;
      }, 200);
    } else {
      this.open = false;
      this.currentNotif = newNotif;
      setTimeout(() => {
        this.open = true;
      }, 200);
    }
  }

  mounted(): void {
    // NotificationSystem.addNotification({
    //   message: "Success!!!!",
    //   color: "success",
    //   icon: "mdi-check",
    // });
  }
}

export enum CRUD {
  CREATE = 1,
  REVIEW = 2,
  UPDATE = 3,
  DELETE = 4,
}

export enum UPSERT {
  "CREATE" = "CREATE",
  "UPDATE" = "UPDATE",
}

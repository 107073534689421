import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import HandgunIcon from "@/components/icons/HandgunIcon.vue";
import ShotgunIcon from "@/components/icons/ShotgunIcon.vue";
import SelfLoadingRifleIcon from "@/components/icons/SelfLoadingRifleIcon.vue";
import ManualRifleIcon from "@/components/icons/ManualRifleIcon.vue";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1660A8",
        secondary: "#424242",
        accent: "#82B1FF",
        error: colors.red.base,
        info: "#2196F3",
        success: "#4CAF50",
        warning: colors.orange.accent4,
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      handgun: {
        component: HandgunIcon,
      },
      shotgun: {
        component: ShotgunIcon,
      },
      selfLoadingRifle: {
        component: SelfLoadingRifleIcon,
      },
      manualRifle: {
        component: ManualRifleIcon,
      },
    },
  },
});

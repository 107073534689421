
import { Component, Vue } from "vue-property-decorator";
import { axiosNoAuth } from "@/plugins/axios";

@Component
export default class ResetPassword extends Vue {
  errorMsg = "Error Resetting Password: Please try again...";
  modalForgotPasswordSucces = false;
  alert = false;
  valid = false;
  loading = false;
  username = null;
  pass = null;
  visible = {
    pass: false,
    confirm: false,
  };
  matchingPass = null;
  matchingPassRules = [
    (v: string): boolean | string =>
      (v && v.length >= 1) || "Matching password required",
  ];

  get passwordConfirmationRule(): (v: string) => string | boolean {
    return () =>
      (this.pass === this.matchingPass && this.pass != null) ||
      `Password must match ${this.pass}`;
  }
  rules = {
    required: (v: string): boolean | string =>
      (v && v.length >= 3) || "Requires length of 3",
    lowercase: (v: string): boolean | string =>
      /(?=.*[a-z])/.test(v) || "Requires atleast 1 lowercase letter",
    uppercase: (v: string): boolean | string =>
      /(?=.*[A-Z])/.test(v) || "Requires atleast 1 uppercase letter",
    number: (v: string): boolean | string =>
      /(?=.*\d)/.test(v) || "Requires atleast 1 number",
    specialChar: (v: string): boolean | string =>
      /([!@$%])/.test(v) || "Requires atleast 1 special character",
  };

  get confirmPassRules(): ((v: string) => string | boolean)[] {
    const temp = [
      this.rules.required,
      this.rules.lowercase,
      this.rules.uppercase,
      this.rules.number,
      this.rules.specialChar,
      this.passwordConfirmationRule,
    ];
    return temp;
  }
  passRules = [
    this.rules.required,
    this.rules.lowercase,
    this.rules.uppercase,
    this.rules.number,
    this.rules.specialChar,
  ];
  get hasError() {
    return this.$route.query.code === undefined;
  }
  async sendResetPass(): Promise<void> {
    try {
      this.loading = true;
      if (this.hasError || !this.valid) {
        return;
      }
      const body = {
        username: this.username,
        password: this.pass,
        code: this.$route.query.code,
      };
      await axiosNoAuth.post(`/api/authenticate/ConfirmResetPassword`, body);
      this.modalForgotPasswordSucces = true;
    } catch (err) {
      this.alert = true;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  goToSignIn(): void {
    this.$router.push("Login");
  }
}


import { Component, Vue, Watch } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Teams, CRUDFunctionality, Downloader } from "@/hooks";
import { TeamViewModel } from "models";
import manageItemModal from "@/views/manage/teams/TeamsAddEdit.vue";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
    manageItemModal,
  },
})
export default class ManageTeams extends Vue {
  items: TeamViewModel[] = [];
  itemsFiltered: TeamViewModel[] = [];
  loading = false;
  search = "";
  sortDesc = true;
  sortBy = "currentlyOnDuty";
  expanded = [];
  deleteModal = false;
  archiveModal = false;
  valid = false;
  manageItem = false;
  manageItemName = "";
  manageItemId = 0;
  shifts = false;
  refresh = false;
  modalData = {
    type: " ",
    field: {},
  };
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
    id: (value: string): boolean | string =>
      value.length == 13 || "Requires 13 digits",
    cell: (value: string): boolean | string =>
      value.length == 10 || "Requires 13 digits",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  };
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Team Name", value: "name" },
    { text: "ARO/SO Team", value: "isSecurityGuardTeam" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "Team Name": string;
    "ARO/SO Team": string;
    "Team Members": string;
    "Standby Members": string;
  }[] = [];
  expFields = {
    "Team Name": "Team Name",
    "ARO/SO Team": "ARO/SO Team",
    "Team Members": "Team Members",
    "Standby Members": "Standby Members",
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    //logger.log("Name: ", name, has);
    return has;
  }

  @Watch("refresh", { immediate: true })
  modalDataChanged(): void {
    if (this.refresh == true) {
      this.loadItems();
    }
  }

  openEditItem(i: TeamViewModel): void {
    this.modalData.type = "edit";
    this.modalData.field = i;
    this.refresh = false;
    this.manageItem = true;
  }

  openAddItem(): void {
    this.modalData.type = "add";
    this.modalData.field = {};
    this.refresh = false;
    this.manageItem = true;
  }

  openDeleteItem(i: TeamViewModel): void {
    this.manageItemName = i.name;
    this.manageItemId = i.id;
    this.shifts = i.shift != null ? (i.shift > 0 ? true : false) : false;
    this.deleteModal = true;
  }

  async deleteItem(): Promise<void> {
    try {
      const res = await Teams.deleteTeam(this.manageItemId);
      this.$notifCreator.createSuccessNotification("Team Deleted Succesfully.");

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;

      this.loadItems();
    }
  }
  updateReportsFiltered(event: any) {
    this.itemsFiltered = event ? (event as any[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.itemsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      const columns = [
        { header: "Site/Area Name", dataKey: "Site/Area Name" },
        { header: "Start Date", dataKey: "Start Date" },
        { header: "End Date", dataKey: "End Date" },
        { header: "Start Time", dataKey: "Start Time" },
        { header: "End Time", dataKey: "End Time" },
        { header: "Team Name", dataKey: "Team Name" },
        { header: "Team Type", dataKey: "Team Type" },
        { header: "Team Members", dataKey: "Team Members" },
        { header: "StandBy Shift Members", dataKey: "StandBy Shift Members" },
      ];

      await Downloader.generatePdfAndSave({
        fileName: `Teams (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "Team Name",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loadItems(): Promise<void> {
    try {
      this.loading = true;
      const res = await Teams.getTeams();
      this.items = res;
      this.expReports = this.populateExportData(this.items);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  populateExportData(items: TeamViewModel[]) {
    return items.map((elem: TeamViewModel) => {
      return {
        "Team Name": elem.name != null ? elem.name : "N/A",
        "ARO/SO Team":
          elem.isSecurityGuardTeam != null
            ? elem.isSecurityGuardTeam == true
              ? "SO Team"
              : "ARO Team"
            : "N/A",
        "Team Members": this.getMemberValues(
          elem.teamAROViewModels,
          elem.armResponseOfficers,
          false
        ),
        "Standby Members": this.getMemberValues(
          elem.teamAROViewModels,
          elem.armResponseOfficers,
          true
        ),
      };
    });
  }

  getMemberValues(arr1: any[], arr2: any[], bool: boolean): any {
    let value: any[] = [];
    arr1.forEach((team) => {
      const res = arr2.find(
        (aro) =>
          aro.aroid == team.armResponseOfficerID && team.isStandBy == bool
      );
      if (res) {
        value.push(res.name + " " + res.surname);
      }
    });
    return value;
  }
}

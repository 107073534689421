var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"rangeOfficerForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"ma-0 pb-5 pa-0 px-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"10"}},[_vm._v(" Supervise the Operation of a Shooting Range (U/S 123516) ")]),_c('v-col',{attrs:{"cols":"2","md":"2"}},[(_vm.currentDoc.onlyRange.file)?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile(1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.currentDoc.onlyRange.expanded =
                        !_vm.currentDoc.onlyRange.expanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                        rotate: _vm.currentDoc.onlyRange.expanded,
                      }},[_vm._v("mdi-chevron-down")])],1)],1)],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentDoc.onlyRange.expanded),expression:"currentDoc.onlyRange.expanded"}]},[(_vm.currentDoc.onlyRange.file != null)?_c('v-hover',{attrs:{"close-delay":"35"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"flat":""}},[(_vm.currentDoc.onlyRange.file.type != 'application/pdf')?_c('v-img',{attrs:{"contain":"","id":"psiraPreview","height":200,"min-width":100,"src":_vm.currentDoc.onlyRange.fileSrc},on:{"error":_vm.errorHandler}},[_c('v-expand-transition',[(hover == true)?_c('div',{staticClass:"d-flex justify-center transition-fast-in-fast-out imageOverlay text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(_vm.currentDoc.onlyRange.file)}}},[_vm._v("View Image")])],1):_vm._e()])],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"100px"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(_vm.currentDoc.onlyRange.file)}}},[_vm._v("View PDF")])],1)],1)]}}],null,false,4035125991)}):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf,.png,.jpg","dense":"","show-size":"","rules":[(v) => !!v || 'Required'],"prepend-icon":"","prepend-inner-icon":"$file","label":"Knowledge of the Act","truncate-length":"16","outlined":"","hide-details":"","disabled":_vm.shouldDisplayForRole('editDocument')},on:{"change":function($event){$event == null
                        ? ''
                        : (_vm.currentDoc.onlyRange.fileSrc =
                            _vm.URL.createObjectURL($event))}},model:{value:(_vm.currentDoc.onlyRange.file),callback:function ($$v) {_vm.$set(_vm.currentDoc.onlyRange, "file", $$v)},expression:"currentDoc.onlyRange.file"}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('div',[_vm._v("Statement of Results")]),_c('v-spacer'),(_vm.currentDoc.onlyRange.sorFile)?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile(3)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.currentDoc.onlyRange.sorExpanded =
                  !_vm.currentDoc.onlyRange.sorExpanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                  rotate: _vm.currentDoc.onlyRange.sorExpanded,
                }},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentDoc.onlyRange.sorExpanded),expression:"currentDoc.onlyRange.sorExpanded"}]},[(_vm.currentDoc.onlyRange.sorFile != null)?_c('v-hover',{attrs:{"close-delay":"35"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"flat":""}},[(
                    _vm.currentDoc.onlyRange.sorFile.type != 'application/pdf'
                  )?_c('v-img',{attrs:{"contain":"","id":"psiraPreview","height":200,"min-width":100,"src":_vm.currentDoc.onlyRange.sorSrc},on:{"error":_vm.errorHandler}},[_c('v-expand-transition',[(hover == true)?_c('div',{staticClass:"d-flex justify-center transition-fast-in-fast-out imageOverlay text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(_vm.currentDoc.onlyRange.sorFile)}}},[_vm._v("View Image")])],1):_vm._e()])],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"100px"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(_vm.currentDoc.onlyRange.sorFile)}}},[_vm._v("View PDF")])],1)],1)]}}],null,false,3000375489)}):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf,.png,.jpg","dense":"","show-size":"","rules":[(v) => !!v || 'Required'],"prepend-icon":"","prepend-inner-icon":"$file","label":"Statement of Results","truncate-length":"16","outlined":"","hide-details":"","disabled":_vm.shouldDisplayForRole('editDocument')},on:{"change":function($event){$event == null
                        ? ''
                        : (_vm.currentDoc.onlyRange.sorSrc =
                            _vm.URL.createObjectURL($event))}},model:{value:(_vm.currentDoc.onlyRange.sorFile),callback:function ($$v) {_vm.$set(_vm.currentDoc.onlyRange, "sorFile", $$v)},expression:"currentDoc.onlyRange.sorFile"}})],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"10"}},[_vm._v(" Supervise the Operations of a Shooting Range and Tactical Exercises (U/S 123517) ")]),_c('v-col',{attrs:{"cols":"2","md":"2"}},[(_vm.currentDoc.includingExcercises.file)?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile(2)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.currentDoc.includingExcercises.expanded =
                        !_vm.currentDoc.includingExcercises.expanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                        rotate: _vm.currentDoc.includingExcercises.expanded,
                      }},[_vm._v("mdi-chevron-down")])],1)],1)],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentDoc.includingExcercises.expanded),expression:"currentDoc.includingExcercises.expanded"}]},[(_vm.currentDoc.includingExcercises.file != null)?_c('v-hover',{attrs:{"close-delay":"35"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"flat":""}},[(
                    _vm.currentDoc.includingExcercises.file.type !=
                    'application/pdf'
                  )?_c('v-img',{attrs:{"contain":"","id":"psiraPreview","height":200,"min-width":100,"src":_vm.currentDoc.includingExcercises.fileSrc},on:{"error":_vm.errorHandler}},[_c('v-expand-transition',[(hover == true)?_c('div',{staticClass:"d-flex justify-center transition-fast-in-fast-out imageOverlay text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(_vm.currentDoc.includingExcercises.file)}}},[_vm._v("View Image")])],1):_vm._e()])],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"100px"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(_vm.currentDoc.includingExcercises.file)}}},[_vm._v("View PDF")])],1)],1)]}}],null,false,3627611309)}):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf,.png,.jpg","dense":"","show-size":"","rules":[(v) => !!v || 'Required'],"prepend-icon":"","prepend-inner-icon":"$file","label":"Knowledge of the Act","truncate-length":"16","outlined":"","hide-details":"","disabled":_vm.shouldDisplayForRole('editDocument')},on:{"change":function($event){$event == null
                        ? ''
                        : (_vm.currentDoc.includingExcercises.fileSrc =
                            _vm.URL.createObjectURL($event))}},model:{value:(_vm.currentDoc.includingExcercises.file),callback:function ($$v) {_vm.$set(_vm.currentDoc.includingExcercises, "file", $$v)},expression:"currentDoc.includingExcercises.file"}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"primary white--text mb-2 text-subtitle-1 py-2 d-flex justify-space-between"},[_c('div',[_vm._v("Statement of Results")]),_c('v-spacer'),(_vm.currentDoc.includingExcercises.sorFile)?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.deleteFile(4)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.currentDoc.includingExcercises.sorExpanded =
                  !_vm.currentDoc.includingExcercises.sorExpanded}}},[_c('v-icon',{staticClass:"toggleUpDown",class:{
                  rotate: _vm.currentDoc.includingExcercises.sorExpanded,
                }},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentDoc.includingExcercises.sorExpanded),expression:"currentDoc.includingExcercises.sorExpanded"}]},[(_vm.currentDoc.includingExcercises.sorFile != null)?_c('v-hover',{attrs:{"close-delay":"35"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"flat":""}},[(
                    _vm.currentDoc.includingExcercises.sorFile.type !=
                    'application/pdf'
                  )?_c('v-img',{attrs:{"contain":"","id":"psiraPreview","height":200,"min-width":100,"src":_vm.currentDoc.includingExcercises.sorSrc},on:{"error":_vm.errorHandler}},[_c('v-expand-transition',[(hover == true)?_c('div',{staticClass:"d-flex justify-center transition-fast-in-fast-out imageOverlay text-h2 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(_vm.currentDoc.includingExcercises.sorFile)}}},[_vm._v("View Image")])],1):_vm._e()])],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"100px"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewMedia(_vm.currentDoc.includingExcercises.sorFile)}}},[_vm._v("View PDF")])],1)],1)]}}],null,false,3994961515)}):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"height":"200px","border":"1px solid gray"}},[_c('div',{staticClass:"text-h5 align-self-center"},[_vm._v("Please Upload")])])],1),_c('v-card-actions',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf,.png,.jpg","dense":"","show-size":"","rules":[(v) => !!v || 'Required'],"prepend-icon":"","prepend-inner-icon":"$file","label":"Statement of Results","truncate-length":"16","outlined":"","hide-details":"","disabled":_vm.shouldDisplayForRole('editDocument')},on:{"change":function($event){$event == null
                        ? ''
                        : (_vm.currentDoc.includingExcercises.sorSrc =
                            _vm.URL.createObjectURL($event))}},model:{value:(_vm.currentDoc.includingExcercises.sorFile),callback:function ($$v) {_vm.$set(_vm.currentDoc.includingExcercises, "sorFile", $$v)},expression:"currentDoc.includingExcercises.sorFile"}})],1)],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"disabled":_vm.valid == false || _vm.shouldDisplayForRole('editDocument') == true,"loading":_vm.loading,"color":"primary","block":""},on:{"click":_vm.addRangeOfficer}},[_vm._v("Submit")])],1)],1)],1),_c('multimedia-viewer',{attrs:{"open":_vm.multiMediaViewer.open,"data":{
      type: 'file',
      file: _vm.multiMediaViewer.file,
      offlinePermit: null,
    }},on:{"update:open":function($event){return _vm.$set(_vm.multiMediaViewer, "open", $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
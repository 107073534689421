
import { Component, Vue, Watch, Prop, Mixins } from "vue-property-decorator";
import { Firearms, AROs, Documents, CRUDFunctionality } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import dayjs from "dayjs";
import {
  ArmResponseOfficer,
  AroDocs,
  Firearm,
  PsiraRegistrationDocuments,
  PsiraRegistrationDocuments_Create,
} from "models";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";
import FileMixin from "@/mixins/FileMixin.vue";
import logger from "@/plugins/logger";
import MultimediaViewer from "@/components/MultimediaViewer.vue";

interface Certificate {
  armResponseOfficerID: number;
  createdBy: string;
  createdDate: string;
  expiryDate: string;
  fileName: string;
  filePath: string;
  firearmType: string;
  id: number;
  isRegulation21Certificate: boolean;
  isRenewed: boolean;
  renewedDate: string | null;
  renewedDocumentFilePath: string | null;
  updatedBy: string;
  updatedDate: string;
}

@Component({
  components: {
    VuePdfApp,
    MultimediaViewer,
  },
})
export default class Regulation21 extends Mixins(FileMixin) {
  @Prop() certificate!: Certificate | null;
  @Prop() aroId!: number;

  isOpenExpiryDate = false;
  isOpenRenewalProofDate = false;
  certExpanded = false;
  multiMediaViewer: { open: boolean; file: File | null } = {
    open: false,
    file: null,
  };

  get getAroDocs(): AroDocs | null {
    return Docs.getAroDocs;
  }

  viewMedia(file: File) {
    this.multiMediaViewer.file = file;
    this.multiMediaViewer.open = true;
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    return !has;
  }

  async deleteFile(file: number, fileId: number): Promise<void> {
    try {
      this.loading = true;
      const res = await Documents.deleteSAPSCompetency(fileId, file);

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.$emit("refresh");
      this.loading = false;
    }
  }

  get getValid() {
    if (
      this.currentDoc &&
      ((this.currentDoc.SAPSHandgun == true && this.getBusHG == true) ||
        (this.currentDoc.SAPSShotgun == true && this.getBusSG == true) ||
        (this.currentDoc.SAPSSelfloadingrifle == true &&
          this.getBusSLR == true) ||
        (this.currentDoc.SAPSManuallyoperatedrifle == true &&
          this.getBusMOR == true)) &&
      this.currentDoc.SAPSCompetencyFile != null
    ) {
      return false;
    }
    return (
      this.currentDoc &&
      ((this.currentDoc.SAPSHandgun == true && this.getBusHG == false) ||
        (this.currentDoc.SAPSShotgun == true && this.getBusSG == false) ||
        (this.currentDoc.SAPSSelfloadingrifle == true &&
          this.getBusSLR == false) ||
        (this.currentDoc.SAPSManuallyoperatedrifle == true &&
          this.getBusMOR == false)) &&
      this.currentDoc.SAPSCompetencyCertificateID != null &&
      this.currentDoc.SAPSCompetencyExpiryDate != null &&
      this.currentDoc.SAPSCompetencyFile != null
    );
  }
  // get getRegHGExpiry() {
  //   if (this.getAroDocs) {
  //     const temp = this.getAroDocs.sapsCompetencyCertificates
  //       .filter((e) => {
  //         e.isRegulation21Certificate == true &&
  //           e.firearmType.includes("Handgun");
  //       })
  //       .sort((a, b) => {
  //         return dayjs(a.expiryDate).diff(b.expiryDate) == 0
  //           ? 0
  //           : dayjs(a.expiryDate).diff(b.expiryDate) > 0
  //           ? 1
  //           : -1;
  //       });
  //     if (temp.length > 0) {
  //       return dayjs().diff(dayjs(temp[0].expiryDate), "days") > 365;
  //     }
  //   }
  //   return false;
  // }
  get getBusHG(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter(
          (e) => e.firearmType.includes("Handgun")
          // &&
          // dayjs(dayjs()).diff(dayjs(e.createdDate), "days", true) > 365
        ).length == 0
      );
    } else return true;
  }

  get getBusSG(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter(
          (e) => e.firearmType.includes("Shotgun")
          // &&
          // dayjs(dayjs()).diff(dayjs(e.createdDate), "days", true) > 365
        ).length == 0
      );
    } else return true;
  }
  get getBusMOR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter(
          (e) => e.firearmType.includes("Manually-Operated Rifle")
          //  &&
          // dayjs(dayjs()).diff(dayjs(e.createdDate), "days", true) > 365
        ).length == 0
      );
    } else return true;
  }
  get getBusSLR(): boolean {
    if (this.getAroDocs) {
      return (
        this.getAroDocs.businessProficiencyCertificates.filter(
          (e) => e.firearmType.includes("Self-Loading Rifle")
          // &&
          // dayjs(dayjs()).diff(dayjs(e.createdDate), "days", true) > 365
        ).length == 0
      );
    } else return true;
  }

  currentDoc: {
    IsRenewed: boolean;
    SAPSCompetencyCertificateID: number;
    SAPSCompetencyAROID: number;
    IsRegulation21Certificate: boolean;
    SAPSShotgun: boolean;
    SAPSHandgun: boolean;
    SAPSSelfloadingrifle: boolean;
    SAPSManuallyoperatedrifle: boolean;
    SAPSCompetencyFile: File | null;
    SAPSCompetencyExpiryDate: string;
  } | null = null;

  loading = false;
  URL = URL;

  // certPreview: null | string = null;
  // renewedPreview: null | string = null;
  errorHandler(e: any) {
    //   logger.log("Erroror", e);
  }
  get getCertPreview() {
    // return this.psiraPreview;
    if (this.currentDoc?.SAPSCompetencyFile != null)
      return URL.createObjectURL(this.currentDoc.SAPSCompetencyFile);
    else return "";
  }

  async init(): Promise<void> {
    if (this.certificate != null) {
      const res = await Promise.all([
        Documents.getFile(this.certificate.filePath),
        Documents.getFile(this.certificate.renewedDocumentFilePath),
      ]);
      this.currentDoc = {
        IsRenewed: false,
        SAPSCompetencyCertificateID: this.certificate.id,
        SAPSCompetencyAROID: this.certificate.armResponseOfficerID,
        SAPSCompetencyFile: res[0],
        IsRegulation21Certificate: this.certificate.isRegulation21Certificate,
        SAPSShotgun: this.certificate.firearmType.includes("Shotgun"),
        SAPSHandgun: this.certificate.firearmType.includes("Handgun"),
        SAPSManuallyoperatedrifle: this.certificate.firearmType.includes(
          "Manually-Operated Rifle"
        ),
        SAPSSelfloadingrifle:
          this.certificate.firearmType.includes("Self-Loading Rifle"),
        SAPSCompetencyExpiryDate: dayjs(this.certificate.expiryDate).format(
          "YYYY-MM-DD"
        ),
      };
    } else {
      this.currentDoc = {
        IsRenewed: false,
        SAPSCompetencyCertificateID: 0,
        SAPSCompetencyAROID: this.aroId,
        SAPSCompetencyFile: null,
        IsRegulation21Certificate: true,
        SAPSShotgun: false,
        SAPSHandgun: false,
        SAPSManuallyoperatedrifle: false,
        SAPSSelfloadingrifle: false,
        SAPSCompetencyExpiryDate: dayjs().format("YYYY-MM-DD"),
      };
    }
  }
  async mounted(): Promise<void> {
    logger.log("Test date ", dayjs(dayjs()).diff(dayjs("2021-04-08"), "days"));
    await this.init();
  }
  async addRegulation21(): Promise<void> {
    try {
      this.loading = true;

      const formData = new FormData();
      // formData.append("IsRegulation21Certificate", String(true));
      // formData.append(
      //   "SAPSCompetencyCertificateID",
      //   String(this.certificate.id)
      // );
      // formData.append(
      //   "SAPSCompetencyAROID",
      //   String(this.certificate.armResponseOfficerID)
      // );

      if (this.currentDoc != null) {
        Object.entries(this.currentDoc).forEach(([key, value]) => {
          if (value == null) {
            formData.append(key, "");
          } else if (typeof value === "number" || typeof value === "boolean") {
            formData.append(key, value.toString());
          } else formData.append(key, value);
        });
      }

      //   logger.log("Form data ", formData);
      const res = await axiosInstance.post(
        "/api/ManageDocumentsAPI/addSAPSCompetency",
        formData
      );
      this.$emit("refresh");
      //   logger.log("Add Refulations 21 response", res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  @Watch("certificate", {
    immediate: true,
  })
  async onCertChanged(
    val: string | File | null,
    oldVal: string | File | null
  ): Promise<void> {
    //   logger.log("Sor changed from ", oldVal, " to ", val);
    await this.init();
  }
}

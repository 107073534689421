
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { ShiftSite, Shifts, Teams } from "@/hooks";
import { ArmResponseOfficer } from "models";
import Auth from "@/store/modules/Auth";
import dayjs from "dayjs";
import logger from "@/plugins/logger";

@Component({})
export default class ManageShiftsModal extends Vue {
  @PropSync("open") manageItem!: boolean;
  @PropSync("refresh") refreshData!: boolean;
  @Prop() readonly data!: {
    type: string;
    field: any;
  };
  valid = false;
  isArmResponseOfficer = true;
  isSecurityGuardTeam = false;
  loading = false;
  addAreaModal = false;
  newArea = "";
  validNewArea = false;
  shiftSites: any[] = []; // Originally: shiftSites!: any;
  teams: any[] = []; // Originally: teams!: any;
  startDateMenu = false;
  endDateMenu = false;
  startTimeMenu = false;
  endTimeMenu = false;
  aroList: any[] = [];
  loadTeam = false;
  headers = [
    { text: "Team StandBy List", value: "name" },
    { text: "Add to Shift", value: "shift", sortable: false },
  ];
  items: ArmResponseOfficer[] = [];
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
  };

  get getShiftDurationLimit(): number | null {
    if (Auth.getUserLimits) {
      return Auth.getUserLimits.shiftDurationLimit;
    } else {
      return null;
    }
  }

  get checkShiftDuration(): boolean {
    const startDate = this.data.field.startDate;
    const endDate = this.data.field.endDate;
    const startTime = this.data.field.startTime;
    const endTime = this.data.field.endTime;

    /* logger.log("sd", startDate, "eD", endDate, "sT", startTime, "eT", endTime); */

    /* logger.log("endtime hour", Number(endTime.split(":")[0]));
    logger.log("endtime minutes", Number(endTime.split(":")[1])); */
    if (
      startDate != null &&
      endDate != null &&
      startTime != null &&
      endTime != null &&
      startDate != undefined &&
      endDate != undefined &&
      startTime != undefined &&
      endTime != undefined
    ) {
      var date1 = dayjs(startDate)
        .hour(Number(startTime.split(":")[0]))
        .minute(Number(startTime.split(":")[1]));
      var date2 = dayjs(endDate)
        .hour(Number(endTime.split(":")[0]))
        .minute(Number(endTime.split(":")[1]));
      let hours = date2.diff(date1, "hours");
      /* logger.log("start date full", date1);
      logger.log("end date full", date2); */
      logger.log("hours", hours);

      if (this.getShiftDurationLimit != null) {
        if (this.getShiftDurationLimit >= hours) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  @Watch("data.field", { immediate: true })
  async modalDataChanged(): Promise<void> {
    logger.log("This modal field:", this.data.field);
    if (this.data.field.team != null) {
      this.isSecurityGuardTeam = this.data.field.team.isSecurityGuardTeam;
    }
    if (this.data.field.team != null) {
      this.isArmResponseOfficer =
        this.data.field.team.isSecurityGuardTeam == true ? false : true;
    }
    await this.getTeamsandSites();
    if (this.data.type == "add") {
      this.aroList = [];
    } else if (this.data.type == "edit") {
      /* this.aroList = this.getMemberIDs(
        this.data.field.teamAROViewModels,
        this.data.field.armResponseOfficers,
        false
      ); */
      await this.getTeamStandByListByTeamID();
      this.aroList = [];
      this.data.field.shiftArmResponseOfficers.forEach((elem: any) => {
        elem.isTeamMember == false
          ? this.aroList.push(elem.armResponseOfficerID)
          : "";
      });
    }
  }

  @Watch("aroList", { immediate: true })
  async aroListChanged(): Promise<void> {
    logger.log("ARO List: ", this.aroList);
  }

  async getTeamsandSites(): Promise<void> {
    let isSG = false;
    isSG = this.isArmResponseOfficer == true ? false : true;

    try {
      this.loadTeam = true;
      const res = await ShiftSite.getTeamAndSiteList(isSG);
      this.teams = [];
      this.shiftSites = [];

      this.teams = res.lstTeam;
      this.shiftSites = res.lstShiftSite ? res.lstShiftSite : [];
      this.loadTeam = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadTeam = false;
    }
  }

  async getTeamStandByListByTeamID(): Promise<void> {
    this.items = [];
    try {
      const res = await Teams.getTeamStandByListByTeamID(
        this.data.field.teamID
      );

      this.items = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async addItem(): Promise<void> {
    logger.log("Add Item info: ", this.data.field);

    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem) => {
      formdata.append(elem, this.data.field[elem]);
    });
    formdata.append("isSecurtityGuardTeam", String(this.isSecurityGuardTeam));
    formdata.append("aroIDs", String(this.aroList));
    /* formdata.append("updateTeam", ) */

    try {
      const res = await Shifts.addShift(formdata);
      this.$notifCreator.createSuccessNotification("Shift Added Succesfully!");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.manageItem = false;
    }
  }

  async editItem(): Promise<void> {
    logger.log("Edit Item info: ", this.data.field);

    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem) => {
      if (this.data.field[elem] != null) {
        formdata.append(elem, this.data.field[elem]);
      }
    });
    formdata.append("isSecurtityGuardTeam", String(this.isSecurityGuardTeam));
    formdata.append("aroIDs", String(this.aroList));
    /* formdata.append("updateTeam", ) */

    try {
      const res = await Shifts.editShift(formdata);
      this.$notifCreator.createSuccessNotification("Shift Edit Succesfully!");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.manageItem = false;
    }
  }

  async addNewSite(): Promise<void> {
    logger.log("New Site Name: ", this.newArea);

    try {
      const res = await Shifts.addShiftSite(
        this.newArea,
        this.isArmResponseOfficer
      );

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.addAreaModal = false;
      this.getTeamsandSites();
    }
  }
}

import { SubscriptionTrial } from "@/models/subscription";
import { axiosInstance } from "@/plugins/axios";
import logger from "@/plugins/logger";
import SubscriptionModule from "@/store/modules/Subscription";
import axios from "axios";
import { subscription, updateSubscriptionRequest } from "subscriptionModels";
import { User } from ".";
import Auth from "@/hooks/Authenticate";

const Subscriptions = () => {
  const authHook = Auth;
  const getSubscription = async () => {
    try {
      const res = await axiosInstance.get(
        "/api/subscriptions/getUserSubscription",
        {
          headers: { skipError: true },
        }
      );
      if (res && res.status === 200) {
        SubscriptionModule.setSubscription(res.data as subscription);
      } else {
        SubscriptionModule.setSubscription(null);
      }

      logger.log("Res for getSub: ", res);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 404) {
          logger.warn("Trust 404");
          SubscriptionModule.setSubscription(null);
        }

        // Attempt trial run and user refresh on error
        try {
          await getTrialRun();
          await authHook.getUser();
        } catch (innerErr) {
          logger.error("Error in trial run or user refresh", innerErr);
          return Promise.reject(innerErr);
        }

        return Promise.resolve(null);
      }

      logger.error("Unexpected error", err);
      return Promise.reject(err);
    }

    return Promise.resolve();
  };
  const updateSubscription = async (val: updateSubscriptionRequest) => {
    const res = await axiosInstance.post(
      "/api/subscriptions/updateUserSubscription",
      val
    );

    if (res.status == 200) {
      SubscriptionModule.setSubscription(res.data as subscription);
    } else {
      SubscriptionModule.setSubscription(null);
    }
    logger.log("Res for getSub: ", res);
    return Promise.resolve();
  };
  const getTrialRun = async () => {
    const res = await axiosInstance.get<SubscriptionTrial>(
      "/api/Subscriptions/getUserTrialRun"
    );
    SubscriptionModule.setTrial(res.data);
    return res.data;
  };

  return {
    getSubscription,
    updateSubscription,
  };
};

const temp = Subscriptions();
export default temp;

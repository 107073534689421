
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { Teams, AROs } from "@/hooks";
import { ArmResponseOfficer } from "models";
import logger from "@/plugins/logger";

@Component({})
export default class ManageUserModal extends Vue {
  @PropSync("open") manageItem!: boolean;
  @PropSync("refresh") refreshData!: boolean;
  @Prop() readonly data!: {
    type: string;
    field: any;
  };
  valid = false;
  isArmResponseOfficer = true;
  loading = false;
  items: ArmResponseOfficer[] = [];
  aroList: number[] = [];
  soList: number[] = [];
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
  };

  @Watch("aroList", { immediate: true })
  async aroListChanged(): Promise<void> {
    logger.log("ARO List: ", this.aroList);
  }

  @Watch("soList", { immediate: true })
  async soListChanged(): Promise<void> {
    logger.log("SO List: ", this.soList);
  }

  @Watch("data.field", { immediate: true })
  async modalDataChanged(): Promise<void> {
    await this.loadAROSOList();
    logger.log("This modal field:", this.data.field);
    if (this.data.type == "add") {
      this.aroList = [];
      this.soList = [];
    } else if (this.data.type == "edit") {
      this.aroList = this.getMemberIDs(
        this.data.field.teamAROViewModels,
        this.data.field.armResponseOfficers,
        false
      );

      this.soList = this.getMemberIDs(
        this.data.field.teamAROViewModels,
        this.data.field.armResponseOfficers,
        true
      );
    }
  }

  @Watch("data.type", { immediate: true })
  async modalTypeChanged(): Promise<void> {
    await this.loadAROSOList();
  }

  async loadAROSOList(): Promise<void> {
    this.items = [];

    let isSG = false;
    if (this.data.type == "add") {
      isSG = this.isArmResponseOfficer == true ? false : true;
      try {
        this.loading = true;
        const res = await AROs.getAROOrSGList(isSG);
        this.items = res;
        return Promise.resolve();
      } catch (err) {
        this.loading = false;
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    } else if (this.data.type == "edit") {
      try {
        this.loading = true;
        const res = await AROs.getAROOrSGListTeamID(
          this.data.field.isSecurityGuardTeam,
          this.data.field.id
        );
        this.items = res;
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
    }
  }

  async addItem(): Promise<void> {
    logger.log("Add Team info: ", this.data.field);

    try {
      const res = await Teams.addTeam(
        this.data.field.name,
        String(this.aroList.length > 0 ? this.aroList : " "),
        String(this.soList.length > 0 ? this.soList : " "),
        this.isArmResponseOfficer == true ? false : true
      );
      this.$notifCreator.createSuccessNotification("Team Added Succesfully!");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.manageItem = false;
    }
  }

  async editItem(): Promise<void> {
    logger.log("Edit Team info: ", this.data.field);

    try {
      const res = await Teams.editTeam(
        this.data.field.id,
        this.data.field.name,
        String(this.aroList.length > 0 ? this.aroList : " "),
        String(this.soList.length > 0 ? this.soList : " ")
      );
      this.$notifCreator.createSuccessNotification("Team Edited Succesfully!");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.manageItem = false;
    }
  }

  getMemberIDs(arr1: any[], arr2: any[], bool: boolean): any {
    let value: any[] = [];

    if (bool == false) {
      arr1.forEach((team) => {
        const res = arr2.find(
          (aro) =>
            aro.aroid == team.armResponseOfficerID && team.isStandBy == bool
        );
        if (res) {
          value.push(res.aroid);
          logger.log("editAROList : ", value);
        }
      });
      logger.log("editAROList : ", value);
    } else if (bool == true) {
      arr1.forEach((team) => {
        const res = arr2.find(
          (aro) =>
            aro.aroid == team.armResponseOfficerID && team.isStandBy == bool
        );
        if (res) {
          value.push(res.aroid);
          logger.log("editSOList : ", value);
        }
      });
    }
    return value;
  }
}


import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { AROs } from "@/hooks";
import { AroResponseCounts, SuggestedUsernames } from "models";
import AuthModule from "@/store/modules/Auth";
import { UserNameModel } from "@/models/username.model";
import Signature from "@/components/Signature.vue";
import logger from "@/plugins/logger";
import SubscriptionModule from "@/store/modules/Subscription";

@Component({
  components: {
    Signature,
  },
})
export default class manageAROModal extends Vue {
  @Prop() counts!: AroResponseCounts;
  @PropSync("open") manageItem!: boolean;
  @PropSync("refresh") refreshData!: boolean;
  @Prop() readonly data!: {
    type: string;
    field: any;
    reportsToUser: {
      name: string;
      value: number;
    }[];
  };
  valid = false;
  redacted = false;
  picture: File | Blob | null = null;
  signature: File | Blob | null = null;
  // adminSignature: File | Blob | null = null;
  isArmResponseOfficer = false;
  password: string | null = "";
  confirmPassword: string | null = "";
  usernameHint: string | null = null;
  get getSubscription() {
    if (SubscriptionModule.currentSubscription == null) return null;
    return SubscriptionModule.currentSubscription;
  }
  get aroRules() {
    const rules = {
      limit: (v: boolean) => {
        if (v == true) {
          if (this.getSubscription) {
            if (
              this.getSubscription.aroCount <
              this.getSubscription.numberOfArmedResponseOfficersAllowed
            ) {
              return true;
            } else {
              return "You have reached the limit of AROs.";
            }
          } else if (this.getLimits) {
            if (this.counts.aroCount < this.getLimits.aroLimit) {
              return true;
            } else {
              return "You have reached the limit of AROs.";
            }
          } else {
            return "Something went wrong while checking limits. Please Contact Admin.";
          }
        } else {
          return true;
        }
        //return false;
      },
    };
    return [rules.limit];
  }
  get soRules() {
    const rules = {
      limit: (v: boolean) => {
        if (v == true) {
          if (this.getSubscription) {
            if (
              this.getSubscription.soCount <
              this.getSubscription.numberOfSecurityOfficersAllowed +
                this.getSubscription.additionalSOUsers
            ) {
              return true;
            } else {
              return "You have reached the limit of SOs.";
            }
          } else if (this.getLimits) {
            if (this.counts.soCount < this.getLimits.soLimit) {
              return true;
            } else {
              return "You have reached the limit of SOs.";
            }
          } else {
            return "Something went wrong while checking limits. Please Contact Admin.";
          }
        } else {
          return true;
        }
        //return false;
      },
    };
    return [rules.limit];
  }
  get getLimits() {
    //console.log("getUserLimits", AuthModule.getUserLimits);
    return AuthModule.getUserLimits;
  }
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 uppercase letter"
      );
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 lowercase letter"
      );
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return (value ? pattern.test(value) : true) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return (
        (value ? pattern.test(value) : true) ||
        "Requires atleast 1 special character"
      );
    },
    id: (value: string): boolean | string =>
      (value ? value.length == 13 && /^(?!^0*$)/.test(value) : true) ||
      "Requires a valid 13 digit ID number",
    cell: (value: string): boolean | string =>
      (value ? value.length == 10 : true) || "Requires 10 digits",
    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    // confirmPassword: (value: string): boolean | string =>
    //   (value && this.password === value) ||
    //   "Please confirm your password",
    confirmPassword: (value: string): boolean | string =>
      this.passwordConfirmation(value) ||
      `Passwords do not match ${this.password}`,
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };

  // @Watch("data", { immediate: true })
  // init(): void {
  //   HTMLFormControlsCollection.log("Initialize form")
  //   this.form = { ...this.data } as AROViewModel;
  //   (this.$refs.form as HTMLFormElement).validate;
  // }

  /**
   * https://stackoverflow.com/a/57056501
   */
  // @Watch("password")
  // async passwordConfirmation(): Promise<void> {
  //   logger.log("Password Confirmation", this.$refs);
  //   try {
  //     await this.$nextTick();
  //     (this.$refs["passwordConfirmationInput"] as HTMLFormElement).validate();
  //   } catch (err) {
  //     logger.log("Error", err);
  //   }
  // }

  // handleSignatureUpdate(b64: string) {
  //   logger.log("Signature: ", b64);
  //   if (b64 != undefined) {
  //     const fileName =
  //       AuthModule.getUserOverview?.name +
  //       " " +
  //       AuthModule.getUserOverview?.surname +
  //       ".png";

  //     const file = this.base64ToFile(b64, fileName);
  //     logger.log("File", file);
  //     this.adminSignature = file;
  //   }
  // }

  base64ToFile(base64: string, fileName: string): File {
    // Extract the base64 string part, removing the data URL prefix
    const byteString = atob(base64.split(",")[1]);

    // Create an ArrayBuffer and a view into it
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    // Populate the view, converting each character to its byte equivalent
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Determine the MIME type from the base64 string
    const mimeType = base64.split(",")[0].match(/:(.*?);/)?.[1] || "";

    // Create a new File object
    return new File([ab], fileName, { type: mimeType });
  }

  @Watch("password")
  @Watch("confirmPassword")
  passwordConfirmation(pass: string): boolean {
    if (pass == this.password) {
      return true;
    } else {
      return false;
    }
  }

  @Watch("data.field.isSecurityGuard", { immediate: true })
  onisSecurityGaurdChange(): void {
    if (this.data.field.isSecurityGuard == true) {
      this.isArmResponseOfficer = false;
      this.data.field.isSecurityGuard = true;
    } else {
      this.isArmResponseOfficer = true;
      this.data.field.isSecurityGuard == true;
    }
  }

  @Watch("manageItem", { immediate: true })
  onOpenChanged(): void {
    if (this.manageItem == true) {
      this.isArmResponseOfficer = false;
      this.data.field.isSecurityGuard = false;
    }
  }

  closeModal(): void {
    this.password = "";
    this.confirmPassword = "";
    this.picture = null;
    this.signature = null;
    // this.adminSignature = null;
    this.manageItem = false;
    (this.$refs.form as HTMLFormElement).reset();
  }

  @Watch("data.field", { immediate: true })
  modalDataChanged(): void {
    logger.log("modal fields:", this.data.field);
  }

  async submit(): Promise<void> {
    logger.log("Add user info: ", this.data.field);

    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem) => {
      formdata.append(elem, this.data.field[elem]);
    });
    if (
      this.password &&
      this.confirmPassword &&
      this.password == this.confirmPassword
    ) {
      formdata.append("password", this.password);
      formdata.append("confirmPassword", this.confirmPassword);
    }
    formdata.append(
      "customUserName",
      this.data.field.userName == undefined ? "" : this.data.field.userName
    );
    formdata.append("emailAddress", this.data.field.email.trim());
    formdata.append("picture", this.picture ? this.picture : "");
    formdata.append("signature", this.signature ? this.signature : "");
    formdata.append("adminSignature", "");
    formdata.append(
      "isSG",
      String(this.data.field.isSecurityGuard == true ? true : false)
    );

    try {
      const res = await AROs.addARO(formdata);
      this.$notifCreator.createSuccessNotification("ARO Added Succesfully!");

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.closeModal();
      this.refreshData = true;
    }
  }

  async ConfirmUsernameExist(): Promise<SuggestedUsernames> {
    try {
      this.usernameHint = null;
      let u: UserNameModel = new UserNameModel();
      u.firstName = this.data.field.name;
      u.lastName = this.data.field.surname;
      u.username = this.data.field.userName;
      const username = await AROs.confirmUsernameExist(u);
      if (username.usernameExist) {
        username.sugestedUsername.forEach((element) => {
          this.usernameHint =
            this.usernameHint == null
              ? element
              : this.usernameHint + " " + element;
        });
        this.usernameHint =
          "This username already exist please try another or one of the suggested :" +
          this.usernameHint;
      } else {
        this.usernameHint = null;
      }
      return Promise.resolve(username);
    } catch (err) {
      this.usernameHint = "Error validating username";
      return Promise.reject(err);
    }
  }
}

import AuthModule from "@/store/modules/Auth";
import { ROLE as RolesEnum } from "@/enums/roles";

const Navigation = () => {
  const allowedRoutes: {
    [key: number]: string[];
  } = {
    [RolesEnum["Super Admin"]]: [
      "Login",
      "dash.quickLinks",
      "dash.pocketbook.aro",
      // "dash.pocketbook.supervisor",
      "dash.overview",
      "dash.manage",
      "dash.manage.users",
      "dash.profile",
      "dash.notificationList",
    ],
    [RolesEnum["Control Room"]]: [
      "Login",
      "dash.quickLinks",
      "dash.overview",
      "dash.pocketbook.aro", // TODO: Should control room have access to the pocket book ?
      // "dash.pocketbook.supervisor",
      "dash.manage",
      "dash.manage.documents",
      "dash.manage.aro",
      "dash.registers.firearms",
      "dash.registers",
      "dash.registers.dailyCombined",
      "dash.manage.teams",
      "dash.registers.ammo",
      "dash.manage.shifts",
      "dash.manage.incidents",
      "dash.manage.permits",
      "dash.profile",
      // "dash.report",
      "dash.notificationList",
    ],
    [RolesEnum["Responsible Person"]]: [
      "Login",
      "dash.quickLinks",
      "dash.overview",
      // "dash.pocketbook.aro",
      "dash.pocketbook.supervisor",
      "dash.offlinePermits",
      "dash.manage",
      "dash.manage.documents",
      "dash.manage.incidents",
      "dash.registers",
      "dash.registers.dailyCombined",
      "dash.manage.teams",
      "dash.manage.shifts",
      "dash.registers.firearms",
      "dash.registers.ammo",
      "dash.manage.permits",
      "dash.manage.aro",
      "dash.manage.users", // "dash.manage.users" ?
      "dash.myPermits",
      "dash.report",
      "dash.report.aro_so",
      "dash.report.ammo",
      "dash.report.compliance",
      "dash.report.firearms",
      "dash.report.incident",
      "dash.report.shift",
      "dash.report.permit",
      "dash.archived",
      "dash.profile",
      "dash.notificationList",
    ],
    [RolesEnum["Shift Supervisor"]]: [
      "Login",
      "dash.quickLinks",
      "dash.overview",
      "dash.pocketbook.aro",
      "dash.pocketbook.supervisor",
      "dash.offlinePermits",
      "dash.manage",
      "dash.manage.documents",
      "dash.manage.aro",
      "dash.registers",
      "dash.registers.dailyCombined",
      "dash.registers.firearms",
      "dash.registers.ammo",
      "dash.manage.teams",
      "dash.manage.shifts",
      "dash.manage.incidents",
      "dash.manage.permits",
      "dash.myPermits",
      // "dash.report",
      "dash.archived",
      "dash.profile",
      "dash.notificationList",
    ],
    [RolesEnum["Arm Response Officer"]]: [
      "Login",
      "dash.quickLinks",
      "dash.myPermits",
      "dash.pocketbook.aro",
      "dash.manage",
      // "dash.manage.teams",
      // "dash.manage.shifts",
      "dash.manage.documents",
      "dash.manage.incidents",
      "dash.offlinePermits",
      "dash.profile",
      "dash.notificationList",
    ],
    [RolesEnum["Security Firm Admin"]]: [
      "Login",
      "dash.quickLinks",
      "dash.overview",
      // "dash.pocketbook.aro",
      "dash.pocketbook.supervisor",
      "dash.offlinePermits",
      "dash.manage",
      "dash.manage.users",
      "dash.manage.documents",
      "dash.manage.incidents",
      "dash.manage.teams",
      "dash.manage.shifts",
      "dash.registers.firearms",
      "dash.registers",
      "dash.registers.dailyCombined",
      "dash.registers.ammo",
      "dash.manage.permits",
      "dash.manage.aro",
      "dash.manage.users",
      "dash.myPermits",
      "dash.report",
      "dash.report.aro_so",
      "dash.report.ammo",
      "dash.report.compliance",
      "dash.report.firearms",
      "dash.report.incident",
      "dash.report.shift",
      "dash.report.permit",
      "dash.archived",
      "dash.activityLog",
      "dash.profile",
      "dash.notificationList",
    ],
    [RolesEnum["Security Firm Sub Admin"]]: [
      "Login",
      "dash.quickLinks",
      "dash.overview",
      "dash.pocketbook.aro",
      // "dash.pocketbook.supervisor",
      "dash.manage",
      "dash.manage.documents",
      "dash.manage.incidents",
      "dash.manage.teams",
      "dash.manage.shifts",
      "dash.registers.firearms",
      "dash.registers",
      "dash.registers.dailyCombined",
      "dash.registers.ammo",
      "dash.manage.aro",
      // "dash.report",
      "dash.archived",
      "dash.profile",
      "dash.notificationList",
    ],
  };

  const hasAccess = (name: string | null | undefined): boolean => {
    if (name == null || name == undefined) {
      return false;
    }
    return AuthModule.getUserRoles
      .flatMap((role) => {
        return allowedRoutes[role.roleId];
      })
      .includes(name);
  };

  return {
    hasAccess,
    RolesEnum,
    allowedRoutes,
  };
};
const temp = Navigation();
export default temp;


import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { EditFirearmViewModel, Firearm } from "models";
import dayjs from "dayjs";
import { Firearms } from "@/hooks";
import logger from "@/plugins/logger";
@Component({})
export default class manageItemModal extends Vue {
  @PropSync("open") manageItem!: boolean;
  @PropSync("refresh") refreshData!: boolean;
  @Prop() readonly data!: {
    type: string; // "Add" | "Edit"
    field: any; // EditFirearmViewModel ?
  };
  valid = false;
  lastMaintenanceDateMenu = false;
  dateOfIssueMenu = false;
  expiryDateMenu = false;
  renewalDateMenu = false;
  licenseFile = "";
  imageFile = "";
  renewedDocumentFilePath = "";
  currentDOI = "";
  currentExipryDate = "";
  types = [
    { name: "Handgun", value: 1 },
    { name: "Shotgun", value: 2 },
    { name: "Self-Loading Rifle", value: 3 },
    { name: "Manually-Operated Rifle", value: 4 },
  ];
  calibres = [
    ".22LR",
    "6,35 Auto",
    "7,65 Auto",
    ".38 Special",
    ".357 Magnum",
    "9mm S",
    "9mm P",
    ".40S&W",
    ".45ACP",
    ".12 Gauge",
    ".12 Gauge - Less Lethal",
    ".22LR",
    "5,56X45",
    ".223 REM",
    "7,62X39",
    "7,62X51",
    ".308 WIN",
  ];
  licenses = [{ name: "Business Use", value: 1 }];
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };
  today() {
    return dayjs().format("YYYY-MM-DD");
  }

  closeModal(): void {
    this.licenseFile = "";
    this.imageFile = "";
    this.renewedDocumentFilePath = "";
    this.manageItem = false;
  }
  saveField(field: string, value: string) {
    // this.$refs.lastMaintenanceDateMenu ? this.$refs.lastMaintenanceDateMenu.save(data.field.lastMaintenanceDate) : ''
    const form = this.$refs[field] as any;
    if (form != undefined) {
      form.save(value);
    }
    // this.data[field] = value;
  }

  @Watch("data.field", { immediate: true })
  modalDataChanged(): void {
    logger.log("This modal field:", this.data.field);
    if (this.data.type == "edit") {
      this.data.field.renewedDocumentFilePath = null;
      this.data.field.imageFile = null;
      this.data.field.licenseFile = null;
    }
    this.currentDOI = this.data.field.fromDate;
    this.currentExipryDate = this.data.field.expiryDate;
    logger.log("DOI", this.currentDOI);
    logger.log("ED", this.currentExipryDate);
  }

  @Watch("data.field.fromDate", { immediate: false })
  fromDateChanged(): void {
    if (this.data.type == "edit") {
      if (this.data.field.fromDate != this.currentDOI) {
        if (this.data.field.expiryDate != this.currentExipryDate) {
          logger.log("Expiry Date Not Changed");
        } else {
          this.data.field.expiryDate = "";
        }
      } else if (this.data.field.fromDate == this.currentDOI) {
        logger.log("DOI Not Changed");
      }
    }
  }

  @Watch("data.field.expiryDate", { immediate: false })
  expiryDateChanged(): void {
    if (this.data.type == "edit") {
      if (this.data.field.expiryDate != this.currentExipryDate) {
        if (this.data.field.fromDate != this.currentDOI) {
          logger.log("DOI Not Changed");
        } else {
          this.data.field.fromDate = "";
        }
      } else if (this.data.field.expiryDate == this.currentExipryDate) {
        logger.log("Expiry Date Not Changed");
      }
    }
  }

  async addItem(): Promise<void> {
    logger.log("Updated Firearm Information: ", this.data.field);

    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem: string) => {
      formdata.append(elem, this.data.field[elem]);
    });

    /*  formdata.append("licenseFile", this.licenseFile);
    formdata.append("imageFile", this.imageFile);
    formdata.append("renewedDocumentFilePath", this.renewedDocumentFilePath); */

    try {
      const res = await Firearms.addFirearm(formdata);
      this.$notifCreator.createSuccessNotification(
        "Firearm Added Succesfully!"
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.closeModal();
    }
  }

  async editItem(): Promise<void> {
    logger.log("Updated Firearm Information: ", this.data.field);

    var formdata = new FormData();

    Object.keys(this.data.field).forEach((elem) => {
      if (this.data.field[elem] != null) {
        formdata.append(elem, this.data.field[elem]);
      }
    });

    try {
      logger.log(formdata);
      const res = await Firearms.editFirearm(formdata);
      this.$notifCreator.createSuccessNotification(
        "Firearm Updated Succesfully!"
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.closeModal();
    }
  }
}


import { Component, Vue, Watch } from "vue-property-decorator";
import { AROs, Documents, Firearms } from "@/hooks";
import VuePdfApp from "vue-pdf-app";
import { ArmResponseOfficer, AROViewModel, Certificate } from "models";
import { DOCUMENTS } from "@/enums/documents";
import { axiosInstance } from "@/plugins/axios";
import Docs from "@/store/modules/Documents";

import OverviewDocs from "@/views/manage/documents/OverviewDocs.vue";
import ModifyUserDocs from "@/views/manage/documents/ModifyUserDocs.vue";
import dayjs from "dayjs";
import AuthModule from "@/store/modules/Auth";
import logger from "@/plugins/logger";

@Component({
  components: {
    OverviewDocs,
    ModifyUserDocs,
  },
  filters: {
    pretty: function (value: any) {
      return JSON.parse(JSON.stringify(value, null, 4));
    },
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    daysRemaining: function (value: string) {
      const today = dayjs();
      const exp = dayjs(value);
      const rem = today.diff(exp, "d");

      if (rem < 0) {
        return -1 * rem + " days remaining";
      } else {
        return rem + " days late";
      }
    },
  },
})
export default class ManageDocuments extends Vue {
  loading = false;
  userIsARO = false;
  date = null;
  trip = {
    name: "",
    location: null,
    start: null,
    end: null,
  };
  locations = [
    // TODO: Surely there should be a larger list of locations ?
    "Australia",
    "Barbados",
    "Chile",
    "Denmark",
    "Ecuador",
    "France",
  ];
  userFilter(
    item: ArmResponseOfficer,
    queryText: string,
    itemText: string
  ): boolean {
    // logger.log("Searched ", queryText, itemText, item);
    if (
      (item.teamName != null &&
        item.teamName
          .toLocaleLowerCase()
          .includes(queryText.toLocaleLowerCase())) ||
      item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
      item.surname.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  }
  viewStep(val: string) {
    switch (val) {
      case "driversLic":
      case "adhoc":
      case "psiraRegDocs":
        // setTimeout(Docs.setCurrentStep, 100, 1);
        Docs.setCurrentStep(1);
        break;
      case "koa":
        // setTimeout(Docs.setCurrentStep, 100, 1);
        Docs.setCurrentStep(2);
        break;
      case "basicPus":
        // setTimeout(Docs.setCurrentStep, 100, 1);
        Docs.setCurrentStep(3);
        break;
      case "businessPus":
        // setTimeout(Docs.setCurrentStep, 100, 4);
        Docs.setCurrentStep(4);
        break;
      case "sapsComp":
        // setTimeout(Docs.setCurrentStep, 100, 4);
        Docs.setCurrentStep(5);
        break;
      case "reg21":
        // setTimeout(Docs.setCurrentStep, 100, 4);
        Docs.setCurrentStep(6);
        break;
      case "idDoc":
        // setTimeout(Docs.setCurrentStep, 100, 0);
        Docs.setCurrentStep(7);
        break;
      case "rangeOfficer":
        // setTimeout(Docs.setCurrentStep, 100, 4);
        Docs.setCurrentStep(8);
        break;
      case "tacticalKnowledge":
        // setTimeout(Docs.setCurrentStep, 100, 1);
        Docs.setCurrentStep(9);
        break;
      case "tacticalUS":
        // setTimeout(Docs.setCurrentStep, 100, 4);
        Docs.setCurrentStep(10);
        break;
      case "firearmInstructionUS":
        // setTimeout(Docs.setCurrentStep, 100, 10);
        Docs.setCurrentStep(11);
        break;
    }
  }
  async removeSelected(): Promise<void> {
    this.selectedUser = null;
    Docs.setAro(null);
    Docs.setAroDocs(null);
  }

  get selectedUser(): AROViewModel | null {
    return Docs.getAro;
  }
  set selectedUser(val: AROViewModel | null) {
    Docs.setAro(val);
  }
  get getAroDocs() {
    return Docs.getAroDocs;
  }
  get getAroDocsBreakdown() {
    return Docs.getAroDocsBreakdown;
  }

  get getAros() {
    return Docs.getAros;
  }

  async refresh(): Promise<void> {
    // await this.loadAros(this.selectedUser); // TODO: Surely this should be called here
    await this.loadAroDocs(this.selectedUser);
    // LoadAroDocs calls Docs.setSelectedAroDocs(null) internally allowing one to deprecate the following lines
    // if (this.selectedUser != null) await this.loadAroDocs(this.selectedUser);
    // else Docs.setSelectedAroDocs(null);
  }
  async loadAROs(): Promise<void> {
    try {
      this.loading = true;
      const res = await AROs.getAros();

      const userRoles = AuthModule.getUserRoles;
      logger.log("Logged in User Roles: ", userRoles);

      if (String(userRoles[0].roleId) == "6") {
        const aro = res.arOs.filter(
          (elem) => elem.userID == userRoles[0].userId
        );
        logger.log("ARO: ", aro);
        this.selectedUser = aro[0];

        this.loadAroDocs(aro[0]);
        this.userIsARO = true;
      } else {
        Docs.setAros(res.arOs);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  async loadAroDocs(aro: AROViewModel | null): Promise<void> {
    // logger.log("loadAroDocs: ", aro);
    // TODO@CVD: After loading documents determine those relevant to the specific user and determine the urgent ones ?
    try {
      this.loading = true;
      Docs.setAroDocs(null);
      if (aro != null && aro.id !== undefined) {
        // TODO@CVD :  Fix this to work with an undefined field.
        const res = await Documents.getAroDocs(aro.id);
        Docs.setAroDocs(res);
        logger.log("getAroDocs", res);
        logger.log("setAroDocs", Docs.getAroDocs);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      Docs.setAro(null);
      await this.loadAROs();
      // logger.log("All Done setup");
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}


import { Component, PropSync, Vue } from "vue-property-decorator";
import jsPDF from "jspdf";
import { jsPDFDocument } from "jspdf-autotable";

// interface Document {
//   document : jsPDFDocument
// }

/*
 * This is to be DEPRECATED and merged into the JsonExcel Element which one should really rename to ``TabulatedReport`` or some such.
 */
@Component
export default class PDF extends Vue {
  compilePDF() {
    // Pass
  }
}

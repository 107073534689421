
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { Ammo } from "@/hooks";
import { Ammunition } from "models";
import dayjs from "dayjs";
import logger from "@/plugins/logger";

@Component({
  filters: {
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },

    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class OModal extends Vue {
  @PropSync("open") overviewModal!: boolean;
  @Prop() readonly data!: {
    tableData: [];
    title: string;
  };
  search = "";
  sortDesc = true;
  sortBy = "purchaseDate";
  popModal = false;
  popData = {};
  headers = [
    { text: "Make", value: "make" },
    { text: "Batch Number", value: "batchNumber" },
    { text: "Quantity", value: "quantity" },
    { text: "Purchase Date", value: "purchaseDate" },
    { text: "Purchase From", value: "purchasedFrom" },
    { text: "Proof of Purchase", value: "pop" },
  ];

  @Watch("data.tableData", { immediate: true })
  modalDataChanged(): void {
    logger.log("Table Data:", this.data.tableData);
  }

  async getFiles(i: Ammunition): Promise<void> {
    try {
      const res = await Ammo.GetAmmunitionFiles(i.id);
      this.popData = res.ammunitionList;
      logger.log("Ammo File", res.ammunitionList);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.popModal = true;
    }
  }

  async download(i: any): Promise<void> {
    try {
      await Ammo.DownLoadAmmunitionFile(i.id).then((response: any) => {
        var type = "image/jpeg";
        if (response.type == "image/jpeg") {
          type = "image/jpeg";
        } else if (response.type == "application/pdf") {
          type = "application/pdf";
        } else if (response.type == "image/png") {
          type = "image/png";
        }
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: type,
          })
        );
        window.open(url);
        /* logger.log(response.type); */
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

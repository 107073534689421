
import { Component, Vue } from "vue-property-decorator";
import { Downloader, Reports } from "@/hooks";
import { ComplianceReport, formatReport } from "models";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import JsonExcel from "@/components/JsonExcel.vue";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    daysRemaining: function (value: string) {
      const today = dayjs();
      const exp = dayjs(value);
      const rem = exp.diff(today, "d");

      return rem;
    },
  },
})
export default class Incident_Report extends Vue {
  reports: ComplianceReport[] = []; // This and expReports need to be managed better
  reportsFiltered: ComplianceReport[] = [];
  loading = false;
  search = "";
  sortDesc = true;
  sortBy = "date";
  headers = [
    { text: "ARO/ SO", value: "aroso" },
    { text: "Full Name", value: "fullName" },
    { text: "Reg No", value: "regNo" },
    { text: "Type/ Team", value: "typeTeam" },
    { text: "Grade", value: "grade" },
    { text: "Expiry Date", value: "expiryD" },
    { text: "Days to Expiry", value: "daysToExp" },
  ];
  expReports: { [index: string]: any }[] = [];
  expFields = {
    "ARO/ SO": "aroso",
    "Full Name": "fullName",
    "Reg No": "regNo",
    "Type/ Team": "typeTeam",
    Grade: "grade",
    "Expiry Date": "expiryD",
    "Days to Expiry": "daysToExp",
  };

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const res = await Reports.getComplianceReport();
      this.reports = res;
      this.expReports = this.populateExportData(this.reports);
      this.loading = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateReportsFiltered(event: any) {
    this.reportsFiltered = event ? (event as any[]) : this.reports;
  }
  get getReportsFiltered() {
    return this.reportsFiltered; // this.populateExportData();
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "ARO/ SO", dataKey: "aroso" },
        { header: "Full Name", dataKey: "fullName" },
        { header: "Reg No", dataKey: "regNo" },
        { header: "Type/ Team", dataKey: "typeTeam" },
        { header: "Grade", dataKey: "grade" },
        { header: "Expiry Date", dataKey: "expiryD" },
        { header: "Days to Expiry", dataKey: "daysToExp" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Compliance Report (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "fullName",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  populateExportData(items: any[]): any[] {
    // This differs from the usual implementation as it is nested one level deeper then it ought to be
    return items.flatMap((elem: ComplianceReport) => {
      //PSIRA
      let a = elem.psiraRegistrationDocuments.map((p) => {
        return {
          aroso:
            elem.isSecurityGuard != null
              ? elem.isSecurityGuard == true
                ? "SO"
                : "ARO"
              : "",
          fullName:
            (elem.name != null ? elem.name : "") +
            ", " +
            (elem.surname != null ? elem.surname : ""),
          regNo:
            elem.psiraRegistrationNumber != null
              ? elem.psiraRegistrationNumber
              : "",
          typeTeam: "PSIRA",
          grade: p.grade != null ? p.grade : "",
          expiryD: p.expiryDateStr != null ? p.expiryDateStr : "",
          daysToExp:
            p.expiryDate != null ? this.getDaysToExpiry(p.expiryDate) : "",
        };
      });
      let b = elem.sapsCompetencyCertificates.map((s) => {
        //SAPS Competency
        if (s.isRegulation21Certificate == false) {
          return {
            aroso:
              elem.isSecurityGuard != null
                ? elem.isSecurityGuard == true
                  ? "SO"
                  : "ARO"
                : "",
            fullName:
              (elem.name != null ? elem.name : "") +
              ", " +
              (elem.surname != null ? elem.surname : ""),
            regNo: "",
            typeTeam: "SAPS Competency",
            grade:
              s.firearmType != null ? s.firearmType.replace(/,*$/, "") : "",
            expiryD: s.expiryDateStr != null ? s.expiryDateStr : "",
            daysToExp:
              s.expiryDate != null ? this.getDaysToExpiry(s.expiryDate) : "",
          };
        }
        //Regulation 21
        if (s.isRegulation21Certificate == true) {
          return {
            aroso:
              elem.isSecurityGuard != null
                ? elem.isSecurityGuard == true
                  ? "SO"
                  : "ARO"
                : "",
            fullName:
              (elem.name != null ? elem.name : "") +
              ", " +
              (elem.surname != null ? elem.surname : ""),
            regNo: "",
            typeTeam: "Regulation 21",
            grade:
              s.firearmType != null ? s.firearmType.replace(/,*$/, "") : "",
            expiryD: s.expiryDateStr != null ? s.expiryDateStr : "",
            daysToExp:
              s.expiryDate != null ? this.getDaysToExpiry(s.expiryDate) : "",
          };
        }
      });
      //Driving License
      let c = elem.drivingLicenses.map((d: any) => {
        return {
          aroso:
            elem.isSecurityGuard != null
              ? elem.isSecurityGuard == true
                ? "SO"
                : "ARO"
              : "",
          fullName:
            (elem.name != null ? elem.name : "") +
            ", " +
            (elem.surname != null ? elem.surname : ""),
          regNo: "",
          typeTeam: "Driving License",
          grade: "",
          expiryD: d.expiryDateStr != null ? d.expiryDateStr : "",
          daysToExp:
            d.expiryDate != null ? this.getDaysToExpiry(d.expiryDate) : "",
        };
      });
      //Team
      let d = elem.teamArmResponseOfficers.map((t) => {
        return {
          aroso:
            elem.isSecurityGuard != null
              ? elem.isSecurityGuard == true
                ? "SO"
                : "ARO"
              : "",
          fullName:
            (elem.name != null ? elem.name : "") +
            ", " +
            (elem.surname != null ? elem.surname : ""),
          regNo: "",
          typeTeam: t.team != null ? t.team.name : "",
          grade: "",
          expiryD: "",
          daysToExp: "",
        };
      });
      return [...a, ...b, ...c, ...d];
    });
  }

  getDaysToExpiry(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");
    return String(rem);
  }

  getColor(i: any, type: string): string {
    if (type == "SAPS Competency") {
      if (i >= 180) return "green";
      else if (i >= 150 && i <= 179) return "yellow";
      else if (i >= 120 && i <= 149) return "orange";
      else if (i >= 90 && i <= 119) return "red";
      else return "red darken-4";
    } else {
      if (i >= 45) return "green";
      else if (i >= 30 && i <= 44) return "yellow";
      else if (i >= 16 && i <= 29) return "orange";
      else if (i >= 0 && i <= 15) return "red";
      else return "red darken-4";
    }
  }
}


import { Component, Vue } from "vue-property-decorator";
import GlobalNotifications from "@/components/notifications/GlobalNotifications.vue";
import OfflineFallback from "@/components/OfflineFallback.vue";
import { ConnectionStatus, Network } from "@capacitor/network";
import dayjs from "dayjs";
import fbHook from "./hooks/firebase";
import AuthModule from "@/store/modules/Auth";
import { Device } from "@capacitor/device";
import {
  Storage as StorageLib,
  Notifications as NotificationLib,
} from "@/hooks";
import {
  PushNotifications,
  Token,
  PushNotificationSchema,
  ActionPerformed,
} from "@capacitor/push-notifications";
import NotificationSystem from "./store/modules/NotificationSystem";
import { UserDetails } from "models";
import updateNotif from "@/components/pwa/updateAvailNotif.vue";
import InstallBanner from "@/components/pwa/InstallBanner.vue";
import { axiosInstance } from "@/plugins/axios";
import logger from "./plugins/logger";
import { Notifications } from "./hooks/Notifications";
// Network.addListener("networkStatusChange", (status) => {
//   logger.log("Tits man.... changed", status);
// });
@Component({
  components: {
    OfflineFallback,
    GlobalNotifications,
    updateNotif,
    InstallBanner,
  },
})
export default class App extends Vue {
  get isOnline(): ConnectionStatus {
    return this.onLine;
  }
  get getVersion() {
    return process.env.VUE_APP_VERSION;
  }
  onLine: ConnectionStatus = {
    connected: false,
    connectionType: "unknown",
  };
  deferredPrompt: Event | null = null;
  showIos = true;
  async mounted(): Promise<void> {
    Network.addListener("networkStatusChange", (status) => {
      this.onLine = status;
      logger.log("Network status changed", status);
    });
    this.onLine = await Network.getStatus();
    this.$vuetify.theme.dark = false;

    const devInfo = await Device.getInfo();
    NotificationSystem.setDeviceInfo(devInfo);
    const id = await Device.getId();

    /* logger.log("media: ", media);
    logger.log("andref: ", andref); */
    if (this.getProfile != null /*  && (media == true || andref == true) */) {
      /* logger.log("token w/ getProfile: ", token); */

      const token = await fbHook.getPWAToken();

      if (token != null) {
        await StorageLib.setFcmToken(token);
        NotificationSystem.setFirebaseToken(token);
      }

      if (token != null) {
        logger.log(
          "Firebase Token: ",
          token,
          "devInfo: ",
          devInfo,
          "deviceId: ",
          id
        );
        await NotificationLib.registerFcmOnServer(token, devInfo, id);
      }
    }
  }

  get getProfile(): UserDetails | null {
    logger.log("getUser: ", AuthModule.getUser);
    return AuthModule.getUser;
  }

  getYear(): number {
    return dayjs().year();
  }

  async created() {
    const devInfo = await Device.getInfo();

    if (devInfo.platform == "android" || devInfo.platform == "ios") {
      logger.log("OS: ", devInfo.osVersion);
      // const temp = Number(devInfo.osVersion);
      // if (devInfo.osVersion && Number.isNaN(temp) == false && temp >= 11) {
      //   logger.log("Foreground Notifications hid on: ", devInfo.osVersion);
      // }
      await fbHook.addMobileListeners();
      if (devInfo.platform == "ios") {
        await Notifications.registerMobileFirebaseNotifications();
      }
    } else if (devInfo.platform == "web") {
      logger.log("Created web: ", devInfo);
    }
  }
}

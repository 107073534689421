import { axiosInstance } from "@/plugins/axios";
import logger from "@/plugins/logger";
import axios, { AxiosError, AxiosResponse } from "axios";
import { AroDocs } from "models";

const Documents = () => {
  const fallbackImage: null | string = null;
  const signatures = {
    ["JVBERi0" as string]: "application/pdf",
    ["R0lGODdh" as string]: "image/gif",
    ["R0lGODlh" as string]: "image/gif",
    ["iVBORw0KGgo" as string]: "image/png",
    "/9j/": "image/jpg",
  };
  function detectMimeType(b64: string) {
    for (const s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }
  const getAroDocs = async (id: number): Promise<AroDocs | null> => {
    const res = await axiosInstance.get(
      "api/ManageDocumentsAPI/getAllDocumentByAROID?aroID=" + id
    );
    return Promise.resolve(res.data);
  };
  const getBase64 = (file: Blob): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const getFile = async (filePath: string | null): Promise<File | null> => {
    if (filePath == null) {
      return Promise.resolve(null);
    }
    type ServerError = { status: string; message: string };
    const fileName = filePath.substring(filePath.lastIndexOf("\\") + 1);
    const res = await axiosInstance
      .get<string>("/api/ManageDocumentsAPI/getFile?filep=" + filePath)
      .catch(async (err) => {
        logger.log("File error outer:", err);
        if (axios.isAxiosError(err)) {
          // alert("Nah bra");
          if (err.response && err.response.status == 404) {
            // alert("lol");
            logger.log("File has error:", err);
            const req = await require("@/assets/FileNotFound.png");
            const resO = await fetch(req);
            logger.log("File req:", resO);
            const img = await resO.blob();
            const base64 = await getBase64(img);
            if (typeof base64 == "string") {
              return Promise.resolve({
                data: base64.split(",")[1],
              });
            }
            // logger.warn("Check this ", img);
            // return Promise.resolve({
            //   data: new File([img], fileName, { type: "image/png" }),
            // });
          }
          logger.warn("error get file", err);
        }
        return Promise.reject(err);
      });
    // logger.log(res);
    // const test = res as ServerError;
    // if (test.message) {
    //   logger.warn("Is server error");
    // } else {
    //   logger.warn("NO server error");
    // }

    // .catch(async (err) => {
    //   const req = await require("@/assets/FileNotFound.png");
    //   const res = await fetch(req);

    //   const img = await res.blob();
    //   const test = URL.createObjectURL(img);
    //   const base64 = await getBase64(img);
    //   if (typeof base64 === "string") {
    //     return Promise.resolve({
    //       data: base64.split(",")[1],
    //     });
    //   } else {
    //     return Promise.resolve({
    //       data: base64,
    //     });
    //   }
    // logger.warn("Return template image", img, base64);

    // if (axios.isAxiosError(err)) {
    //   if (err.response && err.response.status === 404) {

    // const img = (await require("@/assets/FileNotFound.png")) as File;
    // logger.warn("Return template image", img);
    // return Promise.resolve(img);
    // return URL.createObjectURL(img);
    // }
    // const serverError = err as AxiosError<ServerError>;
    // if (serverError && serverError.response) {
    //   logger.warn("Server error", serverError.response.data);
    //   return serverError.response.data;
    // }
    // logger.warn("Axios error but no server error");
    // }
    // return { errorMessage: "What on earth file?" };
    // });
    // const fileName = filePath.substring(filePath.lastIndexOf("\\") + 1);
    // logger.log("FIle res :", res);
    // if (res.data !== 404) {

    try {
      //expects base64 string by this time
      const filetype = detectMimeType(res.data as string);
      // logger.log("file type : ", filetype, fileName);
      const temp = window.atob(res.data as string);
      const arr = new Uint8Array(temp.length);
      for (let i = 0; i < temp.length; ++i) {
        arr[i] = temp.charCodeAt(i);
      }
      const file = new File([new Blob([arr], { type: filetype })], fileName, {
        type: filetype,
      });
      return Promise.resolve(file);
    } catch {
      logger.log("Can't convert file");
      return Promise.reject();
    }

    // } else {
    //   logger.log("FIle has error:", res);
    //   const req = await require("@/assets/FileNotFound.png");
    //   const resO = await fetch(req);

    //   const img = await resO.blob();
    //   return Promise.resolve(new File([img], fileName, { type: "image/png" }));
    // }
  };

  const deleteBasicFirearmProficiency = async (
    id: number,
    option: number
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteBasicFirearmProficiency?id=${id}&option=${option}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAdhocTraining = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAdhocTraining?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteDrivingLicense = async (
    id: number,
    option: number
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteDrivingLicense?id=${id}&option=${option}`
    );
    return Promise.resolve(res.data);
  };

  const deletePSIRARegistration = async (
    id: number,
    option: number
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deletePSIRARegistration?id=${id}&option=${option}`
    );
    return Promise.resolve(res.data);
  };

  const deleteSAPSCompetency = async (
    id: number,
    option: number
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteSAPSCompetency?id=${id}&option=${option}`
    );
    return Promise.resolve(res.data);
  };

  const deleteInstructor = async (
    id: number,
    option: number
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteInstructor?id=${id}&option=${option}`
    );
    return Promise.resolve(res.data);
  };

  const deleteRangeOfficer = async (
    id: number,
    option: number
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteRangeOfficer?id=${id}&option=${option}`
    );
    return Promise.resolve(res.data);
  };

  const deleteTacticalKnowledge = async (
    id: number,
    option: number
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteTacticalKnowledge?id=${id}&option=${option}`
    );
    return Promise.resolve(res.data);
  };

  const deleteIdDocument = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteIdDocument?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteBasicProficiency = async (
    id: number,
    statePath: string,
    certPath: string
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteBasicProficiency?id=${id}&statePath=${statePath}&certPath=${certPath}`
    );
    return Promise.resolve(res.data);
  };

  const deleteBusinessProficiency = async (
    id: number,
    statePath: string,
    certPath: string
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteBusinessProficiency?id=${id}&statePath=${statePath}&certPath=${certPath}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllBasicFirearmProficiency = async (
    id: number
  ): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllBasicFirearmProficiency?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllBusinessProficiency = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllBusinessProficiency?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllDrivingLicense = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllDrivingLicense?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllPSIRARegistration = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllPSIRARegistration?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllBasicProficiency = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllBasicProficiency?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllSAPSCompetency = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllSAPSCompetency?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllInstructor = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllInstructor?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllRangeOfficer = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllRangeOfficer?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const deleteAllTacticalKnowledge = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      `api/ManageDocumentsAPI/deleteAllTacticalKnowledge?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  return {
    getFile,
    getAroDocs,
    detectMimeType,
    getBase64,
    deleteBasicFirearmProficiency,
    deleteAdhocTraining,
    deleteDrivingLicense,
    deleteIdDocument,
    deleteInstructor,
    deletePSIRARegistration,
    deleteRangeOfficer,
    deleteSAPSCompetency,
    deleteBasicProficiency,
    deleteBusinessProficiency,
    deleteAllBasicFirearmProficiency,
    deleteAllDrivingLicense,
    deleteAllPSIRARegistration,
    deleteAllSAPSCompetency,
    deleteAllInstructor,
    deleteAllRangeOfficer,
    deleteAllBasicProficiency,
    deleteAllBusinessProficiency,
    deleteAllTacticalKnowledge,
    deleteTacticalKnowledge,
  };
};
const temp = Documents();
export default temp;


import { Component, PropSync, Vue } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Archive, Firearms, Documents, Downloader } from "@/hooks";
import { ArchivedFirearms } from "models";
import dayjs from "dayjs";
import logger from "@/plugins/logger";

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    daysRemaining: function (value: string) {
      const today = dayjs();
      const exp = dayjs(value);
      const rem = exp.diff(today, "d");

      return rem;
    },
  },
})
export default class Archived_Firearms extends Vue {
  @PropSync("loading") loading!: boolean;
  items: ArchivedFirearms[] = [];
  itemsFiltered: ArchivedFirearms[] = [];
  search = "";
  sortDesc = false;
  sortBy = "model";
  restoreModal = false;
  archivedItemInfo = "";
  expanded = [];
  archivedItemId = 0;
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Model", value: "model" },
    { text: "Type", value: "firearmType" },
    { text: "S/N", value: "serialNumber" },
    { text: "Expiry Date", value: "expiryDate" },
    { text: "Days To Expiry", value: "daysToExpiryCalc" },
    { text: "Last Maintenance Date", value: "lastMaintaniceDate" },
    { text: "Archived Reason", value: "enumArchiveFirearmStr" },
    { text: "SAP 13 No", value: "archiveSAP13" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    Model: any;
    Type: string;
    "S/N": string;
    "Expiry Date": string;
    "Days To Expiry": string;
    "Last Maintenance Date": string;
    "Archived Reason": string;
    "SAP 13 No": string;
    "Currently Booked By": string;
    "No of Magazines": string;
    "CAS No/ New Owner": string;
    Branch: string;
    Make: string;
    Picture: string;
    License: string;
    Calibre: string;
    "License Number": string;
    "License Type": string;
    "Date of Issue": string;
  }[] = [];
  expFields = {
    Model: "model",
    Type: "firearmType",
    "S/N": "serialNumber",
    "Expiry Date": "expiryDate",
    "Days To Expiry": "daysToExpiryCalc",
    "Last Maintenance Date": "lastMaintenanceDate",
    "Archived Reason": "enumArchiveFirearmStr",
    "SAP 13 No": "archiveSAP13",
    "Currently Booked By": "bookedBy", // TODO: DEPRECATED
    "No of Magazines": "numberOfMagazines",
    "CAS No/ New Owner": "archiveCAS",
    Branch: "branch",
    Make: "make",
    Picture: " ",
    License: " ",
    Calibre: "caliber",
    "License Number": "licenceNumber",
    "License Type": "licenseType",
    "Date of Issue": "fromDate",
  };

  openArchiveItem(i: ArchivedFirearms): void {
    this.archivedItemInfo = i.serialNumber;
    this.archivedItemId = i.id;
    this.restoreModal = true;
  }

  async restoreItem(): Promise<void> {
    try {
      const res = await Firearms.restoreFirearm(this.archivedItemId);
      this.$notifCreator.createSuccessNotification(
        "Firearm Restored Succesfully."
      );
      this.loadItems();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.restoreModal = false;
    }
  }

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  async loadItems(): Promise<void> {
    try {
      this.loading = true;
      const res = await Archive.getArchivedFirearm();
      this.items = res;
      this.expReports = this.populateExportData(this.items);
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateReportsFiltered(event: any) {
    this.itemsFiltered = event ? (event as any[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.itemsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "Model", dataKey: "Model" },
        { header: "Type", dataKey: "Type" },
        { header: "S/N", dataKey: "S/N" },
        { header: "Expiry Date", dataKey: "Expiry Date" },
        { header: "Days To Expiry", dataKey: "Days To Expiry" },
        { header: "Last Maintenance Date", dataKey: "Last Maintenance Date" },
        { header: "Archived Reason", dataKey: "Archived Reason" },
        { header: "SAP 13 No", dataKey: "SAP 13 No" },
        { header: "Currently Booked By", dataKey: "Currently Booked By" },
        { header: "No of Magazine", dataKey: "No of Magazine" },
        { header: "CAS No/ New Owner", dataKey: "CAS No/ New Owner" },
        { header: "Branch", dataKey: "Branch" },
        { header: "Make", dataKey: "Make" },
        { header: "Picture", dataKey: "Picture" },
        { header: "License", dataKey: "License" },
        { header: "Calibre", dataKey: "Calibre" },
        { header: "License Number", dataKey: "License Number" },
        { header: "License Type", dataKey: "License Type" },
        { header: "Date of Issue", dataKey: "Date of Issue" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Archived Firearms (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "S/N",
        horizontalPageBreakBehaviour: "immediately",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }
  populateExportData(items: ArchivedFirearms[]) {
    return items.map((elem: ArchivedFirearms) => {
      return {
        Model: elem.model != null ? elem.model : " N/A",
        Type:
          elem.firearmType != null
            ? elem.firearmType == 1
              ? "Handgun"
              : elem.firearmType == 2
              ? "Shotgun"
              : elem.firearmType == 3
              ? "Self-Loading Rifle"
              : elem.firearmType == 4
              ? "Manually-Operated Rifle"
              : String(elem.firearmType)
            : "N/A",
        "S/N": elem.serialNumber != null ? elem.serialNumber : " N/A",
        "Expiry Date":
          elem.expiryDate != null
            ? dayjs(elem.expiryDate).format("YYYY-MM-DD")
            : " N/A",
        "Days To Expiry":
          elem.expiryDate != null ? this.getDaysToExpiry(elem.expiryDate) : " ",
        "Last Maintenance Date":
          elem.lastMaintaniceDate != null
            ? dayjs(elem.lastMaintaniceDate).format("YYYY-MM-DD")
            : " N/A",
        "Archived Reason":
          elem.enumArchiveFirearmStr != null
            ? elem.enumArchiveFirearmStr
            : "N/A",
        "SAP 13 No": elem.archiveSAP13 != null ? elem.archiveSAP13 : "N/A",
        "Currently Booked By": elem.bookedBy != null ? elem.bookedBy : " N/A", // TODO: DEPRECATED
        "No of Magazines":
          elem.numberOfMagazines != null
            ? String(elem.numberOfMagazines)
            : "N/A",
        "CAS No/ New Owner":
          elem.archiveCAS != null
            ? elem.archiveCAS
            : " " + " " + elem.archiveNewOwner != null
            ? elem.archiveNewOwner
            : " ",
        Branch: elem.branch != null ? elem.branch : " N/A",

        Make: elem.make != null ? elem.make : " N/A",

        Picture: " ",
        License: " ",
        Calibre: elem.caliber != null ? elem.caliber : " N/A",
        "License Number":
          elem.licenceNumber != null ? elem.licenceNumber : " N/A",
        "License Type":
          elem.licenseType != null ? elem.licenseType.toString() : " N/A",
        "Date of Issue":
          elem.fromDate != null
            ? dayjs(elem.fromDate).format("YYYY-MM-DD")
            : " N/A",
      };
    });
  }

  getDaysToExpiry(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");
    return String(rem);
  }

  getColor(i: any): string {
    const today = dayjs();
    const exp = dayjs(i);
    const rem = exp.diff(today, "d");

    if (rem >= 211) return "green";
    else if (rem >= 181 && rem <= 210) return "yellow";
    else if (rem >= 151 && rem <= 180) return "orange";
    else if (rem >= 0 && rem <= 150) return "red";
    else return "red darken-4";
  }

  async downloadwPath(i: any): Promise<void> {
    /* window.open(process.env.VUE_APP_API_BASE + "/" + i); */
    var ext = i.substr(i.lastIndexOf(".") + 1);
    var type = "image/jpeg";
    if (ext == "jpeg" || ext == "jpg") {
      type = "image/jpeg";
    } else if (ext == "pdf") {
      type = "application/pdf";
    } else if (ext == "png") {
      type = "image/png";
    }
    try {
      await Documents.getFile(i).then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: type,
          })
        );
        window.open(url);
        logger.log(response);
      });
    } catch (err) {
      const req = await require("@/assets/FileNotFound.png");
      const resO = await fetch(req);
      const img = await resO.blob();
      const url = URL.createObjectURL(img);
      window.open(url);
      this.$notifCreator.createErrorNotification("Can't find Image.");
      return Promise.reject(err);
    }
  }

  async download(i: any): Promise<void> {
    logger.log("to be downloaded: ", i);
    if (i.mimeType == null) {
      this.$notifCreator.createErrorNotification("Can't find Image.");
    } else {
      try {
        var image = new Image();
        image.src = `data:${i.mimeType};base64,${i.dataFiles}`;

        var w = window.open("");
        if (w) {
          w.document.write(image.outerHTML);
        } else {
          throw "Couldn't read File.";
        }
      } catch (err) {
        this.$notifCreator.createErrorNotification(
          `Something went wrong: ${err}`
        );
        return Promise.reject(err);
      }
    }
  }
}

import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import { UserDetails, userRole } from "models";
import store from "@/store";
import { UserOverview } from "userModels";

export interface login {
  [field: string]: string | null;
  email: string | null;
  password: string | null;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "User",
  store,
})
class User extends VuexModule {
  userOverview: UserOverview | null = null;
  issuePermit: undefined | boolean = undefined;
  incidents: undefined | boolean = undefined;
  reports: undefined | boolean = undefined;
  UserDetails: UserDetails | null = null;
  roles: userRole[] = [];

  get getUserLimits() {
    if (
      this.getUserOverview == null ||
      this.getUserOverview.securityFirm == null
    )
      return null;
    return {
      soLimit: this.getUserOverview.securityFirm.sgLimit,
      aroLimit: this.getUserOverview.securityFirm.aroLimit,
      userLimit: this.getUserOverview.securityFirm.userLimit,
      additionalUsers: this.getUserOverview.securityFirm.additionalUsers,
      shiftDurationLimit: this.getUserOverview.securityFirm.shiftDurationLimit,
    };
  }
  get getUserOverview(): UserOverview | null {
    return this.userOverview;
  }
  @Mutation
  setUserOverview(user: UserOverview | null) {
    this.userOverview = user;
  }

  get getUserRoles(): userRole[] {
    return this.roles;
  }
  @Mutation
  setUserRoles(roles: userRole[]) {
    this.roles = roles;
  }
  @Mutation
  setFirearmProxy(proxy: boolean | undefined) {
    this.issuePermit = proxy;
  }
  @Mutation
  setincidentProxy(proxy: boolean | undefined) {
    this.incidents = proxy;
  }
  @Mutation
  setReportProxy(proxy: boolean | undefined) {
    this.reports = proxy;
  }
  get getIssuePermit(): boolean {
    // TODO: Deprecated
    /* if (this.UserDetails && this.UserDetails.issuePermit)
      return this.UserDetails.issuePermit;
    else return false; */

    if (this.issuePermit) {
      return this.issuePermit;
    } else {
      return false;
    }
  }
  get getIncidentProxy(): boolean {
    /*  if (this.UserDetails && this.UserDetails.incidents)
      return this.UserDetails.incidents;
    else return false; */
    if (this.incidents) {
      return this.incidents;
    } else {
      return false;
    }
  }
  get getReportProxy(): boolean {
    /* if (this.UserDetails && this.UserDetails.reports)
      return this.UserDetails.reports;
    else return false; */
    if (this.reports) {
      return this.reports;
    } else {
      return false;
    }
  }
  get getUsername(): string | null {
    return this.getUser && this.getUser.username ? this.getUser.username : null;
  }
  get getUser(): UserDetails | null {
    return this.UserDetails;
  }
  @Mutation
  setUser(val: UserDetails | null) {
    this.UserDetails = val;
  }
  // get getLoggedIn(): UserDetails {
  //   return {
  //     username: this.UserDetails.username,
  //     accessToken: this.UserDetails.accessToken,
  //     expiration: this.UserDetails.expiration,
  //     authenticated: this.UserDetails.authenticated,
  //     issuePermit: this.UserDetails.issuePermit,
  //     incidents: this.UserDetails.incidents,
  //     reports: this.UserDetails.reports,
  //   };
  // }
  // @Mutation
  // setLoggedIn({
  //   userDetailsResponse,
  //   roles,
  // }: {
  //   userDetailsResponse: UserDetailsResponse | undefined;
  //   roles: userRole[];
  // }) {
  //   //logger.log("Setting logged in: ", userDetailsResponse, roles);
  //   if (userDetailsResponse == undefined) {
  //     this.UserDetails = {
  //       expiration: null,
  //       username: null,
  //       accessToken: null,
  //       authenticated: false,
  //       issuePermit: false,
  //       incidents: false,
  //       reports: false,
  //     };
  //   } else {
  //     this.UserDetails = {
  //       expiration: userDetailsResponse.expiration,
  //       username: userDetailsResponse.username,
  //       accessToken: userDetailsResponse.accessToken,
  //       authenticated: true,
  //       issuePermit: userDetailsResponse.issuePermit,
  //       incidents: userDetailsResponse.incidents,
  //       reports: userDetailsResponse.reports,
  //     };
  //   }
  //   this.roles = roles;
  //   const restoreObj = JSON.stringify({
  //     ...this.UserDetails,
  //     roles: roles,
  //   });
  //   localStorage.setItem("zero-eight/login", restoreObj);

  //   // logger.log("res obj:", restoreObj);
  // }

  @Mutation
  removeLoggedIn() {
    // localStorage.removeItem("zero-eight/login");
    // this.UserDetails.username = null;
    // this.UserDetails.accessToken = null;
    // this.UserDetails.expiration = null;
    // this.UserDetails.authenticated = false;
    // this.UserDetails.issuePermit = false;
    // this.UserDetails.incidents = false;
    // this.UserDetails.reports = false;
    // this.roles = [];
    this.UserDetails = null;
    this.roles.splice(0);
  }
  get isLoggedIn(): boolean {
    //add time check here for timestamp?
    return this.UserDetails != null;
  }
}
export default getModule(User);


import { Component, Vue, Watch } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import {
  CRUDFunctionality,
  AROs,
  Downloader,
  PocketBook,
  Firearms,
} from "@/hooks";
import {
  AROViewModel,
  Firearm,
  PocketbookViewModel,
  PocketbookFormattedData,
  PocketBookExtentions,
} from "models";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";
import ManageCommentsModal from "@/views/pocketbook/AddEditComments.vue";
import logger from "@/plugins/logger";

@Component({
  components: {
    JsonExcel,
    ManageCommentsModal,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class OnlinePocketBook extends Vue {
  items: PocketBookExtentions[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "createDate";
  expanded = [];
  deleteModal = false;
  valid = false;
  aeCommentModal = false;
  aeCommentModalData = {
    type: " ",
    field: {} as PocketBookExtentions,
  };
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Entry Date & Time", value: "shiftStart" },
    /*  { text: "Entry End Date/Time", value: "shiftEnd" }, */
    { text: "Inspected By", value: "checkedByUser" },
    { text: "Title", value: "incidentTitle" },
    { text: "Description", value: "comments" },
    { text: "Supervisor Feedback", value: "feedback" },
  ];
  expReports: {
    "Entry Date Time": string | null;
    /* "End of Shift": string | null; */
    "Inspected By": string | null;
    Title: string | null;
    Description: string | null;
    "Supervisor Feedback": string | null;
  }[] = [];
  expFields = {
    "Entry Date Time": "Entry Date Time",
    /*  "End of Shift": "End of Shift", */
    "Inspected By": "Inspected By",
    Title: "Title",
    Description: "Description",
    "Supervisor Feedback": "Supervisor Feedback",
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    logger.log("Name: ", name, has);
    return has;
  }

  get getUserId(): string | null {
    const userId = AuthModule.getUserOverview?.securityFirm.userID;
    if (userId) {
      return userId;
    } else {
      return null;
    }
  }

  openAEComments(i: PocketBookExtentions | null): void {
    if (!i) {
      this.aeCommentModalData.type = "add";
      this.aeCommentModalData.field = {
        comments: "",
        shiftStart: dayjs().format("YYYY-MM-DD HH:mm"),
        shiftEnd: dayjs().format("YYYY-MM-DD HH:mm"),
        incidentTitle: "",
        obNumber: "",
      } as PocketBookExtentions;
    } else {
      this.aeCommentModalData.type = "edit";
      this.aeCommentModalData.field = {
        comments: i.comments,
        shiftStart: i.shiftStart,
        shiftEnd: i.shiftEnd,
        incidentTitle: i.incidentTitle,
        id: i.id,
        createDate: i.createDate,
        userId: i.userId,
        createBy: i.createBy,
        pocketBook: i.pocketBook,
        pocketBookId: i.pocketBookId,
        updateBy: i.updateBy,
        updateDate: i.updateDate,
        aroId: i.aroId,
        securityFirmId: i.securityFirmId,
        obNumber: i.obNumber,
        feedback: i.feedback,
        checked: i.checked,
        checkedByUser: i.checkedByUser,
        checkedByUserId: i.checkedByUserId,
        checkedDate: i.checkedDate,
      };
    }
    this.aeCommentModal = true;
  }

  /* openDeleteItem(i: PocketbookFormattedData): void {
    this.manageItemName = i.shiftFormatted;
    this.manageItemId = i.id;
    this.deleteModal = true;
  } */

  /* async deleteItem(): Promise<void> {
    try {
      if (this.manageItemId)
        var res = await PocketBook.DeletePocketBookEntry(this.manageItemId);
      this.$notifCreator.createSuccessNotification(
        "Pocketbook Entry Deleted Succesfully."
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.manageItemName = null;
      this.manageItemId = null;
      this.deleteModal = false;
      this.loadItems();
    }
  } */

  async generatePDF(): Promise<void> {
    try {
      const columns = [
        { header: "Entry Date Time", dataKey: "Entry Date Time" },
        { header: "Inspected By", dataKey: "Inspected By" },
        { header: "Title", dataKey: "Title" },
        { header: "Description", dataKey: "Description" },
        { header: "Supervisor Feedback", dataKey: "Supervisor Feedback" },
      ];

      await Downloader.generatePdfAndSave({
        fileName: `Pocketbook`,
        columns: columns,
        body: this.expReports,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loadItems(): Promise<void> {
    // logger.log(this.shouldDisplayForRole("addPBEntry"));
    if (this.shouldDisplayForRole("addPBEntry") == true) {
      try {
        this.loading = true;
        const res = await PocketBook.getAllPocketBookARO();
        if (res) this.items = this.formatData(res);
      } catch (err) {
        return Promise.reject(err);
      } finally {
        this.loading = false;
      }
      this.expReports = this.populateExportData(this.items);
    }
  }

  formatData(data: PocketBookExtentions[]): PocketBookExtentions[] {
    const filteredData: PocketBookExtentions[] = [];
    data.forEach((elem) => {
      filteredData.push({
        id: elem.id,
        createDate: elem.createDate,
        userId: elem.userId,
        comments: elem.comments,
        createBy: elem.createBy,
        pocketBook: elem.pocketBook,
        pocketBookId: elem.pocketBookId,
        updateBy: elem.updateBy,
        updateDate: elem.updateDate,
        incidentTitle: elem.incidentTitle,
        shiftEnd: dayjs(elem.shiftEnd).format("YYYY-MM-DD HH:mm"),
        shiftStart: dayjs(elem.shiftStart).format("YYYY-MM-DD HH:mm"),
        aroId: elem.aroId,
        securityFirmId: elem.securityFirmId,
        checkedByUser: elem.checkedByUser,
        obNumber: elem.obNumber,
        checkedByUserId: elem.checkedByUser,
        checked: elem.checked,
        checkedDate: elem.checkedDate,
        feedback: elem.feedback,
      });
    });
    return filteredData;
  }

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      /*  await this.loadAros();
      await this.loadFirearms(); */

      await this.loadItems();
    } catch (err) {
      logger.log("Err on Mounted:", err);
    } finally {
      this.loading = false;
    }
  }

  populateExportData(items: PocketBookExtentions[]) {
    return items.map((elem: PocketBookExtentions) => {
      return {
        "Entry Date Time": elem.shiftStart ? elem.shiftStart : null,
        /*  "End of Shift": elem.shiftEnd, */
        "Inspected By": elem.feedback ? elem.feedback : null,
        Title: elem.incidentTitle ? elem.incidentTitle : null,
        Description: elem.comments ? elem.comments : null,
        "Supervisor Feedback": elem.feedback ? elem.feedback : null,
      };
    });
  }
}


import { Component, PropSync, Vue } from "vue-property-decorator";
import OModal from "@/views/dashboard/overview/Modal.vue";
import { Dash } from "@/hooks";
import logger from "@/plugins/logger";

@Component({
  components: {
    OModal,
  },
})
export default class Expiry extends Vue {
  @PropSync("loading") load!: boolean;
  overviewModal = false;
  psiraExpiringHeaders = [
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Name", value: "aroName" },
    { text: "PSIRA Reg No", value: "registrationNumber" },
    { text: "Grade", value: "grade" },
    { text: "Expiry Date", value: "expiryDate" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
  ];
  firearmExpiringHeaders = [
    { text: "Make", value: "make" },
    { text: "Type", value: "type" },
    { text: "S/N", value: "serialNumber" },
    { text: "Expiry Date", value: "expiryDateStr" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
    { text: "Currently Booked By", value: "bookedBy" }, // TODO: DEPRECATED
  ];
  sapsExpiringHeaders = [
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Name", value: "aroName" },
    { text: "Firearm Type", value: "firearmType" },
    { text: "Expiry Date", value: "expiryDate" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
  ];
  drivingExpiringHeaders = [
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Name", value: "aroName" },
    { text: "Expiry Date", value: "expiryDateStr" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
    { text: "File", value: "fileName" },
  ];
  complianceScoreHeaders = [
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Full Name", value: "name" },
    { text: "Reg No", value: "psiraRegistrationNumber" },
    /* { text: "Type", value: "typeStr" },
    { text: "Grade/Firearm", value: "" },
    { text: "Expiry Date", value: "expiryDate" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" }, */
  ];
  firearmMaintenanceDateCountHeaders = [
    { text: "Model", value: "model" },
    { text: "Type", value: "typeStr" },
    { text: "S/N", value: "serialNumber" },
    { text: "Last Maintenance Date", value: "lastMaintenanceDate" },
    { text: "Expiry Date", value: "expiryDateStr" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
  ];
  td: any[] = [];
  modalData = {
    tableData: this.td,
    tableHeaders: this.psiraExpiringHeaders,
    tableName: "",
    title: "",
    sortBy: "",
  };

  async openModal(i: {
    color: string;
    icon: string;
    title: string;
    value: number;
    name: string;
    colorCheck: string;
  }): Promise<void> {
    this.load = true;

    logger.log("Clicked Button:", i.name);
    this.modalData.title = i.title;

    if (i.name == "psiraExpiring") {
      try {
        const res = await Dash.GetPSIRAList(1);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.psiraExpiringHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        this.$notifCreator.createErrorNotification(
          "Error Loading " + i.title + ": " + err
        );
      }
    } else if (i.name == "firearmExpiring") {
      try {
        const res = await Dash.GetFirearmList(2);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.firearmExpiringHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "sapsExpiring") {
      try {
        const res = await Dash.GetSAPSList(3);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.sapsExpiringHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "regulationExpiring") {
      try {
        const res = await Dash.GetSAPSList(4);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.sapsExpiringHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } /* else if (i.name == "incidentCount") {
      try {
        const res = await Dash.GetIncidentList(8).catch((err: any) => {
          return Promise.reject(err);
        });
        this.modalData.tableData = res;
      } catch (err) {
        this.$notifCreator.createErrorNotification(
          "Error Loading " + i.title + ": " + err
        );
      }
    } */ else if (i.name == "drivingExpiring") {
      try {
        const res = await Dash.GetDrivingLicense(9);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.drivingExpiringHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "complianceScore") {
      try {
        const res = await Dash.GetARO_SOCompliance(10);
        this.modalData.tableData = res;
        this.modalData.tableHeaders = this.complianceScoreHeaders;
        this.modalData.tableName = i.name;
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "firearmComplianceScore") {
      try {
        const res = await Dash.GetFirearmList(11);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.firearmExpiringHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDateStr";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "firearmMaintenanceDateCount") {
      try {
        const res = await Dash.GetMaintenanceDateFirearmList(12);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.firearmMaintenanceDateCountHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "lastMaintenanceDate";
      } catch (err) {
        return Promise.reject(err);
      }
    }
    this.load = false;
    this.overviewModal = true;
  }

  colorEval(str: string): string {
    logger.log(str);
    return eval(str);
  }

  async mounted(): Promise<void> {
    try {
      this.load = true;
      const res = await Dash.GetDashboardCounts();
      this.dash.forEach((el) => {
        const index = Object.keys(res).find((i: string) => i == el.name);
        if (index != undefined) {
          el.value = res[index];
        }
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }
  dash: {
    color: string;
    icon: string;
    title: string;
    value: number;
    name: string;
    colorCheck: string;
  }[] = [
    {
      color: "red lighten-1",
      icon: "mdi-car-brake-alert",
      title: "PSIRA Registrations Expiring Within 90 Days",
      value: -1,
      name: "psiraExpiring",
      colorCheck: "!= 0",
    },
    // {
    //   color: "red lighten-1",
    //   icon: "mdi-car-brake-alert",
    //   title: "PSIRA Registrations Renewed Within 90 Days",
    //   value: -1,
    //   name: "psiraRenewed",
    //   colorCheck: "!= 0",
    // },
    {
      color: "red lighten-1",
      icon: "mdi-car-brake-alert",
      title: "Firearms Expiring Within 150 Days",
      value: -1,
      name: "firearmExpiring",
      colorCheck: "> 0",
    },
    {
      color: "red lighten-1",
      icon: "mdi-car-brake-alert",
      title: "SAPS Competency Expiring Within 150 Days",
      value: -1,
      name: "sapsExpiring",
      colorCheck: "> 0",
    },
    // {
    //   color: "red lighten-1",
    //   icon: "mdi-car-brake-alert",
    //   title: "SAPS Competency Renewed Within 150 Days",
    //   value: -1,
    //   name: "sapsRenewed",
    //   colorCheck: "> 0",
    // },
    {
      color: "red lighten-1",
      icon: "mdi-car-brake-alert",
      title: "Regulation 21 Expiring Within 60 Days",
      value: -1,
      name: "regulationExpiring",
      colorCheck: "> 0",
    },
    // {
    //   color: "red lighten-1",
    //   icon: "mdi-car-brake-alert",
    //   title: "Regulation 21 Renewed Within 60 Days",
    //   value: -1,
    //   name: "regulationRenewed",
    //   colorCheck: "> 0",
    // },
    /* {
      color: "orange lighten-2",
      icon: "mdi-home",
      title: "Shifts currently on duty",
      value: -1,
      name: "currentShifts",
      colorCheck: "> 0",
    }, */
    /*  {
      color: "teal lighten-1",
      icon: "mdi-pistol",
      title: "Firearms currently booked out",
      value: -1,
      name: "firearmBookedOutCount",
      colorCheck: "> 0",
    }, */
    /* {
      color: "teal lighten-1",
      icon: "mdi-bullet",
      title: "Ammunition Currently Booked out",
      value: -1,
      name: "ammunitionBookedOut",
      colorCheck: "> 0",
    }, */
    /* {
      color: "orange lighten-2",
      icon: "mdi-shield-alert-outline",
      title: "Number of Firearm related incidents in the past 90 days",
      value: -1,
      name: "incidentCount",
      colorCheck: "> 0",
    }, */
    {
      color: "red lighten-1",
      icon: "mdi-car-brake-alert",
      title: "Driving licenses expiring within 30 days",
      value: -1,
      name: "drivingExpiring",
      colorCheck: "> 0",
    },
    // {
    //   color: "red lighten-1",
    //   icon: "mdi-car-brake-alert",
    //   title: "Driving licenses renewed within 30 days",
    //   value: -1,
    //   name: "drivingRenewed",
    //   colorCheck: "> 0",
    // },
    {
      name: "complianceScore",
      color: "red lighten-1",
      icon: "mdi-file-check-outline",
      title: "ARO/SO Compliance Score Expired compliance",
      value: -1,
      colorCheck: "!= 100",
    },
    {
      name: "firearmComplianceScore",
      color: "red lighten-1",
      icon: "mdi-file-check-outline",
      title: "Firearm Compliance Score Expired compliance",
      value: -1,
      colorCheck: "!= 100",
    },
    // {
    //   name: "firearmRenewed",
    //   color: "red lighten-1",
    //   icon: "mdi-file-check-outline",
    //   title: "Firearms Renewed",
    //   value: -1,
    //   colorCheck: "> 0",
    // },
    // {
    //   color: "green",
    //   icon: "mdi-home",
    //   title: "Firearm Compliance Score Expired Firearms",
    //   value: 20,
    // },
    {
      color: "red lighten-1",
      icon: "mdi-file-check-outline",
      title: "Firearms with Last Maintenance Date more than 60 days",
      value: -1,
      name: "firearmMaintenanceDateCount",
      colorCheck: "> 0",
    },
  ];
}

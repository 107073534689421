
import { Component, Vue } from "vue-property-decorator";
import { Downloader, Reports } from "@/hooks";
import { AmmunitionReport } from "models";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";
import logger from "@/plugins/logger";

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class Ammunition_Report extends Vue {
  reports: AmmunitionReport[] = [];
  reportsFiltered: AmmunitionReport[] = [];
  loading = false;
  search = "";
  sortDesc = true;
  sortBy = "createdDate";
  expanded: AmmunitionReport[] = [];
  expandedGroups: string[] = [];
  headers = [
    { text: "Calibre", value: "calibre" },
    { text: "Batch Number", value: "batchNumber" },
    { text: "Quantity", value: "quantity" },
    { text: "Currently Booked Out", value: "bookedOut" },
    { text: "Used in Incident", value: "usedInIncident" },
    { text: "Not Booked/Reported", value: "usedAmmunition" },
    { text: "Adjustments", value: "adjustment" },
    { text: "Total Quantity", value: "totalQuantity" },
    { text: "Total Available", value: "totalAvailable" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    Calibre: string;
    "Batch Number": string;
    Quantity: number;
    "Total Quantity": number;
    "Booked Out": number;
    "Total Available": number;
    "Used In Incident": number;
    "Used Ammunition": number;
    Adjustment: number;
    "Purchased From": string;
    "Purchased Date": string;
    "Created By": string;
    "Created Date": string;
    "Updated By": string;
    "Updated Date": string;
  }[] = [];
  expFields = {
    Calibre: "calibre",
    "Batch Number": "batchNumber",
    Quantity: "quantity",
    "Total Quantity": "totalQuantity",
    "Booked Out": "bookedOut",
    "Total Available": "totalAvailable",
    "Used In Incident": "usedInIncident",
    "Used Ammunition": "usedAmmunition",
    Adjustment: "adjustment",
    "Purchased From": "purchasedFrom",
    "Purchased Date": "purchaseDate",
    "Created By": "createdBy",
    "Created Date": "createdDate",
    "Updated By": "updatedBy",
    "Updated Date": "updatedDate",
  };

  clickedHeader(e: string): void {
    logger.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
  updateReportsFiltered(event: any) {
    this.reportsFiltered = event ? (event as any[]) : this.reports;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.reportsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "Calibre", dataKey: "Calibre" },
        { header: "Batch No", dataKey: "Batch Number" },
        { header: "Quantity", dataKey: "Quantity" },
        { header: "Total Quantity", dataKey: "Total Quantity" },
        { header: "Booked Out", dataKey: "Booked Out" },
        { header: "Total Available", dataKey: "Total Available" },
        { header: "Used In Incident", dataKey: "Used In Incident" },
        { header: "Used Ammunition", dataKey: "Used Ammunition" },
        { header: "Adjustment", dataKey: "Adjustment" },
        { header: "Purchased From", dataKey: "Purchased From" },
        { header: "Purchased Date", dataKey: "Purchased Date" },
        { header: "Created By", dataKey: "Created By" },
        { header: "Created Date", dataKey: "Created Date" },
        { header: "Updated By", dataKey: "Updated By" },
        { header: "Updated Date", dataKey: "Updated Date" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Ammunition Report (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.getReportsFiltered,
        horizontalPageBreakRepeat: "Batch Number",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const res = await Reports.getAmmunitionReport();
      this.reports = res;
      this.expReports = this.populateExportData(this.reports);
      this.loading = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  populateExportData(reports: any[]) {
    return reports.map((elem) => {
      return {
        Calibre: elem.calibre,
        "Batch Number": elem.batchNumber,
        Quantity: elem.quantity,
        "Total Quantity": elem.totalQuantity,
        "Booked Out": elem.bookedOut,
        "Total Available": elem.totalAvailable,
        "Used In Incident": elem.usedInIncident,
        "Used Ammunition": elem.usedAmmunition,
        Adjustment: elem.adjustment,
        "Purchased From": elem.purchasedFrom,
        "Purchased Date": dayjs(elem.purchaseDate).format("YYYY-MM-DD"),
        "Created By": elem.createdBy,
        "Created Date": dayjs(elem.createdDate).format("YYYY-MM-DD"),
        "Updated By": elem.updatedBy,
        "Updated Date": dayjs(elem.updatedDate).format("YYYY-MM-DD"),
      };
    });
  }
}


import { Component, Vue } from "vue-property-decorator";
import FirearmTab from "@/components/Archived/Tabs/Firearms.vue";
import aroTab from "@/components/Archived/Tabs/AROs.vue";
@Component({
  components: {
    FirearmTab,
    aroTab,
  },
})
export default class Archived extends Vue {
  loading = false;
  tab = null;
}

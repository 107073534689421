
import { Component, Vue } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import { ActivityLog, User, Downloader } from "@/hooks";
import { ActivityLogModel, ManageUserViewModel } from "models";
import dayjs from "dayjs";
import NotificationSystem from "@/store/modules/NotificationSystem";

@Component
export default class NotificationList extends Vue {
  get notifications() {
    return NotificationSystem.apiNotitications.sort((a, b) => {
      const parseDateTime = (date, time) => {
        const [hours, minutes, seconds] = time.split(/[: ]/);
        const period = time.split(" ")[1]; // AM or PM
        let hour = parseInt(hours, 10);

        // Convert to 24-hour format
        if (period === "PM" && hour < 12) hour += 12;
        if (period === "AM" && hour === 12) hour = 0;

        // Construct an ISO string
        return new Date(
          `${date}T${hour.toString().padStart(2, "0")}:${minutes}:${seconds}`
        );
      };

      const dateA = parseDateTime(a.date, a.time);
      const dateB = parseDateTime(b.date, b.time);

      return dateB.getTime() - dateA.getTime();
    });
  }
  get unreadNotifications() {
    return NotificationSystem.unreadNotitications;
  }
  async loadNotifications() {
    await NotificationSystem.getNotificationFromApi();
  }
  async mounted() {
    await this.loadNotifications();
  }
}
